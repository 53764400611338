// import logo from './logo.svg';
import './App.css';
import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import customTheme from './utils/customTheme';
import Sidebar from './components/sidebar/Sidebar';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import routes from './routes';
import { useEffect, useState } from 'react';
import IsDesktop from './utils/IsDesktop';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { loadGoogleAnalytics } from './helpers/analytics';
import classNames from 'classnames';

function App() {
  const [sidebarWidth, setSidebarWidth] = useState(300);
  const [isHidden, setIsHidden] = useState(false);
  const isDesktop = IsDesktop();

  const toggleSidebar = () => {
    setSidebarWidth(sidebarWidth === 300 ? 130 : 300);
    setIsHidden(!isHidden);
  };

  useEffect(() => {
    const TRACKING_ID = process.env.REACT_APP_GA || '';
    if (TRACKING_ID) loadGoogleAnalytics(TRACKING_ID);
  }, []);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle,
  );
  // const accountType = null;

  const authRoutes = routes.publicRoutes.map(({ path, element: Component }) => (
    <Route key={path} path={path} element={<Component />} />
  ));

  const privateRoutes = routes.privateRoutes.map(
    ({ path, element: Component }) => (
      <Route
        key={path}
        path={path}
        element={
          <Flex
            overflowY={'hidden'}
            className={`${isDesktop ? 'h-[100vh]' : ''} min-h-[100vh] ${path !== 'survey' ? '' : 'flex-col'}`}
          >
            {path !== 'survey' && (
              <Box
                className={classNames({
                  'h-[100vh]': isDesktop,
                })}
              >
                <Sidebar
                  sidebarWidth={sidebarWidth}
                  toggleSidebar={toggleSidebar}
                  isHidden={isHidden}
                />
              </Box>
            )}

            <Component />
          </Flex>
        }
      />
    ),
  );

  const founderRoute = routes.foundersRoutes.map(
    ({ path, element: Component }, index) => (
      <Route
        key={index}
        path={path}
        element={
          <Flex
            overflowY={'hidden'}
            className={`${isDesktop || path === 'connections' ? 'h-[100vh]' : ''} min-h-[100vh] w-[100vw]`}
          >
            <Sidebar
              sidebarWidth={sidebarWidth}
              toggleSidebar={toggleSidebar}
              isHidden={isHidden}
            />
            <Component />
          </Flex>
        }
      />
    ),
  );
  const investorRoute = routes.investorsRoutes.map(
    ({ path, element: Component }, index) => (
      <Route
        key={index}
        path={path}
        element={
          <Flex
            overflowY={'hidden'}
            className={`${isDesktop || path === 'connections' ? 'h-[100vh]' : ''} min-h-[100vh]`}
          >
            <Sidebar
              sidebarWidth={sidebarWidth}
              toggleSidebar={toggleSidebar}
              isHidden={isHidden}
            />
            <Component />
          </Flex>
        }
      />
    ),
  );

  const expertRoute = routes.expertsRoutes.map(
    ({ path, element: Component }, index) => (
      <Route
        key={index}
        path={path}
        element={
          <Flex
            overflowY={path === 'connections' ? 'auto' : 'hidden'}
            className={`${isDesktop ? 'h-[100vh]' : ''} min-h-[100vh]`}
          >
            <Sidebar
              sidebarWidth={sidebarWidth}
              toggleSidebar={toggleSidebar}
              isHidden={isHidden}
            />
            <Component />
          </Flex>
        }
      />
    ),
  );

  const adminRoute = routes.adminRoutes.map(
    ({ path, element: Component }, index) => (
      <Route
        key={index}
        path={path}
        element={
          <Flex
            overflowY={path === 'connections' ? 'auto' : 'hidden'}
            className={`${isDesktop ? 'h-[100vh]' : ''} min-h-[100vh]`}
          >
            <Sidebar
              sidebarWidth={sidebarWidth}
              toggleSidebar={toggleSidebar}
              isHidden={isHidden}
            />
            <Component />
          </Flex>
        }
      />
    ),
  );

  const redirectLogin = (
    <Route key="redirect" path="*" element={<Navigate to="/login" />} />
  );
  const redirectSurvey = (
    <Route key="redirect" path="*" element={<Navigate to="/survey" />} />
  );

  const getRoutes = () => {
    if (isAuthenticated && accountType === 'expert') {
      return expertRoute;
    } else if (isAuthenticated && accountType === 'founder') {
      return founderRoute;
    } else if (isAuthenticated && accountType === 'investor') {
      return investorRoute;
    } else if (isAuthenticated && accountType === 'admin') {
      return adminRoute;
    } else if (
      isAuthenticated &&
      (accountType === null || accountType === '')
    ) {
      return redirectSurvey;
    } else {
      return redirectLogin;
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGN_IN}>
      <ChakraProvider theme={customTheme}>
        <Router>
          <Routes>
            {authRoutes}
            {isAuthenticated ? privateRoutes : redirectLogin}
            {getRoutes()}
          </Routes>
        </Router>
      </ChakraProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
