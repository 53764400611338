import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useMutation, mutateFunction } from '../../libs/apis';
import { useQueryClient } from 'react-query';
import { CustomToast } from '../toast/CustomToast';

const CloseDealFlowModal = ({
  isOpen,
  onClose,
  dealFlowId,
  currentPage,
  itemsPerPage,
  invalidateKey,
  openTermsheetsCount = 0,
}) => {
  const { addToast } = CustomToast();
  const queryClient = useQueryClient();
  const [isConfirmed, setIsConfirmed] = useState(false);

  console.log('key value', invalidateKey);
  const { mutate: closeDealFlow, isLoading: mutateIsLoading } =
    useMutation(mutateFunction);

  const handleClose = () => {
    closeDealFlow(
      {
        key: `termsheet/${dealFlowId}/close`,
        method: 'put',
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(
            `deal-flow?page_no=${currentPage + 1}&page_size=${itemsPerPage}`,
          );
          queryClient.invalidateQueries(`deal-flow/metrics`);
          if (invalidateKey) {
            queryClient.invalidateQueries(invalidateKey);
          }
          addToast(res);
          onClose();
        },
        onError: (err) => {
          addToast(
            err?.response?.data?.message || 'Error closing deal flow',
            'error',
          );
        },
      },
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent width={{ base: '90%', md: 'xl' }}>
        <ModalHeader>Close Deal Flow</ModalHeader>
        <ModalBody p={4}>
          <div className="flex flex-col gap-6">
            {openTermsheetsCount > 0 ? (
              <div>
                <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-4">
                  <p className="text-red-700 font-medium">
                    Action Required: Open Termsheets Found
                  </p>
                  <p className="text-red-600 mt-2">
                    There {openTermsheetsCount === 1 ? 'is' : 'are'}{' '}
                    {openTermsheetsCount} open termsheet
                    {openTermsheetsCount === 1 ? '' : 's'} that need{' '}
                    {openTermsheetsCount === 1 ? "it's" : 'their'} status to be
                    resolved. Please accept or reject{' '}
                    {openTermsheetsCount === 1
                      ? 'this termsheet'
                      : 'these termsheets'}{' '}
                    before closing the deal flow.
                  </p>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={onClose}
                    className="px-4 py-2 rounded bg-[#134A70] hover:bg-[#0f3b5a] text-white"
                  >
                    Go Back and Handle Termsheets
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="text-gray-700">
                  <p className="text-lg font-medium mb-2">
                    Are you sure you want to close this deal flow?
                  </p>
                  <p className="text-sm mb-4">
                    This action cannot be undone. The deal flow will be marked
                    as closed and will no longer be active.
                  </p>

                  <label className="flex items-start gap-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={isConfirmed}
                      onChange={(e) => setIsConfirmed(e.target.checked)}
                      className="mt-1"
                    />
                    <span className="text-sm">
                      I understand that this action cannot be undone.
                    </span>
                  </label>
                </div>

                <div className="flex gap-4 justify-end">
                  <button
                    onClick={onClose}
                    className="px-4 py-2 rounded border border-gray-300 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleClose}
                    disabled={!isConfirmed}
                    className={`text-white px-4 py-2 rounded ${
                      isConfirmed
                        ? 'bg-[#134A70] hover:bg-[#0f3b5a]'
                        : 'bg-gray-400 cursor-not-allowed'
                    }`}
                  >
                    {mutateIsLoading ? 'Closing...' : 'Close Deal Flow'}
                  </button>
                </div>
              </>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CloseDealFlowModal;
