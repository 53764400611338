import { createSlice } from '@reduxjs/toolkit';
import UserService from '../../utils/UserService';

const initialState = {
  userInfo: UserService.getUserInfo(),
  userSubscriptionInfo: UserService.getUserSubscriptionInfo(),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setUserSubscriptionInfo(state, action) {
      state.userSubscriptionInfo = action.payload;
    },
  },
});
export const { setUserInfo, setUserSubscriptionInfo } = userSlice.actions;
export default userSlice.reducer;
