import React from 'react';
import DealFlow from '../../../components/dealFlow';

const DealFlowPage = () => (
  <>
    <DealFlow />
  </>
);

export default DealFlowPage;
