import { createSlice } from '@reduxjs/toolkit';
import UserService from '../../utils/UserService';

const initialState = {
  companyInfo: UserService.getCompanyInfo(),
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyInfo(state, action) {
      state.companyInfo = action.payload;
    },
  },
});
export const { setCompanyInfo } = companySlice.actions;
export default companySlice.reducer;
