import React from 'react';
import { IoIosArrowRoundUp } from 'react-icons/io';
import classNames from 'classnames';
import { Image, Skeleton } from '@chakra-ui/react';

const ReportCard = ({
  count,
  updatedAt,
  title,
  percentage,
  type,
  image,
  isLoading = false,
}) => {
  const date = updatedAt ? new Date(updatedAt) : new Date();

  const getTimeAgo = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    if (seconds < 60) {
      return `updated ${seconds} sec ago`;
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `updated ${minutes} min ago`;
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `updated ${hours} hr ago`;
    }
    const days = Math.floor(hours / 24);
    return `updated ${days} day${days !== 1 ? 's' : ''} ago`;
  };

  return (
    <div className="w-full sm:w-fit p-6 rounded-2xl border-[0.8px] border-[#00000033] flex flex-col gap-2 sm:gap-4">
      <div className="flex items-center gap-2">
        <Image h="22px" w="22px" src={image} alt="dashboard-icon" />
        <p className="text-sm sm:text-base text-[#8A8A8A]">Total {title}</p>
      </div>
      {isLoading ? (
        <Skeleton height="24px" width="50px" />
      ) : (
        <div className="text-2xl text-[#000000]">{count}</div>
      )}
      <div className="flex items-center gap-2">
        <div
          className={classNames({
            'rounded-2xl p-1 px-2 border-[0.7px] border-[#CAEFD6] flex items-center': true,
            'bg-[#98F1B64D]': type !== 'dealFlow',
            'bg-[#134A70] text-white': type === 'dealFlow',
          })}
        >
          <IoIosArrowRoundUp className="w-5 h-5" />
          <p className="text-sm sm:text-base">{percentage}%</p>
        </div>

        <p className="text-[#8A8A8A] text-xs sm:text-sm">
          {date ? getTimeAgo(date) : ''}
        </p>
      </div>
    </div>
  );
};

export default ReportCard;
