import React, { useEffect, useRef, useState } from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import { mobileCheck } from "../../helpers/mobileCheck";
import { FaComment, FaCommentAlt, FaEnvelope, FaFacebook, FaLinkedin, FaRegCommentAlt, FaTwitch, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { CustomToast } from "../toast/CustomToast";

function ShareContent(props) {
  const [state, updateState] = useState({
    showCopiedMessage: false,
  });
  const setState = (data) => {
    updateState((prev) => ({
      ...prev,
      ...data,
    }));
  };
  const copyEl = useRef();
  const { addToast } = CustomToast();


  // useEffect(() => {
  //   $.ajaxSetup({ cache: true });
  //   // load script
  //   $.getScript("https://connect.facebook.net/en_US/sdk.js", () => {
  //     window.FB.init({
  //       appId: process.env.FACEBOOK_APP_ID,
  //       version: "v2.7", // or v2.1, v2.2, v2.3, ...
  //     });
  //   });
  // }, []);

  const getContentOptions = (contentType) => {
    switch (contentType) {
      case "facebook": {
        return {
          icon: <FaFacebook className={`text-[#25d366]`} />,
          onClick: (message, url) => {
            if (window.FB) {
              window.FB.ui(
                {
                  display: "popup",
                  method: "share",
                  href: url,
                  quote: message,
                },
                (/* response */) => {
                  // console.log('response gotten', response);
                },
              );
            }
          },
        };
      }
      case "twitter": {
        return {
          icon: <FaTwitter className={`text-[#2aa3ef]`} />,
          onClick: (message, url) => {
            const text = `${message}\n\n${url}`;
            window.open(
              `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                text,
              )}`,
            );
          },
        };
      }
      case "linkedIn": {
        return {
          icon: <FaLinkedin className={`text-[#007ab7]`} />,
          onClick: (url) => {
            window.open(
              `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
            );
          },
        };
      }
      case "email": {
        return {
          icon: <FaEnvelope className={`text-[#da483f]`} />,
          onClick: (message, url, subject) => {
            const text = `${message}\n\n${props.showLinkInEmail ? url : ""}`;

            window.open(
              `mailto:?subject=${encodeURIComponent(
                subject,
              )}&body=${encodeURIComponent(text)}`,
            );
          },
        };
      }
      case "whatsapp": {
        return {
          icon: <FaWhatsapp className={`text-[#25d366]`} />,
          onClick: (message, url) => {
            const text = `${message}\n\n${url}`;
            if (mobileCheck()) {
              window.open(`whatsapp://send?text=${encodeURIComponent(text)}`);
            } else {
              window.open(
                `https://web.whatsapp.com://send?text=${encodeURIComponent(
                  text,
                )}`,
              );
            }
          },
        };
      }
      case "SMS": {
        return {
          icon: <FaRegCommentAlt className={`text-blue-500`} />,
          onClick: (message, url) => {
            const text = `${message}\n\n${url}`;

            window.open(`sms:&body=${encodeURIComponent(text)}`);
          },
        };
      }
      case "messenger": {
        return {
          icon: <FaCommentAlt className={`text-[#25d366]`} />,
          onClick: (message, url) => {
            if (window.FB) {
              window.FB.ui(
                {
                  display: "popup",
                  method: "send",
                  link: url,
                  quote: message,
                },
                (/* response */) => {
                  // console.log('response gotten', response);
                },
              );
            }
          },
        };
      }
      default:
        return {
          icon: "",
          onClick: () => {},
        };
    }
  };

  const showCopiedMessage = () => {
    addToast({message: 'Copied'}, "info")
  };

  const contentGroup = {
    "messaging & email": ["email", "whatsapp", "SMS", "messenger"],
    "social media": ["facebook", "twitter", "linkedIn"],
    "copy link": ["link"],
  };

  let GroupedContent = {};
  Object.keys(props.content).forEach((contentType) => {
    const contentGroupTypes = Object.keys(contentGroup);
    for (let i = 0; i < contentGroupTypes.length; i += 1) {
      if (
        contentGroup[contentGroupTypes[i]].includes(contentType) &&
        props.content[contentType]
      ) {
        GroupedContent[contentGroupTypes[i]] = {
          ...(GroupedContent[contentGroupTypes[i]] || {}),
          [contentType]: props.content[contentType],
        };
      }
    }
  });

  GroupedContent = {
    "messaging & email": GroupedContent["messaging & email"],
    "social media": GroupedContent["social media"],
    "copy link": GroupedContent["copy link"],
  };

  const ReturnValue = (
    <div id="shareContent w-[100vw] max-w-5xl">
      {props.title && <p className="title">{props.title}</p>}
      <div className="content">
        {Object.keys(GroupedContent).map((group, i) => {
          const groupContent = GroupedContent[group] || {};
          if (Object.keys(groupContent).length === 0) return "";
          return (
            <div key={i} className="group flex flex-col mb-6">
              <div className="group-title flex flex-col items-center">
                <div className="text-md capitalize w-full text-left font-medium">{group}</div>
                <span className="w-full my-2 mb-5 border-b" />
              </div>
              {group === "copy link" ? (
                <div className="flex flex-wrap gap-5">
                  <div className="border border-gray-400 p-[0.7em] w-full flex items-center">
                    <input className="text-left outline-none pr-[1em] block w-full truncate whitespace-nowrap overflow-hidden leading-[1] border-0 h-max bg-transparent text-black" readOnly id="copyUrl" value={props.link} />
                    <div
                      title="copy to clipboard"
                      ref={copyEl}
                      onClick={() => {
                        const link = document.getElementById("copyUrl");
                        link.select();
                        document.execCommand("copy");
                        showCopiedMessage();
                      }}
                    >
                      <button type="button" className="py-[0.3em] px-[0.8em] border border-black bg-white rounded-[0.2em] cursor-pointer" >
                        Copy
                      </button>
                    </div>
                    <Box
                      target={copyEl.current}
                      show={state.showCopiedMessage}
                      placement="bottom"
                    >
                      {(prop) => <Tooltip {...prop}>copied</Tooltip>}
                    </Box>
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap gap-5">
                  {" "}
                  {Object.keys(groupContent).map((type, i) => {
                    const options = getContentOptions(type);
                    return (
                      <div
                        key={i}
                        className="flex gap-2 items-center cursor-pointer"
                        onClick={() => {
                          if (options.onClick) {
                            options.onClick(
                              groupContent[type],
                              props.link,
                              props.subject,
                            );
                          }
                        }}
                      >
                        {options.icon}
                        <span className="name capitalize">{type}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );

  return ReturnValue;
}

// ShareContent.propTypes = {
//   title: PropTypes.string,
//   subject: PropTypes.string,
//   link: PropTypes.string.isRequired,
//   popUp: PropTypes.bool,
//   content: {
//     facebook: PropTypes.string, // message
//     twitter: PropTypes.string, // message
//     linkedIn: PropTypes.string, // url
//     email: PropTypes.string, // message
//     whatsapp: PropTypes.string, // message
//     SMS: PropTypes.string, // message
//     messenger: PropTypes.string, // message
//     link: PropTypes.bool,
//   },
//   showLinkInEmail: PropTypes.bool,
//   onClose: PropTypes.func,
// };

ShareContent.defaultProps = {
  title: null,
  content: {},
  popUp: true,
  onClose: () => {},
  subject: "",
  showLinkInEmail: true,
};

export default ShareContent;
