import { Skeleton } from '@chakra-ui/react';

const DealFlowTableSkeleton = () => {
  return (
    <div className="w-full">
      {/* Table Header */}
      <div className="grid grid-cols-5 gap-4 bg-[#F8FAFC] p-4 rounded-t-lg">
        <Skeleton height="24px" width="100px" />
        <Skeleton height="24px" width="120px" />
        <Skeleton height="24px" width="100px" />
        <Skeleton height="24px" width="80px" />
        <Skeleton height="24px" width="100px" />
      </div>

      {/* Table Rows */}
      {[1, 2, 3, 4, 5].map((row) => (
        <div
          key={row}
          className="grid grid-cols-5 gap-4 p-4 border-b border-gray-100"
        >
          <Skeleton height="20px" width="120px" />
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="90px" />
          <Skeleton height="20px" width="80px" />
          <Skeleton height="20px" width="110px" />
        </div>
      ))}
    </div>
  );
};

export default DealFlowTableSkeleton;
