import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useData } from '../../data';
import Header from '../header/Header';
import { Box } from '@chakra-ui/react';
import HeaderSection from './HeaderSection';
import { termsheetCoulmns } from './data';
import DealFlowTable from './DealFlowTable';
import UserService from '../../utils/UserService';
import { setCurrentTermSheetId } from '../../redux-toolkit/reducers/dealFlowSlice';

const TermSheetTableView = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [totalTermsheets, setTotalTermsheets] = useState();

  const navigate = useNavigate();

  const columns = useMemo(() => termsheetCoulmns, []);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle,
  );

  const currentDealFlow = useSelector(
    (state) => state?.dealFlow?.currentDealFlow,
  );

  const onPageChange = (selected) => {
    setCurrentPage(selected);
  };

  const {
    data: termSheets,
    isLoading: termSheetsLoading,
    isError: termSheetsError,
  } = useData(
    `termsheet/${currentDealFlow?.id}/termsheets?page_no=${currentPage + 1}&page_size=${itemsPerPage}`,
    0,
    {},
  );

  useEffect(() => {
    if (termSheets && !termSheetsError) {
      setData(termSheets?.data?.termSheets);
      setTotalTermsheets(termSheets?.data?.totalCount);
    }
  }, [termSheets, termSheetsError]);

  const onRowClick = (row) => {
    console.log('get here row data', row.original);
    dispatch(setCurrentTermSheetId(row.original?.id));
    UserService.setCurrentTermSheetId(row?.original?.id);
    navigate('../view-term-sheet');
  };

  return (
    <Box>
      <Box className="h-screen flex flex-col">
        <Header title={'Term sheet'} />

        <Box className="flex-1 bg-gray-100 overflow-hidden">
          <Box className="h-full px-5 pt-4">
            <Box className="w-full h-full flex flex-col gap-6 p-4 bg-white mb-5 rounded-lg overflow-y-auto hide-scroll">
              <HeaderSection
                title={currentDealFlow?.name}
                subTitle="Term Sheets"
                showStatus={false}
              />

              <Box className="w-full mt-5">
                <DealFlowTable
                  columns={columns}
                  data={data || []}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  pageCount={Math.ceil(totalTermsheets / itemsPerPage)}
                  isLoading={termSheetsLoading}
                  Fallback={() => (
                    <div className="h-full flex flex-col items-center justify-center p-8 bg-white rounded-lg">
                      <div className="flex flex-col items-center gap-4 max-w-md text-center">
                        {/* Document Icon */}
                        <svg
                          className="w-20 h-20 text-gray-300"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>

                        <h3 className="text-xl font-semibold text-[#2F4B6E]">
                          {accountType === 'founder'
                            ? 'Awaiting Term Sheets'
                            : 'No Term Sheet Submitted'}
                        </h3>

                        <p className="text-gray-600">
                          {accountType === 'founder'
                            ? "This deal flow is waiting for investor term sheets. You'll be notified when investors submit their proposals."
                            : "You haven't submitted a term sheet for this deal flow yet. Create a term sheet to propose your investment terms."}
                        </p>
                      </div>
                    </div>
                  )}
                  onRowClick={onRowClick}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TermSheetTableView;
