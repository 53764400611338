import { Avatar, Box, Text, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  FaBookmark,
  FaComment,
  FaHeart,
  FaRegBookmark,
  FaRegComment,
  FaRegHeart,
  FaShare,
  FaShareAlt,
} from 'react-icons/fa';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { mutateFunction } from '../../libs/apis';
import CreateCommentModal from '../modal/CreateCommentModal';
import ShareContentModal from '../modal/ShareContentModal';
import { CustomToast } from '../toast/CustomToast';
import MediaDisplay from './MediaDisplay';
import ShareContent from './ShareContent';
import { useSelector } from 'react-redux';

const Post = ({ post, refetchPost = () => {} }) => {
  const userInfo = useSelector((state) => state?.user?.userInfo);

  const { mutate, isLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const navigate = useNavigate();
  const {
    isOpen: createCommentModalIsOpen,
    onClose: onCloseCreateCommentModal,
    onOpen: onOpenCreateCommentModal,
  } = useDisclosure();
  const {
    isOpen: createShareContentIsOpen,
    onClose: onCloseShareContentVideo,
    onOpen: onOpenShareContentVideo,
  } = useDisclosure();
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');

  const goToPost = () => {
    navigate(`/post?id=${post.id}`);
  };

  const likePost = () => {
    try {
      mutate(
        {
          key: 'react/post',
          method: 'post',
          data: {
            post_id: post.id,
            comment_id: null,
          },
        },
        {
          onSuccess(res) {
            // addToast(res);
            refetchPost();
            isLiked
              ? setLikesCount((prev) => prev - 1)
              : setLikesCount((prev) => prev + 1);
            setIsLiked((prev) => !prev);
          },
          onError(e) {
            // addToast({message: 'Post was not liked'}, "error")
            console.error(e);
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsLiked(post.isLiked);
    setLikesCount(post.likes_count);
    setCommentsCount(post.comment_count);
    const _message = `Hi! ${post.user.first_name} ${post.user.last_name} shared their thoughts on SeedAFounder`;
    const _link = `${process.env.REACT_APP_FRONTEND_URL}public/post/${post.id}`;
    setMessage(_message);
    setLink(_link);
  }, [post]);

  const onCreateComment = (comment) => {
    setCommentsCount((prev) => prev + 1);
  };

  const viewProfileDetails = () => {
    if (post?.user_id === userInfo?.id) {
      navigate('../account-settings');
    } else {
      const userType = post?.user?.user_type_id;
      const userId = post?.user_id;
      navigate(`../view-user?userid=${userId}&usertype=${userType}`);
    }
  };

  console.log('post and userInfo', post, userInfo);
  return (
    <Box className="flex cursor-pointer items-start gap-2 p-2 pt-5">
      <CreateCommentModal
        isOpen={createCommentModalIsOpen}
        onClose={onCloseCreateCommentModal}
        post={post}
        onSuccess={onCreateComment}
        refetchPost={refetchPost}
      />
      <ShareContentModal
        isOpen={createShareContentIsOpen}
        onClose={onCloseShareContentVideo}
        message={message}
        link={link}
        subject={'Hello! Check out this post on SeedAFounder.'}
      />
      <Box className="cursor-pointer">
        <Avatar
          onClick={viewProfileDetails}
          src={post.user.profile_image ?? null}
          name={`${post.user.first_name} ${post.user.last_name}`}
          size={'md'}
        />
      </Box>
      <Box
        onClick={goToPost}
        className="w-full cursor-pointer flex px-2 flex-col"
      >
        <Box className="w-full pb-5 min-h-10">
          <Box className="flex flex-col mb-3">
            <Text className="font-semibold capitalize">{`${post.user.first_name} ${post.user.last_name}`}</Text>
            <Text className="text-xs text-gray-500">
              {moment(post.createdAt).fromNow()}
            </Text>
          </Box>
          <Text className="w-full" color="#000">
            {post?.text.split('\n\n').map((text) => (
              <span>
                {text}
                <br />
              </span>
            ))}
          </Text>
        </Box>
        {post.media.length > 0 ? <MediaDisplay media={post.media} /> : null}
        <Box className="flex items-center justify-between">
          <Box className="flex gap-1 cursor-pointer items-center">
            <FaRegComment
              onClick={(e) => {
                e.stopPropagation();
                onOpenCreateCommentModal();
              }}
              className="text-gray-600"
            />
            <Text>{commentsCount}</Text>
          </Box>
          <Box className="flex gap-1 cursor-pointer items-center">
            {isLiked ? (
              <FaHeart
                onClick={(e) => {
                  e.stopPropagation();
                  likePost();
                }}
                className="text-red-600"
              />
            ) : (
              <FaRegHeart
                onClick={(e) => {
                  e.stopPropagation();
                  likePost();
                }}
                className="text-gray-600"
              />
            )}
            <Text>{likesCount}</Text>
          </Box>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              onOpenShareContentVideo();
            }}
            className="flex gap-1 cursor-pointer items-center"
          >
            <FaShareAlt />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Post;
