import TermSheet from '../../components/dealFlow/TermSheet';
import { UserContext } from '../../context/UserContext';
import { useContext, useEffect } from 'react';

const TermSheetPage = () => {
  const { setActivePage } = useContext(UserContext);

  useEffect(() => {
    setActivePage('/deal-flow');
  }, [setActivePage]);

  return (
    <>
      <TermSheet />
    </>
  );
};

export default TermSheetPage;
