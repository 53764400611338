import { useRef } from 'react';
import { fetchData } from '../libs/apis';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useErrorHandler } from '../helpers';

const useData = (
  key,
  staleTime = 1000 * 5 * 60,
  headers = {},
  retry = 1,
  enabled = true,
) => {
  const { data, error, isError, isLoading, refetch } = useQuery(
    [`${key}`, key, headers],
    () => fetchData({ queryKey: key, headers }),
    { staleTime, refetchOnMount: 'always', retry, enabled },
  );

  const { handleError } = useErrorHandler();

  const errorHandlerRef = useRef(handleError);

  useEffect(() => {
    if (isError) {
      console.log('This is the error type=====', error);
      errorHandlerRef.current(error);
    }
  }, [isError, error]);
  return { data, isLoading, refetch, isError };
};

export default useData;
