import { Flex } from 'antd';
import React, { useState } from 'react';
import BrandButton from '../brandButton/BrandButton';

const EmbedVideoLink = ({
  videoLink, 
  onAddLink,
  showCloseButton = true,
  onClose = () => {},
}) => {
  const [link, setLink] = useState(videoLink);
  const [error, setError] = useState('');

  // Function to validate that the URL is from an allowed video provider
  const validateLink = (url) => {
    // Regex for YouTube URLs (handles both youtube.com and youtu.be)
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+/;
    // Regex for Vimeo URLs
    const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/\d+/;

    return (
      youtubeRegex.test(url) ||
      vimeoRegex.test(url)
    );
  };

  // Handle input changes and validate the URL on the fly
  const handleChange = (e) => {
    const value = e.target.value;
    setLink(value);
    // If empty, clear any error messages
    if (!value) {
      setError('');
      return;
    }

    // Validate the link and update error state accordingly
    if (!validateLink(value)) {
      setError('Please enter a valid video link (YouTube or Vimeo).');
    } else {
      setError('');
    }
  };

  const addLink = (e) => {
    e.preventDefault()
    if(!error){
      onAddLink(link)
      onClose()
    }
  }

  return (
    <div className="w-full mx-auto my-8 py-4">
      <form className="w-full" onSubmit={addLink} >
        <label htmlFor="videoLink" className="block mb-2 text-xs font-medium text-gray-700">
          Link:
        </label>
        <input
          type="text"
          id="videoLink"
          value={link}
          onChange={handleChange}
          maxLength={300}
          placeholder="Enter video link"
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {error && (
          <p className="mt-2 text-sm text-red-600">
            {error}
          </p>
        )}
        <Flex gap={10} className={`mt-6 items-center ${showCloseButton ? 'justify-between' : 'justify-end'}`}>
                  {
                      showCloseButton ? (
                          <BrandButton
                              text="Close"
                              onClick={onClose}
                              />
                      ) : null
                  }
                  <BrandButton
                    text={`Add Link`}
                    onClick={addLink}
                    type="submit"
                    variant="md"
                    bgColor="brand.primary"
                    color="#fff"
                    fontSize="14px"
                    borderRadius={4}
                    px={4}
                    _hover={{ background: "brand.primary" }}
                  />
                </Flex>
        </form>
    </div>
  );
};

export default EmbedVideoLink;
