import React, { useState, useEffect, useMemo } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import Header from '../header/Header';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from './Input';
import { investors } from './data';
import classNames from 'classnames';
import HeaderSection from './HeaderSection';
import { useData } from '../../data';
import { useMutation, mutateFunction } from '../../libs/apis';
import { CustomToast } from '../toast/CustomToast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import UserService from '../../utils/UserService';
import { setCurrentTermSheetId } from '../../redux-toolkit/reducers/dealFlowSlice';

const Content = ({ label, value }) => {
  return (
    <div className="flex flex-col gap-4">
      <label className="font-semibold">{label}</label>
      <p className="text-sm text-[#17171780]">{value}</p>
    </div>
  );
};

const Sheet = ({
  selecetedInvestor,
  founder,
  type,
  actionType,
  termSheetData,
}) => {
  const dispatch = useDispatch();
  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle,
  );
  const navigate = useNavigate();
  const { addToast } = CustomToast();
  const queryClient = useQueryClient();
  const schema = yup.object({
    amountCommitted: yup.string().required('Amount is required'),
    valuation: yup.string().optional(),
    stage: yup.string().optional(),
    investmentType: yup.string().optional(),
    investmentHistory: yup
      .number()
      .typeError('Investment history must be a number')
      .optional(),
    companyInvestedCount: yup
      .number()
      .typeError('Company count must be a number')
      .optional(),
    portfolioSize: yup.string().optional(),
    engagementStyle: yup.string().optional(),
    geographicFocus: yup.string().optional(),
    perferredSector: yup.string().optional(),
    accpetNDA: yup
      .boolean()
      .oneOf([true], 'Click on NDA to accept')
      .required('Click on NDA to accept'),
    // proofOfFund: yup.string().required('Proof of funds is required'),
  });

  const currentDealFlow = useSelector(
    (state) => state?.dealFlow?.currentDealFlow,
  );

  console.log('actionType', actionType);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      stage: currentDealFlow?.stage,
      valuation: currentDealFlow?.company?.valuation,
      amountCommitted: termSheetData?.investment_amount || '',
      investmentType: termSheetData?.investment_type ?? 'Equity',
      investmentHistory: termSheetData?.investment_history ?? '4',
      companyInvestedCount: termSheetData?.company_invested_count ?? '4',
      portfolioSize: termSheetData?.portfolio_size ?? '',
      engagementStyle: termSheetData?.engagement_style ?? 'Equity',
      geographicFocus:
        termSheetData?.investor?.investment_location
          ?.map((item) => item?.name)
          ?.join(',') || 'Region',
      perferredSector:
        termSheetData?.investor?.business_category
          ?.map((item) => item?.title)
          ?.join(',') || 'Tech',
      accpetNDA:
        accountType === 'founder' ? true : !!termSheetData?.nda_accepted_at,
      // proofOfFund: termSheetData?.proof_of_funds?.[0] ?? '',
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    reset({
      stage: currentDealFlow?.stage,
      valuation: currentDealFlow?.company?.valuation
        ? `$${Number(currentDealFlow?.company?.valuation)?.toLocaleString()}`
        : '',
      amountCommitted: termSheetData?.investment_amount
        ? `$${Number(termSheetData?.investment_amount)?.toLocaleString()}`
        : '',
      investmentType: termSheetData?.investment_type ?? 'Equity',
      investmentHistory: termSheetData?.investment_history ?? '4',
      companyInvestedCount: termSheetData?.company_invested_count ?? '4',
      portfolioSize: termSheetData?.portfolio_size ?? '',
      engagementStyle: termSheetData?.engagement_style ?? 'Equity',
      geographicFocus:
        termSheetData?.investor?.investment_location
          ?.map((item) => item?.name)
          ?.join(',') || 'Region',
      perferredSector:
        termSheetData?.investor?.business_category
          ?.map((item) => item?.title)
          ?.join(',') || 'Tech',
      accpetNDA:
        accountType === 'founder' ? true : !!termSheetData?.nda_accepted_at,
    });
  }, [currentDealFlow, termSheetData, reset]);

  const { mutate: createTermSheet, isLoading: createTermSheetLoading } =
    useMutation(mutateFunction);

  const { mutate: UpdateTermSheet, isLoading: updateTermSheetLoading } =
    useMutation(mutateFunction);

  const { mutate: signTermSheet, isLoading: signTermSheetLoading } =
    useMutation(mutateFunction);

  const { mutate: rejectTermSheet, isLoading: rehiectTermSheetLoading } =
    useMutation(mutateFunction);

  const companyInfo = useSelector((state) => state?.company?.companyInfo);

  console.log('company info', companyInfo);
  const onSubmit = (data) => {
    console.log('data', data, accountType);

    if (accountType === 'investor') {
      // const formData = new FormData();

      // formData.append('investment_amount', data.amountCommitted);
      // formData.append('company_id', Number(companyInfo?.id));
      // formData.append('deal_id', currentDealFlow?.id);

      // Add the proof of funds image if it exists
      // const proofOfFundsInput = document.getElementById('proofOfFunds');
      // if (proofOfFundsInput?.files[0]) {
      //   formData.append('attachments', proofOfFundsInput.files[0]);
      // }

      const parsedAmountCommitted = data.amountCommitted.startsWith('$')
        ? data.amountCommitted.substring(1).replace(/,/g, '')
        : data.amountCommitted.replace(/,/g, '');

      const payload = {
        investment_amount: Number(parsedAmountCommitted),
        company_id: companyInfo?.id,
        deal_id: currentDealFlow?.id,
      };

      if (actionType === 'create') {
        createTermSheet(
          {
            key: 'termsheet/create',
            method: 'post',
            data: payload,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          {
            onSuccess: (res) => {
              queryClient.invalidateQueries(
                `termsheet/${currentDealFlow?.id}/participants`,
              );
              addToast(res);
            },
            onError: (err) => {
              addToast(
                err?.response?.data?.message || 'Error creating termsheet',
                'error',
              );
            },
          },
        );
      } else {
        UpdateTermSheet(
          {
            key: `termsheet/${termSheetData?.id}`,
            method: 'put',
            data: payload,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          {
            onSuccess: (res) => {
              queryClient.invalidateQueries(
                `termsheet/${currentDealFlow?.id}/participants`,
              );
              addToast(res);
            },
            onError: (err) => {
              addToast(
                err?.response?.data?.message || 'Error creating termsheet',
                'error',
              );
            },
          },
        );
      }
    } else {
      if (
        termSheetData &&
        (termSheetData?.status === 'signed' ||
          termSheetData?.status === 'rejected')
      ) {
        dispatch(setCurrentTermSheetId(termSheetData?.id));
        UserService.setCurrentTermSheetId(termSheetData?.id);
        navigate('../view-post-agreement-stage');
      } else {
        //sign term sheet
        signTermSheet(
          {
            key: `termsheet/${termSheetData?.id}/sign`,
            method: 'put',
          },
          {
            onSuccess: (res) => {
              queryClient.invalidateQueries(
                `termsheet/${currentDealFlow?.id}/participants`,
              );
              addToast(res);
            },
            onError: (err) => {
              addToast(
                err?.response?.data?.message || 'Error signing termsheet',
                'error',
              );
            },
          },
        );
      }
    }
  };

  const [previewUrl, setPreviewUrl] = useState(
    termSheetData?.proof_of_funds?.[0],
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      setValue('proofOfFund', file, { shouldValidate: true });
    }
  };

  const handleRejectTermSheet = () => {
    rejectTermSheet(
      {
        key: `termsheet/${termSheetData?.id}/reject`,
        method: 'put',
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(
            `termsheet/${currentDealFlow?.id}/participants`,
          );
          addToast(res);
        },
        onError: (err) => {
          addToast(
            err?.response?.data?.message || 'Error rejecting termsheet',
            'error',
          );
        },
      },
    );
  };

  return (
    <div className="w-full h-full flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="w-full flex-1 flex flex-col"
      >
        <div className="flex-1 rounded-xl bg-[#F4F7FD] p-4 md:p-6 overflow-y-auto hide-scroll">
          <div
            className={classNames({
              'w-full flex': true,
              'flex-col': type === 'investor',
              'flex-col-reverse': type === 'founder',
            })}
          >
            {/* owner section */}
            <div
              className={classNames({
                'w-full flex flex-col gap-4': true,
                'mt-0': type === 'investor',
                'mt-6': type === 'founder',
              })}
            >
              <h2 className="text-lg text-[#2F4B6E] font-bold">
                Investor information
              </h2>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                <Content label="Name" value={selecetedInvestor?.name || '-'} />
                <Content
                  label="Email"
                  value={selecetedInvestor?.email || '-'}
                />
                <Content
                  label="Phone no"
                  value={selecetedInvestor?.phone || '-'}
                />
                <Content
                  label="Company name"
                  value={selecetedInvestor?.company?.title || '-'}
                />
                <Content
                  label="Location"
                  value={
                    selecetedInvestor?.company?.incorporation_location
                      ?.map((item) => item?.name)
                      ?.join(',') || '-'
                  }
                />
              </div>
            </div>

            {/* guest section */}
            <div
              className={classNames({
                'w-full flex flex-col gap-4': true,
                'mt-0': type === 'founder',
                'mt-6': type === 'investor',
              })}
            >
              <h2 className="text-lg text-[#2F4B6E] font-bold">
                Founder information
              </h2>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                <Content label="Name" value={founder.name || '-'} />
                <Content label="Email" value={founder.email || '-'} />
                <Content label="Phone no" value={founder.phone_number || '-'} />
                <Content
                  label="Company name"
                  value={founder.company?.title || '-'}
                />
                <Content
                  label="Location"
                  value={
                    founder.company?.incorporation_location
                      .map((item) => item?.name)
                      .join(',') || '-'
                  }
                />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col gap-6 mt-6">
            {/* Investment section */}
            <div className="w-full flex flex-col gap-4">
              <h2 className="text-lg text-[#2F4B6E] font-bold">
                Investment Details
              </h2>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <Input
                  label="Amount Committed"
                  placeholder="$1,000,000"
                  type="text"
                  htmlFor="amountCommitted"
                  error={errors.amountCommitted?.message}
                  disabled={
                    type === 'founder' || termSheetData?.status === 'rejected'
                  }
                  {...register('amountCommitted')}
                />

                <Input
                  label="Valuation"
                  placeholder="$10,000,000"
                  type="text"
                  htmlFor="valuation"
                  error={errors.valuation?.message}
                  disabled
                  {...register('valuation')}
                />

                <Input
                  label="Stage"
                  placeholder="Seed"
                  htmlFor="stage"
                  error={errors.stage?.message}
                  disabled
                  {...register('stage')}
                />

                <Input
                  label="Investment Type"
                  placeholder="Equity"
                  htmlFor="investmentType"
                  error={errors.investmentType?.message}
                  disabled
                  {...register('investmentType')}
                />
              </div>
            </div>

            {/* Experience section */}
            <div className="w-full flex flex-col gap-4">
              <h2 className="text-lg text-[#2F4B6E] font-bold">
                Investor Experience
              </h2>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <Input
                  label="Investment History"
                  placeholder="5"
                  type="number"
                  htmlFor="investmentHistory"
                  error={errors.investmentHistory?.message}
                  disabled
                  {...register('investmentHistory')}
                />

                <Input
                  label="Company Invested In"
                  placeholder="4"
                  type="number"
                  htmlFor="companyInvestedCount"
                  error={errors.companyInvestedCount?.message}
                  disabled
                  {...register('companyInvestedCount')}
                />

                <Input
                  label="Portfolio Size"
                  placeholder="Optional"
                  htmlFor="portfolioSize"
                  error={errors.portfolioSize?.message}
                  disabled
                  {...register('portfolioSize')}
                />

                <Input
                  label="Engagement Style"
                  placeholder="Equity"
                  htmlFor="engagementStyle"
                  error={errors.engagementStyle?.message}
                  disabled
                  {...register('engagementStyle')}
                />
              </div>
            </div>

            {/* Industry focus section */}
            <div className="w-full flex flex-col gap-4">
              <h2 className="text-lg text-[#2F4B6E] font-bold">
                Industry & Focus
              </h2>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                <Input
                  label="Preferred Sector/industries"
                  placeholder="Technology"
                  type="text"
                  htmlFor="perferredSector"
                  error={errors.perferredSector?.message}
                  disabled
                  {...register('perferredSector')}
                />

                <Input
                  label="Geographic Focus"
                  placeholder="Region"
                  type="text"
                  htmlFor="geographicFocus"
                  error={errors.geographicFocus?.message}
                  disabled
                  {...register('geographicFocus')}
                />
              </div>
            </div>

            {/* Legal compliance section */}
            <div className="w-full flex flex-col gap-4">
              <h2 className="text-lg text-[#2F4B6E] font-bold">
                Legal and Compliance
              </h2>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-20">
                {/* NDA section */}
                <div className="flex flex-col gap-4">
                  <Input
                    label="NDAs"
                    placeholder="Region"
                    type="checkbox"
                    htmlFor="accpetNDA"
                    error={errors.accpetNDA?.message}
                    className="w-6 h-6 border-2 cursor-pointer border-[#2F4B6E] bg-transparent"
                    {...register('accpetNDA')}
                    disabled={
                      type === 'founder' || !!termSheetData?.nda_accepted_at
                    }
                  />

                  <a href="/agreement" className="cursor-pointer">
                    Read Agreement link
                  </a>
                </div>

                <div className="flex flex-col gap-4">
                  <label>Accredited investor Status</label>

                  <select className="w-full bg-transparent outline-none border border-[#00000033] rounded p-2 placeholder:text-[#17171780] cursor-pointer">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                {/* <div className="flex flex-col gap-4">
                  <label>Proof of funds</label>
                  <div className="max-w-[200px] max-h-[200px] w-32 h-32 border-2 border-dashed border-[#4848484D] rounded-xl flex flex-col items-center justify-center cursor-pointer bg-[#C7DFF0] overflow-hidden relative">
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      id="proofOfFunds"
                      onChange={handleFileChange}
                      disabled={type === 'founder'}
                    />
                    {previewUrl ? (
                      <div className="relative w-full h-full group">
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="rounded-xl w-full h-full object-cover"
                        />

                        {accountType === 'investor' && (
                          <label
                            htmlFor="proofOfFunds"
                            className="absolute cursor-pointer inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                          >
                            <div className="text-white flex flex-col items-center">
                              <svg
                                className="w-8 h-8 mb-1"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                              <span className="text-sm">Change Photo</span>
                            </div>
                          </label>
                        )}
                      </div>
                    ) : (
                      <label
                        htmlFor="proofOfFunds"
                        className="p-1.5 cursor-pointer flex flex-col items-center"
                      >
                        <svg
                          className="w-12 h-16 text-gray-400 mb-1"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                        <p className="text-sm text-center text-gray-500">
                          Add or drag photo
                        </p>
                      </label>
                    )}
                  </div>

                  {errors.proofOfFund && (
                    <span className="text-red-500 text-sm">
                      {errors.proofOfFund.message}
                    </span>
                  )}
                </div> */}

                <div>
                  <a href="/data-room">Data Room Link</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {accountType === 'investor' ? (
          !termSheetData?.status === 'rejected' ? (
            <div>
              {actionType === 'create' ? (
                <button
                  type="submit"
                  className="w-full py-2.5 my-5 text-white rounded bg-[#134A70] hover:bg-[#0d3654] transition-colors"
                >
                  {createTermSheetLoading ? 'submitting...' : 'submit'}
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full py-2.5 my-5 text-white rounded bg-[#134A70] hover:bg-[#0d3654] transition-colors"
                >
                  {updateTermSheetLoading ? 'updating....' : 'update'}
                </button>
              )}
            </div>
          ) : null
        ) : (
          <div className="w-full">
            {termSheetData?.status === 'signed' ||
            termSheetData?.status === 'rejected' ? (
              <button className="w-full py-2.5 my-5 text-white rounded bg-[#134A70] hover:bg-[#0d3654] transition-colors">
                Next
              </button>
            ) : (
              <div className="w-full flex items-center gap-4">
                <button
                  type="submit"
                  className="w-full py-2.5 my-5 text-white rounded bg-[#134A70] hover:bg-[#0d3654] transition-colors"
                >
                  {signTermSheetLoading ? 'Signing...' : 'Accept'}
                </button>

                <button
                  type="button"
                  className="w-full py-2.5 my-5 text-white rounded bg-[#DC3545] hover:bg-[#b42c3a] transition-colors"
                  onClick={(e) => {
                    e.preventDefault();
                    handleRejectTermSheet();
                  }}
                >
                  {rehiectTermSheetLoading ? 'Rejecting...' : 'Reject'}
                </button>
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

const TermSheet = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.user?.userInfo);

  const companyInfo = useSelector((state) => state?.company?.companyInfo);

  const allCompanyInfo = useSelector(
    (state) => state?.allcompanies?.allCompaniesInfo,
  );

  const accountType = userInfo?.userTypeTitle;

  const navigate = useNavigate();
  const currentDealFlow = useSelector(
    (state) => state?.dealFlow?.currentDealFlow,
  );

  const currentTermSheetId = useSelector(
    (state) => state?.dealFlow?.currentTermSheetId,
  );

  const [selectedInvestor, setSelectedInvestor] = useState(
    accountType === 'investor'
      ? {
          name: `${userInfo?.firstName} ${userInfo?.lastName}`,
          email: userInfo?.email,
          phone: userInfo?.phone_number ?? '',
          company: companyInfo,
        }
      : investors[0],
  );
  const [showDealSheet, setShowDealSheet] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState();
  const [dealParticipantsData, setDealParticipantsData] = useState([]);
  const [selectedTermSheet, setSelectedTermSheet] = useState({});
  const [investorInfos, setInvestorInfo] = useState([]);

  const founderInfo = useMemo(
    () => ({
      name: `${selectedDeal?.user?.first_name} ${selectedDeal?.user?.last_name}`,
      email: selectedDeal?.user?.email ?? '',
      phone: selectedDeal?.user?.phone_number ?? '',
      company: selectedDeal?.company,
    }),
    [selectedDeal],
  );

  const {
    data: dealParticipants,
    isLoading: dealParticipantsLoading,
    isError: dealParticipantsError,
  } = useData(`termsheet/${currentDealFlow?.id}/participants`, 0, {});

  const {
    data: deal,
    isLoading: dealLoading,
    isError: dealError,
    refetch: refetchDealFlow,
  } = useData(`deal-flow/${currentDealFlow?.id}`, 0, {}, 1, true);

  useEffect(() => {
    if (deal && !dealLoading && !dealError) {
      setSelectedDeal(deal?.data?.deal);
    }
  }, [deal, dealLoading, dealError]);

  useEffect(() => {
    if (
      dealParticipants &&
      !dealParticipantsLoading &&
      !dealParticipantsError
    ) {
      setDealParticipantsData(dealParticipants?.data?.termSheets);

      if (accountType === 'founder') {
        console.log('run here');
        const termSheet = dealParticipants?.data?.termSheets?.find(
          (ts) => ts?.id === currentTermSheetId,
        );

        setSelectedTermSheet(termSheet);

        setInvestorInfo(
          dealParticipants?.data?.termSheets?.map((termSheet) => {
            return {
              id: termSheet.user_id,
              ...termSheet?.investor,
            };
          }),
        );

        const investor = termSheet?.investor;
        setSelectedInvestor({
          id: termSheet?.user_id,
          name: `${investor?.user?.first_name} ${investor?.user?.last_name}`,
          email: investor?.user?.email ?? '',
          phone: investor?.user?.phone_number ?? '',
          company: investor ?? null,
        });
      } else {
        console.log('get here for investor', userInfo);

        // get the logged in investor termsheet from the list of termsheets for the deal
        const investorTermSheet = dealParticipants?.data?.termSheets?.find(
          (termSheet) => termSheet?.user_id === userInfo?.id,
        );

        if (
          accountType === 'investor' &&
          investorTermSheet &&
          investorTermSheet?.status === 'signed'
        ) {
          dispatch(setCurrentTermSheetId(investorTermSheet?.id));
          UserService.setCurrentTermSheetId(investorTermSheet?.id);
          navigate('../view-post-agreement-stage');
        }

        setSelectedTermSheet({
          name: `${userInfo?.firstName} ${userInfo?.lastName}`,
          email: userInfo?.email,
          phone: userInfo?.phone_number ?? '',
          company: userInfo?.company ?? null,
          ...investorTermSheet,
        });
      }
    }
  }, [dealParticipants, dealParticipantsLoading, dealParticipantsError]);

  const hasSubmittedTermSheet = dealParticipantsData?.some(
    (termSheet) => termSheet?.user_id === userInfo?.id,
  );

  console.log('dealparticipants', dealParticipantsData);
  console.log('selectedTermsheet', selectedTermSheet);
  return (
    <Box>
      <Box className="h-screen flex flex-col">
        <Header title={'Term sheet'} />

        <Box className="flex-1 bg-gray-100 overflow-hidden">
          <Box className="h-full px-5 pt-4">
            {accountType === 'founder' && dealParticipantsData?.length < 1 ? (
              <div className="h-full flex flex-col items-center justify-center p-8 bg-white rounded-lg">
                <div className="flex flex-col items-center gap-4 max-w-md text-center">
                  {/* Document Icon */}
                  <svg
                    className="w-20 h-20 text-gray-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>

                  <h3 className="text-xl font-semibold text-[#2F4B6E]">
                    {accountType === 'founder'
                      ? 'Awaiting Term Sheets'
                      : 'No Term Sheet Submitted'}
                  </h3>

                  <p className="text-gray-600">
                    {accountType === 'founder'
                      ? "This deal flow is waiting for investor term sheets. You'll be notified when investors submit their proposals."
                      : "You haven't submitted a term sheet for this deal flow yet. Create a term sheet to propose your investment terms."}
                  </p>

                  {accountType === 'investor' && (
                    <button
                      onClick={() => setShowDealSheet(true)}
                      className="mt-4 px-6 py-2.5 bg-[#134A70] text-white rounded hover:bg-[#0d3654] transition-colors"
                    >
                      Create Term Sheet
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <Box className="h-full bg-white rounded-lg p-4 flex flex-col">
                {/* Fixed header section */}
                <HeaderSection
                  title={
                    accountType === 'founder'
                      ? selectedInvestor?.name
                      : founderInfo?.name
                  }
                  subTitle="Term Sheet Form"
                  checkboxState={selectedTermSheet?.status ?? 'in_progress'}
                />

                {/* Scrollable sheet section */}
                <Box className="flex flex-col-reverse md:flex-row gap-4 mt-6 flex-1 overflow-hidden">
                  <Box className="flex-1 overflow-y-auto hide-scroll">
                    <Sheet
                      selecetedInvestor={selectedInvestor}
                      founder={founderInfo}
                      type={accountType}
                      actionType={!hasSubmittedTermSheet ? 'create' : 'update'}
                      termSheetData={selectedTermSheet}
                    />
                  </Box>

                  {accountType === 'founder' && (
                    <div className="md:w-[200px] overflow-y-auto hide-scroll pr-2">
                      <div className="flex flex-row md:flex-col gap-2 pb-4">
                        {dealParticipantsLoading
                          ? // Chakra UI Skeleton loading items
                            [...Array(8)].map((_, index) => (
                              <Skeleton
                                key={index}
                                className="w-full px-4 py-2 rounded-lg flex-shrink-0"
                                height="36px"
                              />
                            ))
                          : // Actual investor items
                            investorInfos.map((investor) => {
                              // Find the corresponding termsheet to get status
                              const termsheet = dealParticipantsData.find(
                                (sheet) => sheet.user_id === investor.id,
                              );
                              const status = termsheet?.status || 'in_progress';

                              // Define status indicator styles
                              const statusColors = {
                                signed: 'bg-green-500',
                                in_progress: 'bg-yellow-500',
                                rejected: 'bg-red-500',
                              };

                              return (
                                <div
                                  onClick={() => {
                                    setSelectedInvestor({
                                      id: investor.id,
                                      name: `${investor?.user?.first_name} ${investor?.user?.last_name}`,
                                      email: investor?.user?.email ?? '',
                                      phone: investor?.user?.phone_number ?? '',
                                      company: investor ?? null,
                                    });

                                    setSelectedTermSheet(
                                      dealParticipantsData.find(
                                        (termSheet) =>
                                          termSheet?.user_id === investor.id,
                                      ),
                                    );
                                  }}
                                  key={investor.id}
                                  className={`
                                    w-full px-4 py-2 cursor-pointer rounded-lg flex-shrink-0 relative
                                    ${
                                      selectedInvestor?.id === investor.id
                                        ? 'bg-[#134A70] text-white border-[#134A70]'
                                        : 'bg-white border border-[#E5E7EB] hover:border-[#134A70]'
                                    }
                                    transition-all duration-200
                                  `}
                                >
                                  {/* Status indicator dot */}
                                  <div
                                    className={`absolute top-2 right-2 w-2.5 h-2.5 rounded-full ${statusColors[status] || 'bg-gray-300'}`}
                                    title={status.replace('_', ' ')}
                                  />

                                  <p className="text-center whitespace-nowrap truncate text-sm pr-4">
                                    {`${investor?.user?.first_name} ${investor?.user?.last_name}`}
                                  </p>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {dealParticipantsLoading && !dealParticipantsError && <Loader />}
    </Box>
  );
};

export default TermSheet;
