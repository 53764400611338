import classNames from 'classnames';
import { format } from 'date-fns';

export const founderData = [
  {
    investor: 'John Doe',
    stage: 'Initial Meeting',
    amount: 500000,
    percentage: 10,
    status: 'In Progress',
    lastUpdated: 'January 1, 2025',
  },
  {
    investor: 'Jane Smith',
    stage: 'Due Diligence',
    amount: 1000000,
    percentage: 20,
    status: 'Term Sheet Signed',
    lastUpdated: 'January 1, 2025',
  },
  {
    investor: 'Alpha Ventures',
    stage: 'Closed Deal',
    amount: 750000,
    percentage: 15,
    status: 'Closed',
    lastUpdated: 'December 30, 2024',
  },
];

export const investorData = [
  {
    founder: 'John Doe',
    stage: 'Initial Meeting',
    amount: 500000,
    percentage: 10,
    status: 'In Progress',
    lastUpdated: 'January 1, 2025',
  },
  {
    founder: 'Jane Smith',
    stage: 'Due Diligence',
    amount: 1000000,
    percentage: 20,
    status: 'Term Sheet Signed',
    lastUpdated: 'January 1, 2025',
  },
  {
    founder: 'Alpha Ventures',
    stage: 'Closed Deal',
    amount: 750000,
    percentage: 15,
    status: 'Closed',
    lastUpdated: 'December 30, 2024',
  },
];

export const founderColumns = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Stage of Interaction',
    accessorKey: 'stage_of_interaction',
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <span className={`px-2 py-1 text-sm capitalize`}>
          {value.replace(/[^A-Za-z\s]/, ' ')}
        </span>
      );
    },
  },
  {
    header: 'Total Amount Committed',
    accessorKey: 'amount_committed',
    cell: ({ getValue }) => {
      const value = getValue();
      return value !== undefined && value !== null
        ? `$${value.toLocaleString()}`
        : '-';
    },
  },
  {
    header: 'No of signed termsheets',
    accessorKey: 'no_of_investors',
  },
  {
    header: 'No of pending termsheets',
    accessorKey: 'open_termsheets',
  },
  {
    header: 'Total Percentage',
    accessorKey: 'percentage',
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? `${value * 100}%` : '-';
    },
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <span
          className={classNames('px-2 py-1 rounded-full text-sm capitalize', {
            'text-green-600': value === 'in_progress',
            'text-[#134A70]': value === 'signed',
            'text-red-600': value === 'closed',
          })}
        >
          {value.replace(/[^A-Za-z\s]/, ' ')}
        </span>
      );
    },
  },
  {
    header: 'Last Updated',
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) return '-';

      try {
        return format(new Date(value), 'MMM d, yyyy');
      } catch (error) {
        return '-';
      }
    },
  },
  {
    header: '',
    accessorKey: 'actions',
    cell: ({ row, onActionClick }) => {
      if (row.original.status === 'closed') {
        return (
          <div className="text-center text-gray-600">Deal already closed</div>
        );
      } else {
        return (
          <div className="flex items-center justify-center w-full">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onActionClick(row.original);
              }}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200 text-gray-600 hover:text-red-600 group relative"
              title="Close Deal"
            >
              <span className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded py-1 px-2 -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                Close Deal Flow
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8"
                />
              </svg>
            </button>
          </div>
        );
      }
    },
  },
];

export const closedDealFounderColumns = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Stage of Interaction',
    accessorKey: 'stage_of_interaction',
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <span className={`px-2 py-1 text-sm capitalize`}>
          {value.replace(/[^A-Za-z\s]/, ' ')}
        </span>
      );
    },
  },
  {
    header: 'Total Amount Committed',
    accessorKey: 'amount_committed',
    cell: ({ getValue }) => {
      const value = getValue();
      return value !== undefined && value !== null
        ? `$${value.toLocaleString()}`
        : '-';
    },
  },
  {
    header: 'No of signed termsheets',
    accessorKey: 'no_of_investors',
  },
  {
    header: 'No of rejected termsheets',
    accessorKey: 'rejected_termsheets',
  },
  {
    header: 'Total Percentage',
    accessorKey: 'percentage',
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? `${value * 100}%` : '-';
    },
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <span
          className={classNames('px-2 py-1 rounded-full text-sm capitalize', {
            'text-green-600': value === 'in_progress',
            'text-[#134A70]': value === 'signed',
            'text-red-600': value === 'closed',
          })}
        >
          {value.replace(/[^A-Za-z\s]/, ' ')}
        </span>
      );
    },
  },
  {
    header: 'Last Updated',
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) return '-';

      try {
        return format(new Date(value), 'MMM d, yyyy');
      } catch (error) {
        return '-';
      }
    },
  },
];

export const investorColumns = [
  {
    header: 'Founder',
    accessorKey: 'user',
    cell: ({ getValue }) => {
      const value = getValue();
      const fullName = `${value?.first_name} ${value?.last_name}`;
      return fullName;
    },
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Stage of Interaction',
    accessorKey: 'stage_of_interaction',
  },
  {
    header: 'Amount Committed',
    accessorKey: 'amount_committed',
    cell: ({ getValue }) => {
      const value = getValue();
      return value !== undefined && value !== null
        ? `$${value.toLocaleString()}`
        : '-';
    },
  },
  // {
  //   header: 'Percentage',
  //   accessorKey: 'percentage',
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     return value ? `${value}%` : '-';
  //   },
  // },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue }) => {
      const value = getValue();

      return (
        <span
          className={classNames('px-2 py-1 rounded-full text-sm', {
            'text-green-600': value === 'in_progress',
            'text-[#134A70]': value === 'signed',
            'text-red-600': value === 'closed',
          })}
        >
          {value}
        </span>
      );
    },
  },
  {
    header: 'Last Updated',
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) return '-';

      try {
        return format(new Date(value), 'MMM d, yyyy');
      } catch (error) {
        return '-';
      }
    },
  },
];

export const termsheetCoulmns = [
  { header: 'Deal Id', accessorKey: 'deal_id' },
  {
    header: 'Investor Name',
    accessorKey: 'investor.user',
    cell: ({ getValue }) => {
      const value = getValue();

      return `${value?.first_name} ${value?.last_name}`;
    },
  },
  {
    header: 'Amount Committed',
    accessorKey: 'investment_amount',
    cell: ({ getValue }) => {
      const value = getValue();
      return `$${Number(value).toLocaleString()}`;
    },
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <span
          className={classNames('px-2 py-1 rounded-full text-sm', {
            'text-green-600': value === 'in_progress',
            'text-[#134A70]': value === 'signed',
            'text-red-600': value === 'rejected',
          })}
        >
          {value}
        </span>
      );
    },
  },
  {
    header: 'Equity percentage',
    accessorKey: 'equity_percentage',
    cell: ({ getValue }) => {
      const value = getValue();
      return `${value * 100}%` || 0;
    },
  },

  {
    header: 'CreatedAt',
    accessorKey: 'createdAt',
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) return '-';

      try {
        return format(new Date(value), 'MMM d, yyyy');
      } catch (error) {
        return '-';
      }
    },
  },
  {
    header: 'Last Updated',
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) return '-';

      try {
        return format(new Date(value), 'MMM d, yyyy');
      } catch (error) {
        return '-';
      }
    },
  },
];

export const investors = [
  {
    id: 1,
    name: 'Nicholas Gordon',
    email: 'nicholasgordon@gmail.com',
    phone: '+2348060000000',
    company: 'Tech Solutions',
    location: 'Lagos, Nigeria',
  },
  {
    id: 2,
    name: 'Sarah Chen',
    email: 'sarah.chen@ventures.com',
    phone: '+2348061111111',
    company: 'Global Ventures Capital',
    location: 'Abuja, Nigeria',
  },
  {
    id: 3,
    name: 'David Okonkwo',
    email: 'david.o@investafrica.com',
    phone: '+2348062222222',
    company: 'InvestAfrica Partners',
    location: 'Port Harcourt, Nigeria',
  },
  {
    id: 4,
    name: 'Maria Santos',
    email: 'maria.s@techfund.com',
    phone: '+2348063333333',
    company: 'TechFund Innovation',
    location: 'Kano, Nigeria',
  },
  {
    id: 5,
    name: 'James Wilson',
    email: 'jwilson@capitalgroup.com',
    phone: '+2348064444444',
    company: 'Capital Group Africa',
    location: 'Ibadan, Nigeria',
  },
  {
    id: 6,
    name: 'Aisha Mohammed',
    email: 'aisha.m@futureventures.com',
    phone: '+2348065555555',
    company: 'Future Ventures',
    location: 'Kaduna, Nigeria',
  },
  {
    id: 7,
    name: 'Robert Zhang',
    email: 'robert.z@asiacapital.com',
    phone: '+2348066666666',
    company: 'Asia-Africa Capital',
    location: 'Enugu, Nigeria',
  },
  {
    id: 8,
    name: 'Chioma Adebayo',
    email: 'chioma.a@nexgen.com',
    phone: '+2348067777777',
    company: 'NexGen Investments',
    location: 'Benin City, Nigeria',
  },
  {
    id: 9,
    name: 'Michael Osei',
    email: 'michael.o@panafric.com',
    phone: '+2348068888888',
    company: 'PanAfrican Ventures',
    location: 'Calabar, Nigeria',
  },
  {
    id: 10,
    name: 'Michael Osei',
    email: 'michael.o@panafric.com',
    phone: '+2348068888888',
    company: 'PanAfrican Ventures',
    location: 'Calabar, Nigeria',
  },
];
