// import * as dotenv from 'dotenv';

// dotenv.config();

export const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

// export const BASE_URL = process.env.NODE_ENV == "development"? "http://localhost:9000/" :  process.env.REACT_APP_BACKEND_API_URL;

export const HUB_8020_AUTH_USER = 'Hub8020_user';

export const NUMBER_OF_DATA_PER_PAGE = 5;
