import ComposeInternalLayout from '../components/HOC/ComposeInternalLayout';
import { investorsUrl } from '../url/urls';
import Dashboard from '../pages/investor/Dashboard/Dashboard';
import AccountSettings from '../pages/founder/Settings/AccountSettings';
import StartupOffers from '../pages/founder/Startup/StartupOffers';
import Advert from '../pages/founder/Advert/Advert';
import FoundersPage from '../components/Founders/FoundersPage';
import Connections from '../pages/experts/Connections/Connections';
import MyBookings from '../pages/investor/Bookings';
import Feed from '../pages/investor/Feed';
import PostPage from '../pages/investor/Post';
import DealFlowPage from '../pages/investor/dealFlow';

export const investorsRoutes = [
  {
    title: 'Dashboard',
    path: investorsUrl.dashboard(),
    element: ComposeInternalLayout(Dashboard),
  },
  {
    title: 'Feed',
    path: investorsUrl.feed(),
    element: ComposeInternalLayout(Feed),
  },
  {
    title: 'Post',
    path: investorsUrl.post(),
    element: ComposeInternalLayout(PostPage),
  },
  {
    title: 'Founders',
    path: investorsUrl.founders(),
    element: ComposeInternalLayout(FoundersPage),
  },
  {
    title: 'Deal Flow',
    path: investorsUrl.dealFlow(),
    element: ComposeInternalLayout(DealFlowPage),
  },
  {
    title: 'Advert',
    path: investorsUrl.advert(),
    element: ComposeInternalLayout(Advert),
  },
  {
    title: 'Bookings',
    path: investorsUrl.bookings(),
    element: ComposeInternalLayout(MyBookings),
  },
  {
    title: 'Connections',
    path: investorsUrl.connections(),
    element: ComposeInternalLayout(Connections),
  },
  {
    title: 'Account Settings',
    path: investorsUrl.accountSettings(),
    element: ComposeInternalLayout(AccountSettings),
  },
];
