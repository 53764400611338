import { Avatar, Box, Text, useDisclosure } from "@chakra-ui/react"
import { FaBookmark, FaComment, FaHeart, FaRegBookmark, FaRegComment, FaRegHeart, FaShareAlt } from "react-icons/fa"
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { mutateFunction } from "../../libs/apis";
import CreateCommentModal from "../modal/CreateCommentModal";
import CreateReplyModal from "../modal/CreateReplyModal";
import { CustomToast } from "../toast/CustomToast";
import { useState, useEffect } from "react";
import ShareContentModal from "../modal/ShareContentModal";

const Comment = ({
    post,
    parentId,
    onCreateReply = () => {}
}) => {
    const { mutate, isLoading } = useMutation(mutateFunction);
    const { addToast } = CustomToast();
    const location = useLocation()
    const navigate = useNavigate()
    const {
        isOpen : createCommentModalIsOpen,
        onClose : onCloseCreateCommentModal,
        onOpen : onOpenCreateCommentModal
    } = useDisclosure()
    const [isPublic, setIsPublic] = useState("");

    const [isLiked, setIsLiked] = useState(false)
    const [likesCount, setLikesCount] = useState(0)
    const [commentsCount, setCommentsCount] = useState(0)
    const [message, setMessage] = useState('')
    const [link, setLink] = useState('')
    const [params, setParams] = useState(null)

    const {
        isOpen : createShareContentIsOpen,
        onClose : onCloseShareContentVideo,
        onOpen : onOpenShareContentVideo
      } = useDisclosure()

    const goToPost = () => {
        const params = {}
        location.search.replace('?', '').split("&").forEach((x) => {
            let arr = x.split("=")
            params[arr[0]] = arr[1]
        })
        if(!post?.reply_to && !isPublic) {
            navigate(`/post?id=${params['id']}&commentId=${post.id}`) 
        }
    }

    const likePost = () => {
        try {
            if(isPublic) return
            mutate(
                { key: "react/post", method: "post", data : {
                    post_id: parentId,
                    comment_id: post.id,
                }},
                {
                  onSuccess(res) {
                    // addToast(res);
                    isLiked ? setLikesCount(prev => prev - 1) : setLikesCount(prev => prev + 1)
                    setIsLiked(prev => !prev)
                  },
                  onError(e){
                    // addToast({message: 'Post was not liked'}, "error")
                    console.error(e)
                  }
                }
              );
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        setIsLiked(post.isLiked)
        setLikesCount(post.likes_count)
        setCommentsCount(post.replies_count)
        const _message = `Hi! ${post.user.first_name} ${post.user.last_name} shared their thoughts on SeedAFounder; Follow the conversation`;
        const _link = `${process.env.REACT_APP_FRONTEND_URL}public/post/${parentId}`;
        setMessage(_message)
        setLink(_link)
    }, [post])

    const onCreateComment = (comment) => {
        setCommentsCount(prev => prev + 1)
        onCreateReply(comment)
    }

    const openModal = (e) => {
        e.stopPropagation()
        if(!post.reply_to) onOpenCreateCommentModal()
    }
    
    useEffect(() => {
        if(location.pathname.includes('public')){
          setIsPublic(true)
        }else{
          setIsPublic(false)
        }
    }, [location])

    return (
        <Box className="flex cursor-pointer items-start gap-2 p-2 pt-5">
            <CreateReplyModal
                isOpen={createCommentModalIsOpen}
                onClose={onCloseCreateCommentModal}
                comment={post}
                postId={parentId}
                onSuccess={onCreateComment}
            />
            <ShareContentModal
                isOpen={createShareContentIsOpen}
                onClose={onCloseShareContentVideo}
                message={message}
                link={link}
                subject={"Hello! Check out this post on SeedAFounder."}
            />
            <Box>
                <Avatar 
                    src={post.user.profile_image ?? null}
                    name={`${post.user.first_name} ${post.user.last_name}`}
                    size={"md"}
                />
            </Box>
            <Box onClick={goToPost} className="w-full cursor-pointer flex px-2 flex-col">
                <Box className="w-full pb-5 min-h-10">
                        <Text className="capitalize font-medium text-sm">
                            {`${post.user.first_name} ${post.user.last_name}`}
                        </Text>
                        <Text className="w-full" color="#000">
                            {post?.text.split('\n\n').map(text => <span>{text}<br/></span>)}
                        </Text>
                </Box>
                <Box className="flex items-center justify-between">
                    <Box className={`flex gap-1 cursor-pointer items-center ${post.reply_to ? 'text-gray-300' : 'text-gray-600'}`}>
                        <FaRegComment 
                            onClick={openModal} 
                        />
                        <Text className={`${post.reply_to ? 'invisible' : 'visible'}`}>{commentsCount}</Text>
                    </Box>
                    <Box className="flex gap-1 cursor-pointer items-center">
                        {
                            isLiked ? (
                                <FaHeart onClick={(e) => {
                                    e.stopPropagation()
                                    likePost()
                                }} className="text-red-600" />
                            ) : (
                                <FaRegHeart onClick={(e) => {
                                    e.stopPropagation()
                                    likePost()
                                }} className="text-gray-600" /> 
                            )
                        } 
                        <Text>{likesCount}</Text>
                    </Box>
                    <Box  onClick={(e) => {
                                                                e.stopPropagation()
                                                                onOpenShareContentVideo()
                                                            }} className="flex gap-1 cursor-pointer items-center">
                                                    <FaShareAlt />
                                                </Box>
                </Box>
            </Box>
        </Box>
        
    )
}


export default Comment