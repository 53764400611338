import React, { useContext, useEffect } from 'react';
import CloseDealFlow from '../../components/dealFlow/CloseDealFlow';
import { UserContext } from '../../context/UserContext';

const CloseDealFlowPage = () => {
  const { setActivePage } = useContext(UserContext);

  useEffect(() => {
    setActivePage('/deal-flow');
  }, [setActivePage]);

  return (
    <>
      <CloseDealFlow />
    </>
  );
};

export default CloseDealFlowPage;
