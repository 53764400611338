import React, { useMemo, useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../header/Header';
import ReportCard from './ReportCard';
import people from '../../../src/public/icons_v2/people.svg';
import dealFlowIcon from '../../../src/public/icons_v2/deal-flow-icon.svg';
import {
  closedDealFounderColumns,
  founderColumns,
  investorColumns,
} from './data';
import DealFlowTable from './DealFlowTable';
import { useSelector } from 'react-redux';
import CreateDealFlowModal from '../modal/CreateDealFlowModal';
import CloseDealFlowModal from '../modal/CloseDealFlowModal';
import { useData } from '../../data';
import { FaHandshake } from 'react-icons/fa';
import { Skeleton } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserService from '../../utils/UserService';
import {
  setCurrentDealFlow as setCurrentDealFlowStore,
  setCurrentPage as setCurrentPageStore,
} from '../../redux-toolkit/reducers/dealFlowSlice';

const DealFlow = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloseDealFlowModalOpen, setIsCloseDealFlowModalOpen] =
    useState(false);
  const [dealFlowData, setDealFlowData] = useState([]);
  const [totalDealFlow, setTotalDealFlow] = useState(0);
  const [openDealFlowData, setOpenDealFlowData] = useState([]);
  const [closeDealFlowData, setClosedDealFlowData] = useState([]);
  const [totalOpenDealFlow, setTotalOpenDealFlow] = useState(0);
  const [totalClosedDealFlow, setTotalClosedDealFlow] = useState(0);
  const [activeTab, setActiveTab] = useState('myDeals');
  const [currentDealFlow, setCurrentDealFlow] = useState();

  const itemsPerPage = 10;

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle,
  );

  const columns = useMemo(
    () => (accountType === 'founder' ? founderColumns : investorColumns),
    [accountType],
  );

  const closedColumns = useMemo(() => closedDealFounderColumns, []);

  const onPageChange = (selected) => {
    setCurrentPage(selected);
  };

  const navigate = useNavigate();

  const onRowClick = (row) => {
    console.log('get here row data', row.original);
    dispatch(setCurrentDealFlowStore(row.original));
    UserService.setCurrentDealFlow(row.original);

    if (accountType === 'founder') {
      dispatch(setCurrentPageStore(currentPage));
      UserService.setCurrentPage(currentPage);
    }

    if (
      row.original?.status === 'signed' ||
      row.original?.status === 'closed'
    ) {
      navigate('../view-post-agreement-stage');
    } else {
      if (accountType === 'investor') {
        navigate('../view-term-sheet');
      } else {
        // navigate('../view-term-sheet');
        navigate('../view-deal-flow-termsheets');
      }
    }
  };

  const {
    data: dealFlow,
    isLoading: dealFlowLoading,
    isError: dealFlowError,
    refetch: refetchDealFlow,
  } = useData(
    `deal-flow?page_no=${currentPage + 1}&page_size=${itemsPerPage}`,
    0,
  );

  const { data: dealFlowMetrics, isLoading: dealFlowMetricsLoading } = useData(
    `deal-flow/metrics`,
    0,
  );

  const {
    data: openDealFlow,
    isLoading: openDealFlowLoading,
    isError: openDealFlowError,
    refetch: refetchOpenDeals,
  } = useData(
    `deal-flow/open?page_no=${currentPage + 1}&page_size=${itemsPerPage}`,
    0,
    {},
    1,
    false,
  );

  const {
    data: closedDealFlow,
    isLoading: closedDealFlowLoading,
    isError: closedDealFlowError,
    refetch: refetchClosedDeals,
  } = useData(
    `deal-flow/close?page_no=${currentPage + 1}&page_size=${itemsPerPage}`,
    0,
    {},
    1,
    false,
  );

  useEffect(() => {
    if (activeTab === 'myDeals') {
      refetchDealFlow();
    } else if (activeTab === 'closedDeals') {
      refetchClosedDeals();
    } else {
      refetchOpenDeals();
    }
  }, [currentPage]);

  useEffect(() => {
    if (dealFlow && !dealFlowError) {
      setDealFlowData(dealFlow?.data?.deals);
      setTotalDealFlow(dealFlow?.data?.totalCount);
    }
  }, [dealFlow, dealFlowError]);

  useEffect(() => {
    if (openDealFlow && !openDealFlowError) {
      setOpenDealFlowData(openDealFlow?.data?.deals);
      setTotalOpenDealFlow(openDealFlow?.data?.totalCount);
    }
  }, [openDealFlow, openDealFlowError]);

  useEffect(() => {
    if (closedDealFlow && !closedDealFlowError) {
      setClosedDealFlowData(closedDealFlow?.data?.deals);
      setTotalClosedDealFlow(closedDealFlow?.data?.totalCount);
    }
  }, [closedDealFlow, closedDealFlowError]);

  useEffect(() => {
    if (activeTab === 'openDeals') {
      setCurrentPage(0);
      refetchOpenDeals();
    } else if (activeTab === 'closedDeals') {
      setCurrentPage(0);
      refetchClosedDeals();
    }
  }, [activeTab]);

  const handleActionClick = (row) => {
    setCurrentDealFlow(row);
    setIsCloseDealFlowModalOpen(true);
  };

  console.log('deal flow data', dealFlow);
  return (
    <Box>
      <CreateDealFlowModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />

      <CloseDealFlowModal
        isOpen={isCloseDealFlowModalOpen}
        onClose={() => setIsCloseDealFlowModalOpen(false)}
        dealFlowId={currentDealFlow?.id}
        currentPage={currentPage}
        openTermsheetsCount={currentDealFlow?.open_termsheets}
        itemsPerPage={itemsPerPage}
      />
      <Header title={'Deal Flow'} />
      <Box className="flex w-full bg-gray-100 min-h-screen">
        <Box className={`pt-4 px-5 w-full md:h-[calc(100vh-80px)]`}>
          <Box className="w-full h-full flex flex-col gap-6 p-4 bg-white mb-5 rounded-lg overflow-y-auto hide-scroll">
            {/* report card section */}
            <section className="flex flex-col-reverse gap-6 xl:gap-0 xl:flex-row items-start justify-between">
              <div className="w-full flex flex-col sm:flex-row items-center gap-6">
                <ReportCard
                  count={
                    accountType === 'founder'
                      ? dealFlowMetrics?.data?.noOfInvestors
                      : dealFlowMetrics?.data?.noOfFounders
                  }
                  updatedAt={new Date()}
                  title={accountType === 'founder' ? 'Investors' : 'Founders'}
                  percentage={31}
                  type={accountType === 'founder' ? 'founders' : 'investors'}
                  image={people}
                  isLoading={dealFlowMetricsLoading}
                />
                <ReportCard
                  count={dealFlowMetrics?.data?.noOfDeals}
                  updatedAt={new Date()}
                  title="Deal Flow"
                  percentage={31}
                  type="dealFlow"
                  image={dealFlowIcon}
                  isLoading={dealFlowMetricsLoading}
                />
              </div>

              <div className="w-full flex flex-col items-end gap-4">
                {accountType === 'founder' ? (
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="bg-[#134A70] text-white px-4 py-2 rounded w-fit"
                  >
                    Start Deal Flow
                  </button>
                ) : null}

                <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
                  {dealFlowMetricsLoading ? (
                    <Skeleton height="20px" width="100px" />
                  ) : (
                    <div className="whitespace-nowrap text-[#20B962] flex items-center gap-1">
                      <div className="w-3 h-3 rounded-full bg-[#20B962]"></div>
                      <p className="text-sm sm:text-base">
                        {dealFlowMetrics?.data?.noOfDealsInProgress} in progress
                      </p>
                    </div>
                  )}

                  {dealFlowMetricsLoading ? (
                    <Skeleton height="20px" width="100px" />
                  ) : (
                    <div className="whitespace-nowrap text-[#EC981C] flex items-center gap-1">
                      <div className="w-3 h-3 rounded-full bg-[#EC981C]"></div>
                      <p className="text-sm sm:text-base">
                        {dealFlowMetrics?.data?.noOfSheetsSigned} Term Sheet
                        Signed
                      </p>
                    </div>
                  )}

                  {dealFlowMetricsLoading ? (
                    <Skeleton height="20px" width="100px" />
                  ) : (
                    <div className="whitespace-nowrap text-[#CF1137] flex items-center gap-1">
                      <div className="w-3 h-3 rounded-full bg-[#CF1137]"></div>
                      <p className="text-sm sm:text-base">
                        {dealFlowMetrics?.data?.noOfDealsClosed} Deal Closed
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <section className="w-full flex flex-col gap-2">
              <h1 className="text-xl sm:text-2xl font-bold text-[#2F4B6E]">
                Deal Flow
              </h1>

              <div className="w-full">
                {accountType === 'founder' ? (
                  <div className="w-full flex flex-col gap-6">
                    <div className="flex items-center border-b border-gray-200">
                      <button
                        onClick={() => setActiveTab('myDeals')}
                        className={`px-6 py-3 font-medium text-sm relative ${
                          activeTab === 'myDeals'
                            ? 'text-[#134A70]'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        My Deals
                        {activeTab === 'myDeals' && (
                          <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#134A70]" />
                        )}
                      </button>
                      <button
                        onClick={() => setActiveTab('closedDeals')}
                        className={`px-6 py-3 font-medium text-sm relative ${
                          activeTab === 'openDeals'
                            ? 'text-[#134A70]'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        Closed Deals
                        {activeTab === 'closedDeals' && (
                          <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#134A70]" />
                        )}
                      </button>
                    </div>

                    {activeTab === 'myDeals' && (
                      <DealFlowTable
                        columns={columns}
                        data={dealFlowData}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                        pageCount={Math.ceil(totalDealFlow / itemsPerPage)}
                        isLoading={dealFlowLoading}
                        onActionClick={handleActionClick}
                        Fallback={() => (
                          <div className="flex flex-col items-center justify-center py-5 px-4 text-center">
                            <div className="w-24 h-24 mb-6 text-gray-300">
                              <FaHandshake className="w-full h-full" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-700 mb-2">
                              Start Your Fundraising Journey
                            </h3>
                            <p className="text-gray-500 text-center max-w-md mb-8">
                              Create your first deal flow to start connecting
                              with potential investors and track your
                              fundraising progress.
                            </p>

                            <button
                              onClick={() => setIsModalOpen(true)}
                              className="bg-[#134A70] text-white px-6 py-2 rounded-md hover:bg-[#0f3a5a] transition-colors"
                            >
                              Create Your First Deal
                            </button>
                          </div>
                        )}
                        onRowClick={onRowClick}
                      />
                    )}

                    {activeTab === 'closedDeals' && (
                      <DealFlowTable
                        columns={closedColumns}
                        data={closeDealFlowData || []}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                        pageCount={Math.ceil(
                          totalClosedDealFlow / itemsPerPage,
                        )}
                        isLoading={closedDealFlowLoading}
                        Fallback={() => (
                          <div className="flex flex-col items-center justify-center py-5 px-4 text-center">
                            <div className="w-24 h-24 mb-6 text-gray-300">
                              <FaHandshake className="w-full h-full" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-700 mb-2 text-center">
                              No Closed Deals Yet
                            </h3>
                            <p className="text-gray-500 text-center max-w-md mb-8">
                              Successfully closed deals will appear here once
                              deals reach their completion. This includes deals
                              that have been finalized with signed agreements
                              and completed transactions.
                            </p>
                          </div>
                        )}
                        onRowClick={onRowClick}
                      />
                    )}
                  </div>
                ) : (
                  <div className="w-full flex flex-col gap-6">
                    <div className="flex items-center border-b border-gray-200">
                      <button
                        onClick={() => setActiveTab('myDeals')}
                        className={`px-6 py-3 font-medium text-sm relative ${
                          activeTab === 'myDeals'
                            ? 'text-[#134A70]'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        My Deals
                        {activeTab === 'myDeals' && (
                          <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#134A70]" />
                        )}
                      </button>
                      <button
                        onClick={() => setActiveTab('openDeals')}
                        className={`px-6 py-3 font-medium text-sm relative ${
                          activeTab === 'openDeals'
                            ? 'text-[#134A70]'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        Open Deals
                        {activeTab === 'openDeals' && (
                          <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#134A70]" />
                        )}
                      </button>

                      <button
                        onClick={() => setActiveTab('closedDeals')}
                        className={`px-6 py-3 font-medium text-sm relative ${
                          activeTab === 'openDeals'
                            ? 'text-[#134A70]'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        Closed Deals
                        {activeTab === 'closedDeals' && (
                          <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#134A70]" />
                        )}
                      </button>
                    </div>

                    {activeTab === 'myDeals' && (
                      <DealFlowTable
                        columns={columns}
                        data={dealFlowData || []}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                        pageCount={Math.ceil(totalDealFlow / itemsPerPage)}
                        isLoading={dealFlowLoading}
                        Fallback={() => (
                          <div className="flex flex-col items-center justify-center py-5 px-4 text-center">
                            <div className="w-24 h-24 mb-6 text-gray-300">
                              <FaHandshake className="w-full h-full" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-700 mb-2">
                              No Investment Opportunities Yet
                            </h3>
                            <p className="text-gray-500 text-center max-w-md mb-8">
                              Deal flows will appear here when founders share
                              their investment opportunities with you. Browse
                              the marketplace to discover potential investments.
                            </p>
                          </div>
                        )}
                        onRowClick={onRowClick}
                      />
                    )}

                    {activeTab === 'openDeals' && (
                      <DealFlowTable
                        columns={columns}
                        data={openDealFlowData || []}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                        pageCount={Math.ceil(totalOpenDealFlow / itemsPerPage)}
                        isLoading={openDealFlowLoading}
                        Fallback={() => (
                          <div className="flex flex-col items-center justify-center py-5 px-4 text-center">
                            <div className="w-24 h-24 mb-6 text-gray-300">
                              <FaHandshake className="w-full h-full" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-700 mb-2 text-center">
                              No Investment Opportunities Yet
                            </h3>
                            <p className="text-gray-500 text-center max-w-md mb-8">
                              Deal flows will appear here when founders share
                              their investment opportunities with you. Browse
                              the marketplace to discover potential investments.
                            </p>
                          </div>
                        )}
                        onRowClick={onRowClick}
                      />
                    )}

                    {activeTab === 'closedDeals' && (
                      <DealFlowTable
                        columns={columns}
                        data={closeDealFlowData || []}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                        pageCount={Math.ceil(
                          totalClosedDealFlow / itemsPerPage,
                        )}
                        isLoading={closedDealFlowLoading}
                        Fallback={() => (
                          <div className="flex flex-col items-center justify-center py-5 px-4 text-center">
                            <div className="w-24 h-24 mb-6 text-gray-300">
                              <FaHandshake className="w-full h-full" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-700 mb-2 text-center">
                              No Closed Deals Yet
                            </h3>
                            <p className="text-gray-500 text-center max-w-md mb-8">
                              Successfully closed deals will appear here once
                              deals reach their completion. This includes deals
                              that have been finalized with signed agreements
                              and completed transactions.
                            </p>
                          </div>
                        )}
                        onRowClick={onRowClick}
                      />
                    )}
                  </div>
                )}
              </div>
            </section>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DealFlow;
