import React, { useState } from "react";
import {
  Center,
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { mutateFunction, useMutation } from "../../libs/apis";
import Logout from "../../utils/Logout";
import ShareContent from "../feed/ShareContent";

export default function ShareContentModal({ 
  isOpen, 
  onClose, 
  message,
  subject,
  link
}) {
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { doLogout } = Logout()


  const handleLogout = () => {
    mutate(
      { key: "logout", method: "get" },
      {
        onSuccess(res) {
          onClose()
          doLogout();
        },
      }
    );
  };

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={"16px"} className="w-[100vw] max-w-5xl">
          <ModalBody p={0}>
            <Center d="flex" flexDirection="column" className="gap-2 mb-5" >
                <Text textAlign={"center"} fontWeight={500} fontSize={19} color="#000">
                  Share Post
                </Text>
            </Center>
            <ShareContent 
              link={link}
              subject={subject}
              content={{
                // facebook: message,
                twitter: message,
                linkedIn: link,
                email: message,
                whatsapp: message,
                SMS: message,
                // messenger: message,
                link: true,
              }}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
