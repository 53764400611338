import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation, mutateFunction } from '../../libs/apis';
import BrandButton from '../brandButton/BrandButton';
import { CustomToast } from '../toast/CustomToast';
import { BsPlusCircle } from 'react-icons/bs';
import { setChatInfo } from '../../redux-toolkit/reducers/chatSlice';

const ConnectionButton = ({ user, size, onCompleteAction = () => {} }) => {
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const queryClient = useQueryClient();
  const { addToast } = CustomToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [a, setA] = useState('a');

  const connectWithUser = () => {
    mutate(
      {
        key: `users/${loggedInUserId}/connect/${user.id}`,
        method: 'post',
        data: {},
      },
      {
        onSuccess(res) {
          addToast(res);
          onCompleteAction(user.id, 'Connection Request Sent');
          user.connectionStatus = 'Connection Request Sent';
        },
      },
    );
  };

  const acceptRequest = () => {
    mutate(
      {
        key: `users/${loggedInUserId}/connections/${user?.connection?.connection_id || user?.connection?.id}`,
        method: 'put',
      },
      {
        onSuccess(res) {
          addToast(res);
          onCompleteAction(user.id, 'Connected');
          user.connectionStatus = 'Connected';
          user.connection.accepted
            ? (user.connection.accepted = true)
            : (user.accepted = true);
        },
      },
    );
  };

  const chatWithConnection = () => {
    dispatch(setChatInfo(user));
    navigate('../connections');
  };

  return (
    <>
      {user?.connectionStatus === 'Not Connected' && (
        <BrandButton
          fontSize="13px"
          color="#000"
          bg="#C7DFF0"
          borderColor="#C7DFF0"
          icon={<BsPlusCircle className="text-[#000] text-lg" />}
          isLoading={mutateIsLoading}
          text="Follow"
          w="full"
          maxW="400px"
          onClick={() => connectWithUser()}
          {...size}
        />
      )}
      {user?.connectionStatus === 'Connection Request Received' && (
        <BrandButton
          fontSize="13px"
          text="Accept"
          bg="#20B962"
          colorScheme="blue"
          w="full"
          maxW="400px"
          onClick={() => acceptRequest()}
          {...size}
        />
      )}
      {user?.connectionStatus === 'Connection Request Sent' && (
        <BrandButton
          fontSize="13px"
          text="Followed"
          borderColor="#C7DFF0"
          isDisabled
          bg={'#134A70'}
          colorScheme="blue"
          w="full"
          maxW="400px"
          {...size}
        />
      )}
      {user?.connectionStatus === 'Connected' && (
        <BrandButton
          fontSize="13px"
          text="Start Conversation"
          colorScheme="blue"
          bg="#20B962"
          w="full"
          maxW="400px"
          onClick={() => chatWithConnection()}
          {...size}
        />
      )}
    </>
  );
};
export default ConnectionButton;
