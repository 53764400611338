import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Input from '../dealFlow/Input';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { useMutation, mutateFunction } from '../../libs/apis';
import { useQueryClient } from 'react-query';
import { CustomToast } from '../toast/CustomToast';

const seedOptions = [
  'Pre-Seed Stage',
  'Seed Stage',
  'Series A',
  'Series B',
  'Series C',
  'Series D',
  'Series E',
  'Series F',
  'Pre-IPO / Mezzanine Financing',
  'IPO (Initial Public Offering)',
];

const CreateDealFlowModal = ({
  isOpen,
  onClose,
  currentPage,
  itemsPerPage,
}) => {
  const { addToast } = CustomToast();
  const schema = yup.object({
    name: yup.string().required('Name is required'),
    companyId: yup.string().required('Company is required'),
    valuation: yup.string().required('Valuation is required'),
    stage: yup.string().required('Stage is required'),
  });

  const { mutate: createDealFlow, isLoading: mutateIsLoading } =
    useMutation(mutateFunction);

  const queryClient = useQueryClient();

  const [selectedCompany, setSelectedCompany] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyId: '',
      valuation: selectedCompany?.valuation ?? '',
      stage: '',
    },
    mode: 'onBlur',
  });

  const watchedSeed = watch('stage');
  const companyInfo = useSelector((state) => state?.company?.companyInfo);
  const allCompaniesInfo = useSelector(
    (state) => state?.allcompanies?.allCompaniesInfo,
  );

  const [companyOptions, setCompanyOptions] = useState([]);
  console.log('companyinfo', companyInfo);
  console.log('allCompaniesInfo', allCompaniesInfo);
  console.log('selectedCompany', selectedCompany);

  useEffect(() => {
    if (watchedSeed) {
      setValue('name', watchedSeed);
    }
  }, [watchedSeed]);

  useEffect(() => {
    if (allCompaniesInfo) {
      setCompanyOptions(
        allCompaniesInfo.map((company) => ({
          value: company.id,
          label: company.title,
          valuation: company.valuation,
        })),
      );
    }
  }, [allCompaniesInfo]);

  const onSubmit = (data) => {
    console.log('data', data);

    createDealFlow(
      {
        key: 'deal-flow/create',
        method: 'post',
        data: {
          name: data.name,
          company_id: data?.companyId,
          valuation: data?.valuation,
          stage: data?.stage,
        },
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(
            `deal-flow?page_no=${currentPage + 1}&page_size=${itemsPerPage}`,
          );
          queryClient.invalidateQueries(`deal-flow/metrics`);
          addToast(res);
          onClose();
        },
        onError: (err) => {
          addToast(
            err?.response?.data?.message || 'Error creating deal flow',
            'error',
          );
        },
      },
    );
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deal Flow</ModalHeader>
          <ModalBody p={4}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className="w-full flex flex-col gap-4"
            >
              <div className="flex flex-col gap-1">
                <div className="flex items-center">
                  <label>Company</label>
                  <span>*</span>
                </div>

                <Controller
                  name="companyId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select a company"
                      options={companyOptions}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: 'transparent',
                          borderColor: '#00000033',
                          borderRadius: '0.25rem',
                          padding: '0.125rem',
                          boxShadow: 'none',
                          '&:hover': {
                            borderColor: '#00000033',
                          },
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          color: '#17171780',
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          color: 'inherit',
                        }),
                      }}
                      value={companyOptions.find(
                        (option) => option.value === field.value,
                      )}
                      onChange={(option) => {
                        field.onChange(option.value);
                        setSelectedCompany(option);
                      }}
                    />
                  )}
                />

                {errors.companyId ? (
                  <p className="flex self-start text-red-500 font-semibold mt-0.5 text-xs md:text-sm">
                    {errors.companyId.message}
                  </p>
                ) : null}
              </div>

              <div className="flex flex-col gap-1">
                <div className="flex items-center">
                  <label>Stage</label>
                  <span>*</span>
                </div>

                <Controller
                  name="stage"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select a seed"
                      options={seedOptions?.map((seed) => ({
                        label: seed,
                        value: seed,
                      }))}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: 'transparent',
                          borderColor: '#00000033',
                          borderRadius: '0.25rem',
                          padding: '0.125rem',
                          boxShadow: 'none',
                          '&:hover': {
                            borderColor: '#00000033',
                          },
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          color: '#17171780',
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          color: 'inherit',
                        }),
                      }}
                      value={seedOptions
                        ?.map((seed) => ({ label: seed, value: seed }))
                        .find((option) => option.value === field.value)}
                      onChange={(option) => {
                        field.onChange(option.value);
                      }}
                    />
                  )}
                />

                {errors.stage ? (
                  <p className="flex self-start text-red-500 font-semibold mt-0.5 text-xs md:text-sm">
                    {errors.stage.message}
                  </p>
                ) : null}
              </div>

              <Input
                label="Name"
                placeholder="Deal Flow Name"
                type="text"
                htmlFor="name"
                error={errors.name?.message}
                required
                {...register('name')}
              />

              <Input
                label="Valuation"
                placeholder="$10,000,000"
                type="number"
                htmlFor="valuation"
                error={errors.valuation?.message}
                required
                {...register('valuation')}
              />

              <button
                type="submit"
                className="bg-[#134A70] text-white px-4 py-2 rounded w-fit"
              >
                {mutateIsLoading ? 'Creating...' : 'Create'}
              </button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CreateDealFlowModal;
