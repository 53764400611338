import React, { useContext, useEffect } from 'react';
import PostAgreementStage from '../../components/dealFlow/PostAgreementStage';
import { UserContext } from '../../context/UserContext';

const PostAgreementStagePage = () => {
  const { setActivePage } = useContext(UserContext);

  useEffect(() => {
    setActivePage('/deal-flow');
  }, [setActivePage]);

  return (
    <>
      <PostAgreementStage />
    </>
  );
};

export default PostAgreementStagePage;
