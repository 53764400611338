import ForgotPassword from "../pages/auth/ForgotPassword/ForgotPassword";
import Login from "../pages/auth/Login/Login";
import Signup from "../pages/auth/Signup/SignUp";
import HomePage from "../pages/homepage/HomePage";
import VerifyEmail from "../pages/auth/verifyEmail/VerifyEmail";
import { publicUrls } from "../url/urls";
import ResetPassword from "../pages/auth/ForgotPassword/ResetPassword";
import VerifyAccountDeletion from "../pages/auth/verifyAccountDeletion/verifyAccountDeletion";
import PrivacyPolicy from "../pages/privacyPolicy/policy";
import PostPage from "../pages/founder/Post";
import PublicPostPage from "../pages/founder/Post/publicPost";

export const publicRoutes = [
  {
    title: 'Default Page',
    path: publicUrls.default(),
    element: Login,
  },
  {
    title: 'Home Page',
    path: publicUrls.home(),
    element: HomePage,
  },
  {
    title: 'Signup Page',
    path: publicUrls.signUp(),
    element: Signup,
  },
  {
    title: 'Login Page',
    path: publicUrls.login(),
    element: Login,
  },
  {
    title: 'Forgot Password',
    path: publicUrls.forgotPassword(),
    element: ForgotPassword,
  },
  {
    title: 'Reset Password',
    path: publicUrls.resetPassword(),
    element: ResetPassword,
  },
  {
    title: 'Verify Email',
    path: publicUrls.verifyEmail(),
    element: VerifyEmail,
  },
  {
    title: 'Verify Account Deletion',
    path: publicUrls.VerifyAccountDeletion(),
    element: VerifyAccountDeletion,
  },
  {
    title: 'Privacy policy',
    path: publicUrls.privacyPolicy(),
    element: PrivacyPolicy,
  },
  {
    title: 'Post',
    path: publicUrls.post(),
    element: PublicPostPage,
  }
]