import React, { forwardRef } from 'react';

const Input = forwardRef(
  (
    {
      placeholder,
      label,
      htmlFor,
      error,
      type = 'text',
      required = false,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="w-full">
        <div className="w-ful flex flex-col gap-4">
          <div className="flex items-center">
            <label>{label}</label>
            {required && <span>*</span>}
          </div>

          <div className="flex flex-col gap-1">
            <input
              ref={ref}
              id={htmlFor}
              type={type}
              placeholder={placeholder}
              className="w-full bg-transparent outline-none border border-[#00000033] rounded p-2 placeholder:text-[#17171780]"
              {...props}
            />

            {error ? (
              <p className="flex self-start text-red-500 font-semibold mt-0.5 text-xs md:text-sm">
                {error}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

// Add display name for dev tools
Input.displayName = 'Input';

export default Input;
