import {
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  VStack,
  Alert,
  Button,
  InputRightAddon,
  useIds,
  useDisclosure,
  Textarea,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useData } from '../../data';
import IsDesktop from '../../utils/IsDesktop';
import { GrAttachment } from 'react-icons/gr';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  TbMessageCircleOff,
  TbFile,
  TbPhoto,
  TbFileText,
  TbLink,
} from 'react-icons/tb';
import upload from '../../public/icons_v2/upload.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import StyledSpinner from '../../components/spinner/Spinner';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { mutateFunction, useMutation } from '../../libs/apis';
import { CustomToast } from '../../components/toast/CustomToast';
import BrandButton from '../../components/brandButton/BrandButton';
import { useNavigate } from 'react-router-dom';
import { useInterval } from '../../utils/useInterval';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import Linkify from 'linkify-react';
import client from '../../libs/client';
import { BASE_URL } from '../../helpers/constant';
import { UserContext } from '../../context/UserContext';
import OpenExternalAdvertLinkModal from '../../components/modal/OpenExternalAdvertLinkModal';

const Discussions = ({}) => {
  const navigate = useNavigate();
  const { emitEvent, connectSocket } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const currentDealFlow = useSelector(
    (state) => state?.dealFlow?.currentDealFlow,
  );
  const [messages, setMessages] = useState([]);
  const [participants, setParticipants] = useState([]);
  const isDesktop = IsDesktop();
  const [inputMessage, setInputMessage] = useState('');
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [images, setImages] = useState([]);
  const imagesInputRef = useRef(null);
  const [documents, setDocuments] = useState([]);
  const documentsInputRef = useRef(null);
  const [attachmentType, setAttachmentType] = useState('');
  const maxBytes = 10485760;
  const messagesEndRef = useRef(null);

  const [lastSeen, setLastSeen] = useState('');

  const queryClient = useQueryClient();
  const {
    data: messagesData = null,
    isLoading: messagesIsLoading = false,
    refetch: refetchMessages = () => {},
  } = useData(`deal-flow/messages/${currentDealFlow?.id}`);

  const openFileUpload = (e) => {
    fileInputRef.current.click();
  };

  const handleChangeForFile = (e) => {
    setDocuments([]);
    setImages([]);
    let isTooLarge = [...e.target.files].some((file) => file.size > maxBytes);
    if (isTooLarge) {
      setTimeout(() => {
        addToast({
          message: 'One or more files are greater than 10MB',
          type: 'warning',
        });
      }, 500);
      return;
    }
    if (e.target.files.length < 8) {
      setFiles(e.target.files);
      setAttachmentType('file');
    } else {
      setTimeout(() => {
        addToast({ message: 'More than 7 files selected', type: 'warning' });
      }, 500);
    }
  };

  const openImageUpload = (e) => {
    imagesInputRef.current.click();
  };

  const handleChangeForImage = (e) => {
    setFiles([]);
    setDocuments([]);
    let isTooLarge = [...e.target.files].some((file) => file.size > maxBytes);
    if (isTooLarge) {
      setTimeout(() => {
        addToast({
          message: 'One or more files are greater than 10MB',
          type: 'warning',
        });
      }, 500);
      return;
    }
    if (e.target.files.length < 8) {
      setImages(e.target.files);
      setAttachmentType('image');
    } else {
      setTimeout(() => {
        addToast({ message: 'More than 7 files selected', type: 'warning' });
      }, 500);
    }
  };

  const openDocumentUpload = (e) => {
    documentsInputRef.current.click();
  };

  const handleChangeForDocument = (e) => {
    setFiles([]);
    setImages([]);
    let isTooLarge = [...e.target.files].some((file) => file.size > maxBytes);
    if (isTooLarge) {
      setTimeout(() => {
        addToast({
          message: 'One or more files are greater than 10MB',
          type: 'warning',
        });
      }, 500);
      return;
    }
    if (e.target.files.length < 8) {
      setDocuments(e.target.files);
      setAttachmentType('document');
    } else {
      setTimeout(() => {
        addToast({ message: 'More than 7 files selected', type: 'warning' });
      }, 500);
    }
  };

  const clearData = () => {
    setFiles([]);
    setDocuments([]);
    setImages([]);
    setAttachmentType('');
    setInputMessage('');
  };

  useEffect(() => {
    // if (recipientId) {
    //   client()
    //     .get(`/connections/${recipientId}/messages`)
    //     .then((res) => {
    //       setMessages(res?.data?.data?.messages?.sort((a, b) => moment(a?.createdAt).diff(moment(b?.createdAt))));
    //       intervalId = setInterval(() => {
    //         client()
    //           .get(`/connections/${recipientId}/messages`)
    //           .then((res) => {
    //             setMessages(res?.data?.data?.messages?.sort((a, b) => moment(a?.createdAt).diff(moment(b?.createdAt))));
    //           });
    //       }, 10000);
    //     });
    // }
    // return () => clearInterval(intervalId);
  }, [messagesData, messagesEndRef?.current]);

  // useEffect(() => {
  //   if (socket) {
  //     socket.on("private_message_received", (data) => {
  //       setMessages((data));
  //     });
  //   }
  //   // socket.on("private_message_received", (data) => {
  //   //   setMessages((data));
  //   // });
  // }, [socket]);

  useEffect(() => {
    connectSocket();
    //refetchConnections()
    console.log('Messages========', messages, messagesData);
    if (
      (!messages || messages?.length === 0) &&
      !messagesData?.data?.messages
    ) {
      client()
        .get(`${BASE_URL}deal-flow/messages/${currentDealFlow?.id}`)
        .then((res) => {
          setParticipants(res?.data?.data?.participants);
          setMessages(res?.data?.data?.messages);
        });
    } else {
      setMessages(messagesData?.data?.messages);
      setParticipants(messagesData?.data?.participants);
    }
  }, [messagesData, currentDealFlow]);

  useEffect(() => {
    if (messagesEndRef?.current) {
      messagesEndRef.current?.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [messages?.length]);

  const updateParties = (data = []) => {
    let messageId = data[0].deal_id || '';
    console.log('The dtat===', data);
    if (messageId) {
      emitEvent('incomingdealmessages', {
        messages: data,
      });
      setMessages((prev) => [...prev, ...data]);
    }
  };

  const sendMessage = () => {
    if (mutateIsLoading) return;

    const attachments = [...files, ...images, ...documents];

    if (inputMessage.length < 1 && attachments.length < 1) {
      addToast({ message: 'Message cannot be empty', type: 'warning' });
      return;
    }

    let formData = new FormData();
    formData.append('content', inputMessage.trim());
    formData.append('sender_id', loggedInUser?.id);
    formData.append('deal_id', currentDealFlow?.id);
    formData.append('attachmentType', attachmentType);
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }
    mutate(
      {
        key: `deal-flow/message`,
        method: 'post',
        data: formData,
      },
      {
        onSuccess(res) {
          addToast(res);
          //refetchMessages();
          clearData();
          updateParties(res.data?.messages);
          document.getElementById('message-input').value = '';
        },
      },
    );
  };

  const handleChange = (e) => {
    setInputMessage(e.target.value);
  };

  //   const goToUser = (profile) => {
  //     navigate(`../view-user?userid=${loggedInUser?.id === recipient?.target_user_id ? recipient?.source_user_id : recipient?.target_user_id}&usertype=${recipient?.user_type_id}`);
  //   };

  useInterval(() => {
    refetchMessages();
  }, 15000);

  const FileDisplay = ({ message }) => {
    let fileStringArr = message.content.split('/');
    let name = fileStringArr[fileStringArr.length - 1].split('_', 2)[1];

    return (
      <a
        className="cursor_pointer flex justify-between items-center w-[180px] no-decoration"
        href={message.content}
        download={name}
      >
        <Box className="flex flex-col gap-2">
          <TbFile />
          <Text className="max-w-[130px] hover:no-underline whitespace-nowrap overflow-hidden text-ellipsis">
            {name}
          </Text>
        </Box>
        <Box className="border h-6 w-6 grid place-items-center rounded-full">
          <DownloadIcon w={3} />
        </Box>
      </a>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // Shift + Enter pressed
      } else {
        event.preventDefault();
        sendMessage();
      }
    }
  };
  console.log('The current deal====', currentDealFlow);
  return (
    <Box h="100%" overflow="auto">
      <Box
        position="relative"
        w="full"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)"
        borderRadius="10px"
        px={isDesktop && 4}
        h={'100%'}
      >
        <VStack className="overflow-hidden" h={`100%`} spacing={0}>
          <Box position="sticky" top={0} zIndex={0} w="full" h="40px">
            <Box className="flex justify-between items-center flex-wrap">
              <Box>
                <Text className="font-semibold">Discussions</Text>
              </Box>
              <Box className="w-full md:w-min flex justify-end gap-1">
                {participants.map((item) => {
                  return (
                    <Box>
                      <Avatar
                        size="xs"
                        name={`${item?.user?.first_name} ${item?.user?.last_name}`}
                        src={item?.user?.profile_image}
                        alt="Avatar"
                        w="24px"
                        h="24px"
                        borderRadius="100%"
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box
            w="100%"
            px={2}
            display="flex"
            flex={1}
            flexDir="column"
            justifyContent="space-between"
            overflowY="scroll"
            className="py-5 max-h-[65vh]"
          >
            <style>
              {`
                    ::-webkit-scrollbar {
                      display: none;
                    }
                  `}
            </style>
            <VStack className="w-full">
              {messages?.length > 0 ? (
                (messages || []).map((message, index) => (
                  <Box
                    h="auto"
                    w="100%"
                    key={message?.id}
                    display="flex"
                    className="flex-col"
                    justifyContent={'flex-start'}
                  >
                    <Box className="self-center text-sm mb-4">
                      {index === 0 ||
                      !moment(new Date(messages[index]?.updatedAt)).isSame(
                        messages[index - 1]?.updatedAt,
                        'day',
                      )
                        ? moment(new Date(message?.updatedAt)).format(
                            'ddd, MMMM D, YYYY',
                          )
                        : ''}
                    </Box>
                    <VStack
                      minH={0}
                      pos="left"
                      mb={4}
                      w="100%"
                      h="auto"
                      alignItems="flex-start"
                      key={message?.id}
                    >
                      <Box className="flex gap-2 items-center">
                        <Box bg="rgba(54, 171, 190, 0.05)" h="auto">
                          <Avatar
                            size="xs"
                            name={`${message?.sender?.first_name} ${message?.sender?.last_name}`}
                            src={message?.sender?.profile_image}
                            alt="Avatar"
                            w="24px"
                            h="24px"
                            borderRadius="100%"
                          />
                        </Box>
                        <Text className="text-xs fofnt-semibold">{`${message?.sender?.first_name} ${message?.sender?.last_name}`}</Text>
                      </Box>
                      <Box className="flex flex-col items-end">
                        <Box
                          pos="left"
                          borderRadius="18px 18px 18px 0px"
                          px={2}
                          py={1}
                          className="text-sm w-full text-[#252631] mb-1.5"
                        >
                          {message?.type === 'text' ? (
                            (message?.content || '')
                              .split('\n')
                              .map((txt, index) => (
                                <Linkify
                                  key={index}
                                  options={{
                                    target: '_blank',
                                    attributes: {
                                      onClick: (e) => {
                                        e.stopPropagation();
                                      },
                                    },
                                  }}
                                >
                                  {txt}
                                  <br />
                                </Linkify>
                              ))
                          ) : (
                            <FileDisplay message={message} />
                          )}
                        </Box>
                      </Box>
                    </VStack>

                    <Box className="h-1 w-full mt-5 mb-2 border-t border-t-black">
                      <Text className="text-[13px] text-right font-light text-[#778CA2]">
                        {index === 0 ||
                        !moment(messages[index]?.updatedAt).isSame(
                          messages[index - 1]?.updatedAt,
                          'minute',
                        ) ||
                        messages[index]?.sender_id !=
                          messages[index - 1]?.sender_id
                          ? moment(new Date(message?.updatedAt)).format(
                              'h:mm A',
                            )
                          : ''}
                      </Text>
                    </Box>
                  </Box>
                ))
              ) : (
                <Flex w="100%" justifyContent="center">
                  {!messagesIsLoading && messages?.length === 0 && (
                    <Text>No messages...</Text>
                  )}
                  {messagesIsLoading && <StyledSpinner />}
                </Flex>
              )}
              <div ref={messagesEndRef} />
            </VStack>
          </Box>
          <Box
            className={`w-full min-h-[25px] ${(files || images || documents) && files.length + images.length + documents.length > 0 ? '' : 'min-h-[2px]'}`}
          >
            {(files || images || documents) &&
            files.length + images.length + documents.length > 0 ? (
              <Flex className="items-center w-full justify-between bg-gray-100 py-1 px-2 ">
                <Flex className="w-full gap-3 items-center">
                  <Text
                    className={`max-w-[60%] text-sm whitespace-nowrap overflow-hidden text-ellipsis`}
                  >
                    {[...files, ...documents, ...images][0]?.name}
                  </Text>
                  {files.length + images.length + documents.length > 1 ? (
                    <Box className="rounded-lg bg-[#134A70] text-white font-bold text-[11px] grid place-items-center w-6">
                      {files.length + images.length + documents.length < 11
                        ? `+${files.length + images.length + documents.length - 1}`
                        : '10+'}
                    </Box>
                  ) : null}
                </Flex>
                <Box
                  onClick={clearData}
                  className="border cursor-pointer border-red-400 rounded-full px-1 grid place-items-center"
                >
                  <CloseIcon color="red.400" w={2} />
                </Box>
              </Flex>
            ) : null}
          </Box>
          <Box w="full">
            <textarea
              id="message-input"
              placeholder="Write a comment"
              onChange={(e) => handleChange(e)}
              _active={{ border: '1px solid red' }}
              onKeyDown={handleKeyDown}
              pl={'15px'}
              rounded="lg"
              className={`
                       w-full px-3 py-2 pl-4 border border-gray-200 rounded-xl focus:outline-none resize-none overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400
                    `}
              style={{
                maxHeight: '1rem',
                minHeight: '3.5rem',
              }}
            />
            <Box className="w-full flex mt-5 items-center">
              <BrandButton
                fontSize="13px"
                color="#000"
                bg="primary"
                isLoading={mutateIsLoading}
                text="Post Comment"
                w="full"
                onClick={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              />
            </Box>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

export default Discussions;
