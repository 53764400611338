import React, { useState, useEffect } from 'react';
import { Box, Text, Skeleton } from '@chakra-ui/react';
import Header from '../header/Header';
import HeaderSection from './HeaderSection';
import uploadIcon from '../../public/icons_v2/upload-icon.svg';
import { Image } from '@chakra-ui/react';
import { investors } from './data';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Discussions from './Discussions';
import Loader from './Loader';
import { useData } from '../../data';
import moment from 'moment';
import CloseDealFlowModal from '../modal/CloseDealFlowModal';
import { useMutation, mutateFunction } from '../../libs/apis';
import { useQueryClient } from 'react-query';
import { CustomToast } from '../toast/CustomToast';

// const UploadButton = ({
//   name,
//   uploadedFile,
//   setUploadedFile,
//   handleFileUpload,
// }) => {
//   return (
//     <Box className="w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
//       <Box className="flex flex-col w-full">
//         <label htmlFor="file-upload" className="cursor-pointer w-full">
//           <Box className="mt-2 gap-2 border p-2 rounded-lg border-[#00000033] flex items-center justify-center hover:bg-gray-50 w-full">
//             <Image h="22px" w="22px" src={uploadIcon} alt="dashboard-icon" />
//             <p>{name}</p>
//           </Box>
//         </label>
//         <input
//           id="file-upload"
//           type="file"
//           className="hidden"
//           onChange={handleFileUpload}
//           accept=".pdf,.doc,.docx,.txt"
//           key={uploadedFile ? undefined : 'file-input'}
//         />

//         {/* Display uploaded file name */}
//         {uploadedFile && (
//           <Box className="mt-3 flex items-center gap-2 w-full">
//             <Box className="flex items-center gap-2 bg-gray-100 py-1 px-3 rounded flex-1 min-w-0">
//               <Image h="16px" w="16px" src={uploadIcon} alt="file-icon" />
//               <Text className="text-sm text-gray-700 truncate">
//                 {uploadedFile.name}
//               </Text>
//             </Box>
//             <Box
//               className="cursor-pointer text-red-500 hover:text-red-600 flex-shrink-0"
//               onClick={() => {
//                 setUploadedFile(null);
//                 const fileInput = document.getElementById('file-upload');
//                 if (fileInput) fileInput.value = '';
//               }}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-5 w-5"
//                 viewBox="0 0 20 20"
//                 fill="currentColor"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//             </Box>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// };

const InvestmentDetails = () => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  const currentDealFlow = useSelector(
    (state) => state?.dealFlow?.currentDealFlow,
  );

  return (
    <Box className="w-full overflow-y-auto hide-scroll flex flex-col gap-4">
      <Text className="text-[#2F4B6E] font-semibold">
        Investment Details -{' '}
        {moment(currentDealFlow.createdAt).format('DD/MM/YYY')}
      </Text>
      <div className="p-2 w-fit rounded bg-[#197FC6] text-white">
        Equity investment
      </div>
      <Box className="w-full flex flex-col gap-4">
        <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
          <p>Amount committed</p>
          <p>${Number(currentDealFlow.amount_committed)?.toLocaleString()}</p>
        </div>
        <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
          <p>Number of investor</p>
          <p>{currentDealFlow.no_of_investors}</p>
        </div>
        <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
          <p>Percentage</p>
          <p>{currentDealFlow.percentage * 100}%</p>
        </div>
        <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
          <p>Pre-valuation worth</p>
          <p>${Number(currentDealFlow.company.valuation)?.toLocaleString()}</p>
        </div>
        <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
          <p>Post-valuation worth</p>
          <p>
            $
            {Number(
              currentDealFlow.company.valuation +
                currentDealFlow.amount_committed,
            )?.toLocaleString()}
          </p>
        </div>
      </Box>

      {/* upload document */}
      {/* <UploadButton
        name="Document"
        uploadedFile={uploadedFile}
        setUploadedFile={setUploadedFile}
        handleFileUpload={handleFileUpload}
      /> */}
    </Box>
  );
};

const CompanyUpdate = () => {
  return (
    <Box className="rounded-lg bg-[#F4F7FD] p-4">
      <Discussions />
    </Box>
  );
};

const TermSheetView = ({
  accountType,
  dealParticipantsLoading,
  dealParticipantsData,
  selectedTermSheet,
  setSelectedTermSheet,
  selectedInvestor,
  setSelectedInvestor,
  investorInfos,
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const queryClient = useQueryClient();
  const { mutate: uploadProofOfFund, isLoading: proofOfFundLoading } =
    useMutation(mutateFunction);

  const currentDealFlow = useSelector(
    (state) => state?.dealFlow?.currentDealFlow,
  );

  const { addToast } = CustomToast();

  const handleFileUpload = (event) => {
    const formData = new FormData();

    const file = event.target.files[0];
    if (file) {
      if (!file.type.match('image.*')) {
        addToast('Please upload only image files (JPEG, PNG, etc.)', 'error');
        return;
      }

      formData.append('attachments', file);
      uploadProofOfFund(
        {
          key: `termsheet/${selectedTermSheet?.id}/funds`,
          method: 'put',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        {
          onSuccess: (res) => {
            queryClient.invalidateQueries(
              `termsheet/${currentDealFlow?.id}/participants`,
            );
            addToast(res);
          },
          onError: (err) => {
            addToast(
              err?.response?.data?.message || 'Error uploading proof of funds',
              'error',
            );
          },
        },
      );
      setUploadedFile(file);
    }
  };

  console.log(
    'selectedtermSheet',
    selectedTermSheet,
    selectedTermSheet?.proof_of_funds?.[0],
  );

  if (
    !dealParticipantsLoading &&
    (!dealParticipantsData || dealParticipantsData.length === 0)
  ) {
    return (
      <Box className="w-full h-full flex items-center justify-center">
        <Box className="text-center">
          <Text className="text-2xl font-semibold text-gray-700 mb-2">
            No Term Sheets Available
          </Text>
          <Text className="text-gray-500">
            There are currently no term sheets submitted for this deal flow.
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="w-full overflow-y-auto hide-scroll h-full flex flex-col-reverse md:flex-row items-start gap-5 md:gap-10 justify-between">
      <Box className="w-full flex flex-col gap-4">
        <Text className="text-[#2F4B6E] font-semibold">
          Acme tech - Term Sheet Details -{' '}
          {moment(selectedTermSheet?.createdAt).format('DD/MM/YYY')}
        </Text>

        <Box className="w-full flex flex-col gap-4">
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Amount committed</p>
            <p>
              ${Number(selectedTermSheet?.investment_amount)?.toLocaleString()}
            </p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Valuation worth</p>
            <p>
              $
              {Number(selectedTermSheet?.investor?.valuation)?.toLocaleString()}
            </p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Stage</p>
            <p>{selectedTermSheet?.deal?.stage}</p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Eqiuity Percentage</p>
            <p>{`${selectedTermSheet?.equity_percentage * 100}%`}</p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Investment Vehicle</p>
            <p>Equity</p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Investment History</p>
            <p>5</p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Company Invested In</p>
            <p>4</p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Engagement Style</p>
            <p>Active</p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Preferred Sector</p>
            <p>
              {selectedTermSheet?.investor?.business_category
                ?.map((item) => item?.title)
                ?.join(',') || '-'}
            </p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>NDAs</p>
            <p>Accepted</p>
          </div>
          <div className="flex items-center justify-between border-b border-[#00000033] pb-2">
            <p>Accredited Investor</p>
            <p>Seed Investor</p>
          </div>
        </Box>

        {/* upload proof of funds */}
        {selectedTermSheet?.proof_of_funds?.[0] ? (
          <div className="max-w-[200px] max-h-[200px]">
            <img
              src={selectedTermSheet?.proof_of_funds?.[0]}
              alt="Preview"
              className="rounded-xl w-full h-full object-cover"
            />
          </div>
        ) : (
          // <UploadButton
          //   name="Proof of Funds"
          //   uploadedFile={uploadedFile}
          //   setUploadedFile={setUploadedFile}
          //   handleFileUpload={handleFileUpload}
          // />

          <Box className="flex flex-col w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
            <label htmlFor="file-upload" className="cursor-pointer w-full">
              <Box className="mt-2 gap-2 border p-2 rounded-lg border-[#00000033] flex items-center justify-center hover:bg-gray-50 w-full">
                <Image
                  h="22px"
                  w="22px"
                  src={uploadIcon}
                  alt="dashboard-icon"
                />
                <p>{proofOfFundLoading ? 'uploading...' : 'Proof of Funds'}</p>
              </Box>
            </label>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              accept="image/*"
              key={uploadedFile ? undefined : 'file-input'}
            />
          </Box>
        )}
      </Box>

      {accountType === 'founder' && (
        <div className="w-full flex-1">
          <div className="w-full h-fit md:w-[200px] overflow-y-auto hide-scroll pr-2">
            <div className="flex flex-row md:flex-col gap-2 pb-4">
              {dealParticipantsLoading
                ? // Chakra UI Skeleton loading items
                  [...Array(8)].map((_, index) => (
                    <Skeleton
                      key={index}
                      className="w-full px-4 py-2 rounded-lg flex-shrink-0"
                      height="36px"
                    />
                  ))
                : // Actual investor items
                  investorInfos.map((investor) => {
                    // Find the corresponding termsheet to get status
                    const termsheet = dealParticipantsData.find(
                      (sheet) => sheet.id === investor.id,
                    );
                    let status = termsheet?.status || 'in_progress';

                    if (status === 'closed') {
                      if (!!termsheet?.rejected_at) {
                        status = 'rejected';
                      }

                      if (!!termsheet?.signed_at) {
                        status = 'signed';
                      }
                    }

                    // Define status indicator styles
                    const statusColors = {
                      signed: 'bg-green-500',
                      in_progress: 'bg-yellow-500',
                      rejected: 'bg-red-500',
                    };

                    return (
                      <div
                        onClick={() => {
                          setSelectedInvestor({
                            id: investor.id,
                            name: `${investor?.user?.first_name} ${investor?.user?.last_name}`,
                            email: investor?.user?.email ?? '',
                            phone: investor?.user?.phone_number ?? '',
                            company: investor ?? null,
                          });

                          setSelectedTermSheet(
                            dealParticipantsData.find(
                              (termSheet) => termSheet?.id === investor.id,
                            ),
                          );
                        }}
                        key={investor.id}
                        className={`
                            w-full px-4 py-2 cursor-pointer rounded-lg flex-shrink-0 relative
                            ${
                              selectedInvestor?.id === investor.id
                                ? 'bg-[#134A70] text-white border-[#134A70]'
                                : 'bg-white border border-[#E5E7EB] hover:border-[#134A70]'
                            }
                            transition-all duration-200
                          `}
                      >
                        {/* Status indicator dot */}
                        <div
                          className={`absolute top-2 right-2 w-2.5 h-2.5 rounded-full ${statusColors[status] || 'bg-gray-300'}`}
                          title={status.replace('_', ' ')}
                        />

                        <p className="text-center whitespace-nowrap truncate text-sm pr-4">
                          {`${investor?.user?.first_name} ${investor?.user?.last_name}`}
                        </p>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

const PostAgreementStage = () => {
  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle,
  );

  const userInfo = useSelector((state) => state?.user?.userInfo);

  const currentDealFlow = useSelector(
    (state) => state?.dealFlow?.currentDealFlow,
  );

  const currentTermSheetId = useSelector(
    (state) => state?.dealFlow?.currentTermSheetId,
  );

  const [activeTab, setActiveTab] = useState('tab1'); // or whatever default tab you want
  const [isCloseDealFlowModalOpen, setIsCloseDealFlowModalOpen] =
    useState(false);
  const [dealParticipantsData, setDealParticipantsData] = useState([]);
  const [selectedTermSheet, setSelectedTermSheet] = useState();
  const [investorInfos, setInvestorInfos] = useState([]);
  const [selectedInvestor, setSelectedInvestor] = useState();

  const {
    data: dealParticipants,
    isLoading: dealParticipantsLoading,
    isError: dealParticipantsError,
  } = useData(`termsheet/${currentDealFlow?.id}/participants`, 0, {});

  useEffect(() => {
    if (
      dealParticipants &&
      !dealParticipantsLoading &&
      !dealParticipantsError
    ) {
      setDealParticipantsData(dealParticipants?.data?.termSheets);

      if (accountType === 'founder') {
        console.log('run here');
        const termSheet = dealParticipants?.data?.termSheets?.[0];
        setSelectedTermSheet(termSheet);

        setInvestorInfos(
          dealParticipants?.data?.termSheets?.map((termSheet) => {
            return {
              id: termSheet.id,
              ...termSheet?.investor,
            };
          }),
        );

        const investor = termSheet?.investor;
        setSelectedInvestor({
          id: termSheet?.id,
          name: `${investor?.user?.first_name} ${investor?.user?.last_name}`,
          email: investor?.user?.email ?? '',
          phone: investor?.user?.phone_number ?? '',
          company: investor ?? null,
          // company:
        });
      } else {
        const termSheet = dealParticipants?.data?.termSheets?.find(
          (termSheet) => termSheet?.user_id === userInfo?.id,
        );
        setSelectedTermSheet(termSheet);
      }
    }
  }, [dealParticipants, dealParticipantsLoading, dealParticipantsError]);

  const currentPage = useSelector((state) => state?.dealFlow?.currentPage);

  console.log('dealParticipants', dealParticipants);
  console.log('currentdeal flow', currentDealFlow);
  console.log('selected termsheet', selectedTermSheet);
  console.log('selected investor', selectedInvestor);
  return (
    <Box>
      <CloseDealFlowModal
        isOpen={isCloseDealFlowModalOpen}
        onClose={() => setIsCloseDealFlowModalOpen(false)}
        dealFlowId={currentDealFlow?.id}
        currentPage={currentPage}
        invalidateKey={`termsheet/${currentDealFlow?.id}/participants`}
        openTermsheetsCount={currentDealFlow?.open_termsheets}
      />
      <Box className="h-screen flex flex-col">
        <Header title={'Post Agreement Stage'} />
        <Box className="flex-1 bg-gray-100 overflow-hidden">
          <Box className="h-full px-5 pt-4">
            <Box className="h-full bg-white rounded-lg p-4 flex flex-col">
              {/* Fixed header section */}
              <HeaderSection
                title="Acme Tech"
                subTitle="Post Agreement Stage"
                checkboxState="term_sheet_signed"
              />

              {/* Tab headers */}
              <Box className="mt-5 border-b border-gray-200">
                <Box className="flex items-end gap-4 overflow-x-auto hide-scroll whitespace-nowrap pb-2">
                  <Box
                    onClick={() => setActiveTab('tab1')}
                    className={`px-2 cursor-pointer flex-shrink-0 ${
                      activeTab === 'tab1'
                        ? 'border-b-2 border-[#134A70] text-[#134A70]'
                        : 'text-gray-500'
                    }`}
                  >
                    <Text className="text-base font-medium">Investment</Text>
                  </Box>
                  <Box
                    onClick={() => setActiveTab('tab2')}
                    className={`px-2 cursor-pointer flex-shrink-0 ${
                      activeTab === 'tab2'
                        ? 'border-b-2 border-[#134A70] text-[#134A70]'
                        : 'text-gray-500'
                    }`}
                  >
                    <Text className="text-base font-medium">
                      Company Update
                    </Text>
                  </Box>

                  <Box
                    onClick={() => setActiveTab('tab3')}
                    className={`px-2 cursor-pointer flex-shrink-0 ${
                      activeTab === 'tab3'
                        ? 'border-b-2 border-[#134A70] text-[#134A70]'
                        : 'text-gray-500'
                    }`}
                  >
                    <Text className="text-base font-medium">
                      Term Sheet View
                    </Text>
                  </Box>
                </Box>
              </Box>

              {/* Tab content */}
              <Box className="flex flex-col justify-between w-full h-full overflow-y-auto hide-scroll gap-4 mt-6">
                {activeTab === 'tab1' && <InvestmentDetails />}
                {activeTab === 'tab2' && <CompanyUpdate />}
                {activeTab === 'tab3' && (
                  <TermSheetView
                    accountType={accountType}
                    termSheetData={selectedTermSheet}
                    dealParticipantsLoading={dealParticipantsLoading}
                    dealParticipantsData={dealParticipantsData}
                    selectedTermSheet={selectedTermSheet}
                    setSelectedTermSheet={setSelectedTermSheet}
                    selectedInvestor={selectedInvestor}
                    setSelectedInvestor={setSelectedInvestor}
                    investorInfos={investorInfos}
                  />
                )}

                {accountType === 'founder' &&
                  currentDealFlow?.status != 'closed' && (
                    <button
                      onClick={() => setIsCloseDealFlowModalOpen(true)}
                      className="w-full self-end mt-10 bg-[#134A70] text-white px-4 py-2 rounded-md"
                    >
                      Close Deal Flow
                    </button>
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {dealParticipantsLoading && !dealParticipantsError && <Loader />}
    </Box>
  );
};

export default PostAgreementStage;
