import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Text,
  Flex,
  HStack,
  Avatar,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import IsDesktop from '../../utils/IsDesktop';
import useData from '../../data/useData';
import { useDispatch, useSelector } from 'react-redux';
import BrandButton from '../brandButton/BrandButton';
import { useNavigate } from 'react-router-dom';
import { mutateFunction, useMutation } from '../../libs/apis';
import { useQueryClient } from 'react-query';
import { CustomToast } from '../toast/CustomToast';
import { setChatInfo } from '../../redux-toolkit/reducers/chatSlice';
import { BsChatLeftText } from 'react-icons/bs';

const ConnectionRequestTabs = ({
  onClose,
  notifications = [],
  onReadNotification = () => {},
}) => {
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const {
    data: connectionsData,
    isLoading,
    refetch: refetchConnections,
  } = useData(
    `users/${loggedInUserId}/connections?page_no=1&page_size=20`,
    30000,
  );
  const [senderIds, setSenderIds] = useState([]);
  const navigate = useNavigate();
  const isDesktop = IsDesktop();
  const [receivedConnections, setReceivedConnections] = useState([]);
  const [sentConnections, setSentConnections] = useState([]);
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const queryClient = useQueryClient();
  const { addToast } = CustomToast();
  const dispatch = useDispatch();

  useEffect(() => {
    if (connectionsData?.data) {
      setReceivedConnections(
        connectionsData?.data?.connections.filter(
          (each) =>
            each.target_user_id === loggedInUserId && each?.accepted !== true,
        ),
      );
      setSentConnections(
        connectionsData?.data?.connections.filter(
          (each) => each.target_user_id !== loggedInUserId,
        ),
      );
    }
  }, [connectionsData]);

  useEffect(() => {
    setSenderIds(notifications.map((item) => item.senderId));
  }, [notifications?.length]);

  const markAsReadNotificationId = (id) => {
    if (notifications?.length > 0) {
      const notification = notifications.filter(
        ({ senderId }) => senderId === id,
      );
      if (notification?.length > 0) {
        onReadNotification(notification[0].id);
      }
    }
  };

  const goToUser = (connection) => {
    onClose();
    navigate(
      `../view-user?userid=${
        loggedInUserId === connection.target_user_id
          ? connection.source_user_id
          : connection.target_user_id
      }&usertype=${connection.user_type_id}`,
    );
  };

  const acceptRequest = (connectionRequest) => {
    markAsReadNotificationId(connectionRequest.id);

    mutate(
      {
        key: `users/${loggedInUserId}/connections/${connectionRequest?.connection?.connection_id}`,
        method: 'put',
      },
      {
        onSuccess(res) {
          addToast(res);
          queryClient.invalidateQueries(`users`);
        },
      },
    );
  };

  const chatWithConnection = (connectionRequest) => {
    // /users/15/connections/2
    dispatch(setChatInfo(connectionRequest));
    // alert('dkkfkf')
    navigate('../connections');
    onClose();
  };

  return (
    <>
      {receivedConnections?.length > 0 && senderIds?.length > 0 ? (
        receivedConnections
          .filter((eachConnection) => senderIds.includes(eachConnection.id))
          .map((eachConnection) => (
            <>
              <Flex
                border={
                  eachConnection?.date_accepted == null
                    ? '2px solid #134A70'
                    : 'none'
                }
                key={eachConnection.id}
                mb={2}
                p={5}
                justifyContent="space-between"
                alignItems={'center'}
                h="72px"
                w="100%"
                boxShadow="0px 0px 4px lightgray"
              >
                <Flex w="60%">
                  <Box mr={4}>
                    <Avatar
                      borderRadius="10px"
                      name={`${eachConnection.first_name} ${eachConnection.last_name}`}
                      src={eachConnection.profile_image}
                      size={isDesktop ? 'md' : 'sm'}
                    />
                  </Box>
                  <Box d="flex" flexDir="column">
                    <Text
                      fontWeight={500}
                      fontSize={isDesktop ? '16px' : '12px'}
                    >
                      {`${eachConnection.first_name} ${eachConnection.last_name}`}
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  w="40%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text
                    cursor="pointer"
                    fontWeight={500}
                    fontSize={isDesktop ? '14px' : '12px'}
                    color="gray"
                    onClick={() => goToUser(eachConnection)}
                    display={{ base: 'none', md: 'flex' }}
                  >
                    View profile
                  </Text>
                  {!eachConnection.accepted && (
                    <BrandButton
                      isLoading={mutateIsLoading}
                      h="35px"
                      text="Accept"
                      width="100px"
                      colorScheme="blue"
                      onClick={() => acceptRequest(eachConnection)}
                    />
                  )}
                  {eachConnection.accepted && (
                    <>
                      <BrandButton
                        isLoading={mutateIsLoading}
                        h="35px"
                        text="Chat"
                        width="100px"
                        colorScheme="blue"
                        display={{ base: 'none', md: 'flex' }}
                        onClick={() => chatWithConnection(eachConnection)}
                      />
                      <Box
                        onClick={() => chatWithConnection(eachConnection)}
                        display={{ base: 'flex', md: 'none' }}
                        justifyContent={'flex-end'}
                        cursor={'pointer'}
                        width={'100%'}
                      >
                        <BsChatLeftText style={{ color: '#134A70' }} />
                      </Box>
                    </>
                  )}
                </Flex>
              </Flex>
            </>
          ))
      ) : (
        <>
          {isLoading ? (
            <Box className="w-full flex items-center justify-center my-4">
              <Spinner />
            </Box>
          ) : (
            <Box>You have no new connection requests.</Box>
          )}
        </>
      )}
    </>
  );
};
export default ConnectionRequestTabs;
