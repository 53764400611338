import {
  Box,
  Button,
  Center,
  Checkbox,
  Stack,
  HStack,
  VStack,
  Text,
  Avatar,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Icon,
  Wrap,
  WrapItem,
  Collapse,
  Flex,
  InputLeftAddon,
  Image,
  useDisclosure,
  Heading,
  Textarea,
  Spinner,
  Tabs,
  TabList,
  Tab,
  Select,
  Spacer,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  AiOutlineUser,
  AiOutlineMail,
  AiOutlineLock,
  AiOutlineEyeInvisible,
  AiOutlineUp,
  AiOutlineRight,
} from 'react-icons/ai';
import { FiEdit2, FiUpload } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/header/Header';
import { CustomToast } from '../../../components/toast/CustomToast';
import { mutateFunction, useMutation } from '../../../libs/apis';
import IsDesktop from '../../../utils/IsDesktop';
import { loginSchema } from '../../../validators';
import BrandButton from '../../../components/brandButton/BrandButton';
import lock from '../../../public/icons/lock.svg';
import { setUserInfo } from '../../../redux-toolkit/reducers/userSlice';
import UserService from '../../../utils/UserService';
import { setCompanyInfo } from '../../../redux-toolkit/reducers/companySlice';
import { setExpertInfo } from '../../../redux-toolkit/reducers/expertSlice';
import { setCompleteSurvey } from '../../../redux-toolkit/reducers/SurveySlice';
import MySlots from '../../experts/Settings/mySlots/MySlots';
import ppt from '../../../public/icons/ppt.svg';
import mp4 from '../../../public/icons/mp4.svg';
import EditIcon from '../../../public/icons_v2/edit.svg';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import DeleteAccountModal from '../../../components/modal/DeleteAccountModal';
import { useData } from '../../../data';
import { AddIcon, DeleteIcon, StarIcon } from '@chakra-ui/icons';
import Page from '../../survey/assets/Page';
import evaluateCompanyProfile from '../../../utils/evaluateCompanyProfile';
import { useClickOutside } from '../../../utils/useClickOutside';
import InvestorSurveyForm from '../../survey/InvestorSurveyForm';
import FounderSurveyForm from '../../survey/FounderSurveyForm';
import Survey from '../../survey/Survey';
import { setAllCompaniesInfo } from '../../../redux-toolkit/reducers/allCompaniesSlice';
import CustomerSupportModal from '../../../components/modal/CustomerSupportModal';
import { FaUser, FaUserAlt } from 'react-icons/fa';
import InviteUserModal from '../../../components/modal/InviteUserModal';
import { useQueryClient } from 'react-query';
import Loader from '../../../components/dealFlow/Loader';

const AccountSettings = () => {
  const navigate = useNavigate();
  const {
    isOpen: deleteAccountModalIsOpen,
    onOpen: onOpenDeleteAccountModal,
    onClose: closeDeleteAccountModal,
  } = useDisclosure();
  const {
    isOpen: shareInviteModalIsOpen,
    onOpen: onOpenShareInviteModal,
    onClose: onCloseShareInviteModal,
  } = useDisclosure();
  const {
    isOpen: submitComplaintModalIsOpen,
    onOpen: onOpenSubmitComplaintModal,
    onClose: onCloseSubmitComplaintModal,
  } = useDisclosure();
  const {
    isOpen: addCompanyModalIsOpen,
    onOpen: onOpenAddCompanyModal,
    onClose: onCloseAddCompanyModal,
  } = useDisclosure();

  const queryClient = useQueryClient();
  const isDesktop = IsDesktop();
  const { addToast } = CustomToast();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { mutate: mutateUpload, isLoading: isLoadingUpload } =
    useMutation(mutateFunction);
  const { mutate: mutateAddExtraCompany, isLoading: isLoadingAddExtraCompany } =
    useMutation(mutateFunction);
  const { mutate: mutateImageUpload, isLoading: isLoadingImageUpload } =
    useMutation(mutateFunction);
  const userDetails = useSelector((state) => state?.user?.userInfo);
  const {
    data,
    refetch,
    isLoading: isLoadingUserDetails,
    isError: isUserDetailsError,
  } = useData(`users/${userDetails.id}`);

  const { data: _surveydata, isLoading: surveyDataIsLoading } =
    useData('survey');
  const surveyData = _surveydata?.data;
  const [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  const topRef = useRef();
  const pitchDeckRef = useRef();
  const videoUrlRef = useRef();
  const companyStageRef = useRef();
  const incorporationLocationRef = useRef();
  const businessModelRef = useRef();
  const businessCategoryRef = useRef();
  const fundCategoryRef = useRef();
  const problemRef = useRef();
  const solutionRef = useRef();
  const marketGrowthRef = useRef();
  const revenueModelRef = useRef();
  const competitionRef = useRef();
  const fundsUseRef = useRef();
  const stakeholdersRef = useRef();
  const customerTypeRef = useRef();
  const expertCompanyRef = useRef();
  const expertCountryRef = useRef();
  const expertCategoryRef = useRef();
  const expertBioRef = useRef();
  const expertHeadlineRef = useRef();
  const expertPhoneRef = useRef();

  const [tabIndex, setTabIndex] = useState(1);

  const refs = {
    pitchDeckRef,
    videoUrlRef,
    companyStageRef,
    incorporationLocationRef,
    businessModelRef,
    businessCategoryRef,
    fundCategoryRef,
    problemRef,
    solutionRef,
    marketGrowthRef,
    revenueModelRef,
    competitionRef,
    fundsUseRef,
    stakeholdersRef,
    customerTypeRef,
    expertCompanyRef,
    expertCountryRef,
    expertCategoryRef,
    expertBioRef,
    expertHeadlineRef,
    expertPhoneRef,
  };

  const toastAlerts = {
    pitchDeck: 'Add a pitch deck to increase visibility',
    videoUrl: 'Add an explainer video to increase visibility',
    incorporationLocation: `Select your company's incorporation locations to improve company profile`,
    businessCategory: `Select your company's business category to improve company profile`,
    businessModel: `Select your company's business model to improve company profile`,
    fundCategory: `Select your company's fund category to improve company profile`,
    companyStage: `Select your company's stage to improve company profile`,
    problem: 'Add a problem statement to improve company profile',
    solution: 'Add a solution statement to improve company profile',
    marketGrowth: 'Add market penetration brief to improve company profile',
    revenueModel: 'Add a revenue strategy brief to improve company profile',
    competition: 'List potential competitors to improve company profile',
    fundsUse: 'Add fund allocation strategy to improve company profile',
    stakeholders: 'Add a list of current stakeholders',
    customerType: 'Add a list of targeted customer bases',
    expertCompany: 'Add name of your current company',
    expertCountry: 'Select the country you currently work from',
    expertCategory: 'Select your job category',
    expertHeadline: 'Add your positon',
    expertPhone: 'Add your phone number',
    expertBio: 'Please add a brief bio',
  };

  const [loading, setLoading] = useState(false);

  const [userDetailsData, setUserDetailsData] = useState({
    first_name: data?.data?.user?.first_name,
    last_name: data?.data?.user?.last_name,
    email: data?.data?.user?.email,
    profile_image: data?.data?.user?.profile_image,
  });

  const [pushNotf, setPushNotf] = useState(data?.data?.user?.push_notification);
  const [emailNotf, setEmailNotf] = useState(
    data?.data?.user?.email_notification,
  );
  const [currentCompanyIndex, setCurrentCompanyIndex] = useState('0');
  const [inboxNotification, setInboxNotification] = useState(
    data?.data?.user?.systemPreferences?.inboxNotification || false,
  );
  const [friendRequestNotification, setFriendRequestNotification] = useState(
    data?.data?.user?.systemPreferences?.friendRequestNotification || false,
  );
  const [promoNotf, setPromoNotf] = useState(
    data?.data?.user?.promotional_newsletter,
  );

  useEffect(() => {
    setPushNotf(data?.data?.user?.push_notification);
    setEmailNotf(data?.data?.user?.email_notification);
    setPromoNotf(data?.data?.user?.promotional_newsletter);
    setFriendRequestNotification(
      data?.data?.user?.systemPreferences?.friendRequestNotification,
    );
    setInboxNotification(
      data?.data?.user?.systemPreferences?.inboxNotification,
    );
  }, [
    data?.data?.user?.email_notification,
    data?.data?.user?.promotional_newsletter,
    data?.data?.user?.push_notification,
    data?.data?.user?.systemPreferences?.inboxNotification,
    data?.data?.user?.systemPreferences?.friendRequestNotification,
  ]);

  useEffect(() => {
    setUserDetailsData({
      first_name: data?.data?.user?.first_name,
      last_name: data?.data?.user?.last_name,
      email: data?.data?.user?.email,
      profile_image: data?.data?.user?.profile_image,
    });
  }, [data?.data?.user]);

  const [profilePicture, setProfilePicture] = useState(
    data?.data?.user?.cover_image || userDetails?.profile_image,
  );
  const companyDetails = useSelector((state) => state?.company?.companyInfo);
  const expertDetails = useSelector((state) => state?.expert?.expertInfo);

  const viewProfileAsOthers = () => {
    try {
      navigate(
        `/view-user?userid=${loggedInUser?.id}&usertype=${loggedInUser?.userTypeId}${companyDetails?.id ? `&cid=${companyDetails?.id}` : ''}`,
      );
    } catch (error) {}
  };
  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle,
  );
  const shouldGoToEditSurvey = useSelector(
    (state) => state?.survey?.completeSurvey,
  );
  const dispatch = useDispatch();
  const [showPasswowordReset, setShowPasswordReset] = useState(false);
  const [password, setPassword] = useState({});
  const {
    isOpen: isOpenEditSurvey,
    onToggle: onToggleEditSurvey,
    onOpen: onOpenEditSurvey,
    onClose: onCloseEditSurvey,
  } = useDisclosure();
  const {
    isOpen: isOpenEditExpert,
    onToggle: onToggleEditExpert,
    onOpen: onOpenEditExpert,
    onClose: onCloseEditExpert,
  } = useDisclosure();
  const [filledData, setFilledData] = useState({
    title: companyDetails?.title,
    job_title: companyDetails?.job_title,
    description: companyDetails?.description,
    pitch_deck: companyDetails?.pitch_deck,
    video_url: companyDetails?.video_url,
    businessCategoryId: companyDetails?.business_category_ids,
    businessModelId: companyDetails?.business_model_ids,
    fundCategoryId: companyDetails?.fundCategoryId,
    incorporationLocationId: companyDetails?.incorporation_location_ids,
    investmentlocationId: companyDetails?.investment_location_ids,
    companyStageId: companyDetails?.company_stage_ids,
    problem: companyDetails?.problem,
    solution: companyDetails?.solution,
    market_growth: companyDetails?.market_growth,
    revenue_model: companyDetails?.revenue_model,
    competition: companyDetails?.competition,
    fund_use: companyDetails?.fund_use,
    stakeholders: companyDetails?.stakeholders,
    customer_type: companyDetails?.customer_type,
  });
  const allCompaniesInfo = useSelector(
    (state) => state?.allcompanies?.allCompaniesInfo || [],
  );
  const [expertFilledData, setExpertFilledData] = useState({
    company_name: expertDetails?.company_name,
    headline: userDetails?.headline,
    bio: userDetails?.bio,
    phone_number: userDetails?.phone_number,
  });
  const { data: expertCategoriesData } = useData('experts/categories');
  const { data: expertLocations } = useData('experts/locations');
  const list = expertCategoriesData?.data?.categories || [];
  const countriesList = expertLocations?.data?.locations || [];
  const [selected, setSelected] = useState(
    expertDetails?.expert_category || null,
  );
  const [searchValue, setSearchValue] = useState(
    expertDetails?.expert_category?.title || '',
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    expertDetails?.location || null,
  );
  const [searchLocationValue, setSearchLocationValue] = useState(
    expertDetails?.location?.title || '',
  );
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [ref] = useClickOutside(() => {
    if (isOpen) setIsOpen(false);
  });
  const [images, setImages] = useState(null);
  const [imageSource, setImageSource] = useState([]);
  const imagesInputRef = useRef(null);
  const [imageIndex, setImageIndex] = useState();
  const [countrySelectionError, setCountrySelectionError] = useState(false);
  const maxBytes = 10485760;
  const maxSteps = 6;
  const [addCompanyData, setAddCompanyData] = useState({
    title: '',
    job_title: '',
    description: '',
    pitch_deck: '',
    video_url: '',
    businessCategoryId: null,
    businessModelId: null,
    fundCategoryId: null,
    incorporationLocationId: null,
    investmentlocationId: null,
    companyStageId: null,
    number_of_investors: 0,
    funding_target: 0,
    valuation: 0,
    problem: '',
    solution: '',
    market_growth: '',
    revenue_model: '',
    competition: '',
    fund_use: '',
    stakeholders: '',
    customer_type: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const [locationRef] = useClickOutside(() => {
    if (isLocationOpen) setIsLocationOpen(false);
  });

  const changeDPRef = useRef(null);
  const changeBannerRef = useRef(null);

  useEffect(() => {
    let index = allCompaniesInfo.findIndex(
      ({ id }) => id === companyDetails?.id,
    );
    index >= 0 && setCurrentCompanyIndex(`${index}`);
  }, [companyDetails?.title]);

  const searchCategoriesFilter = (value) => {
    return searchValue
      ? value?.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
          value?.title == 'Other'
      : true;
  };
  const searchLocationFilter = (value) => {
    return searchLocationValue
      ? value?.title
          ?.toLowerCase()
          .includes(searchLocationValue.toLowerCase()) ||
          value?.title == 'Other'
      : true;
  };
  const onChange = (e) => {
    setIsOpen(true);
    setSearchValue(e.target.value);
    setSelected(null);
  };

  const onChangeLocation = (e) => {
    setIsLocationOpen(true);
    setSearchLocationValue(e.target.value);
    setSelectedLocation(null);
    setCountrySelectionError(false);
  };

  const selectItem = (item) => {
    setSearchValue(item?.title);
    setSelected(item);
    toggleDropDown();
  };

  const selectLocation = (item) => {
    setSearchLocationValue(item?.title);
    setSelectedLocation(item);
    toggleLocationDropDown();
  };

  const toggleDropDown = () => setIsOpen((prev) => !prev);
  const toggleLocationDropDown = () => setIsLocationOpen((prev) => !prev);

  const openImageUpload = (e) => {
    imagesInputRef.current.click();
  };

  const handleChangeForImages = (e) => {
    let isTooLarge = [...e.target.files].some((file) => file.size > maxBytes);
    if (isTooLarge) {
      setTimeout(() => {
        addToast({
          message: 'One or more files are greater than 10MB',
          type: 'warning',
        });
      }, 500);
      return;
    }
    let remainingSlots = 8 - companyDetails?.additional_images?.length || 0;
    if (e.target.files.length <= remainingSlots) {
      const files = e.target.files;
      const imagesArray = [];
      setImages(e.target.files);
      Array.from(files).forEach((file) => {
        const reader = new FileReader();

        reader.onload = function (e) {
          imagesArray.push(e.target.result);
          if (imagesArray.length === files.length) {
            setImageSource(imagesArray);
          }
        };

        reader.readAsDataURL(file);
      });
    } else {
      setTimeout(() => {
        addToast({
          message: `More than ${remainingSlots} files selected`,
          type: 'warning',
        });
      }, 500);
    }
  };

  const uploadExtraImages = () => {
    if (isLoadingImageUpload) return;
    const attachments = [...images];
    let formData = new FormData();
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }
    mutateImageUpload(
      {
        key: `companies/addExtraImages/${companyDetails?.id}`,
        method: 'patch',
        data: formData,
      },
      {
        onSuccess(res) {
          addToast(res);
          dispatch(
            setCompanyInfo({
              ...companyDetails,
              additional_images: [...res.data?.additional_images],
            }),
          );
          UserService.setCompanyInfo({
            ...companyDetails,
            additional_images: [...res.data?.additional_images],
          });
          UserService.setAllCompaniesInfo(
            [...allCompaniesInfo].map((_company) =>
              _company.id === companyDetails?.id
                ? {
                    ...companyDetails,
                    additional_images: [...res.data?.additional_images],
                  }
                : _company,
            ),
          );
          dispatch(
            setAllCompaniesInfo(
              [...allCompaniesInfo].map((_company) =>
                _company.id === companyDetails?.id
                  ? {
                      ...companyDetails,
                      additional_images: [...res.data?.additional_images],
                    }
                  : _company,
              ),
            ),
          );
          setImages(null);
          setImageSource([]);
        },
        onError(res) {
          addToast(
            res?.response?.data || { message: 'Failed to upload images' },
            'warning',
          );
        },
      },
    );
  };

  const removeExtraImages = (index) => {
    if (isLoadingImageUpload) return;
    setImageIndex(index);
    const imageList = [...companyDetails.additional_images];
    imageList.splice(index, 1);

    mutateImageUpload(
      {
        key: `companies/manageExtraImages/${companyDetails?.id}`,
        method: 'post',
        data: { files: imageList },
      },
      {
        onSuccess(res) {
          addToast(res);
          dispatch(
            setCompanyInfo({
              ...companyDetails,
              additional_images: [...res.data?.additional_images],
            }),
          );
          UserService.setCompanyInfo({
            ...companyDetails,
            additional_images: [...res.data?.additional_images],
          });
          UserService.setAllCompaniesInfo(
            [...allCompaniesInfo].map((_company) =>
              _company.id === companyDetails?.id
                ? {
                    ...companyDetails,
                    additional_images: [...res.data?.additional_images],
                  }
                : _company,
            ),
          );
          dispatch(
            setAllCompaniesInfo(
              [...allCompaniesInfo].map((_company) =>
                _company.id === companyDetails?.id
                  ? {
                      ...companyDetails,
                      additional_images: [...res.data?.additional_images],
                    }
                  : _company,
              ),
            ),
          );
          setImageIndex(null);
        },
        onError(res) {
          addToast(
            res?.response?.data || { message: 'Failed to remove image' },
            'error',
          );
          setImageIndex(null);
        },
      },
    );
  };

  const editSurvey = () => {
    onOpenEditSurvey();
  };

  const editExpertDetails = () => {
    onOpenEditExpert();
  };

  const closeEditSurvey = () => {
    UserService.setCompleteSurveyAction(false);
    dispatch(setCompleteSurvey(false));
    onCloseEditSurvey();
  };

  const closeEditExpert = () => {
    onCloseEditExpert();
  };

  const onChangeCurrentCompanyIndex = (e) => {
    setCurrentCompanyIndex(e.target.value);
    dispatch(setCompanyInfo(allCompaniesInfo[Number(e.target.value)]));
    UserService.setCompanyInfo(allCompaniesInfo[Number(e.target.value)]);
  };

  const submitCompanydata = () => {
    const formData = new FormData();
    formData.append('title', filledData?.title);
    formData.append('description', filledData?.description);
    formData.append('video_url', filledData?.video_url);
    formData.append('pitch_deck', filledData?.pitch_deck);
    formData.append('fundCategoryId', filledData?.fundCategoryId || '');
    formData.append('problem', filledData?.problem);
    formData.append('solution', filledData?.solution);
    formData.append('market_growth', filledData?.market_growth);
    formData.append('revenue_model', filledData?.revenue_model);
    formData.append('competition', filledData?.competition);
    formData.append('fund_use', filledData?.fund_use);
    formData.append('stakeholders', filledData?.stakeholders);
    formData.append('customer_type', filledData?.customer_type);
    if (filledData?.incorporationLocationId?.length > 0) {
      filledData.incorporationLocationId.forEach((data) => {
        formData.append('incorporationLocationId[]', data);
      });
    } else {
      formData.append('incorporationLocationId', '');
    }
    if (accountType === 'founder') {
      if (
        typeof filledData?.companyStageId === 'number' ||
        filledData?.companyStageId?.length > 0
      ) {
        formData.append('companyStageId[]', filledData.companyStageId);
      } else {
        formData.append('companyStageId', '');
      }
      if (
        typeof filledData?.businessModelId === 'number' ||
        filledData?.businessModelId?.length > 0
      ) {
        formData.append('businessModelId[]', filledData.businessModelId);
      } else {
        formData.append('businessModelId', '');
      }
      if (
        typeof filledData.businessCategoryId === 'number' ||
        filledData?.businessCategoryId?.length > 0
      ) {
        formData.append('businessCategoryId[]', filledData.businessCategoryId);
      } else {
        formData.append('businessCategoryId', '');
      }
      formData.append('valuation', filledData?.valuation || 0);

      formData.append(
        'number_of_investors',
        filledData?.number_of_investors || 0,
      );

      formData.append('funding_target', filledData?.funding_target || 0);
    }
    if (accountType === 'investor') {
      formData.append('job_title', filledData?.job_title);
      if (filledData?.companyStageId?.length > 0) {
        filledData.companyStageId.forEach((data) => {
          formData.append('companyStageId[]', data);
        });
      } else {
        formData.append('companyStageId', '');
      }
      if (filledData?.businessModelId?.length > 0) {
        filledData.businessModelId.forEach((data) => {
          formData.append('businessModelId[]', data);
        });
      } else {
        formData.append('businessModelId', '');
      }
      if (filledData?.businessCategoryId?.length > 0) {
        filledData.businessCategoryId.forEach((data) => {
          formData.append('businessCategoryId[]', data);
        });
      } else {
        formData.append('businessCategoryId', '');
      }
      if (filledData?.investmentlocationId?.length > 0) {
        filledData.investmentlocationId.forEach((data) => {
          formData.append('investmentlocationId[]', data);
        });
      } else {
        formData.append('investmentlocationId', '');
      }
    }
    mutate(
      { key: `companies/${companyDetails?.id}`, method: 'put', data: formData },
      {
        onSuccess(res) {
          addToast(res);
          const updatedData = res?.data?.updatedData;
          UserService.setAllCompaniesInfo(
            [...allCompaniesInfo].map((_company) =>
              _company.id === companyDetails?.id
                ? { ...companyDetails, ...updatedData }
                : _company,
            ),
          );
          dispatch(
            setAllCompaniesInfo(
              [...allCompaniesInfo].map((_company) =>
                _company.id === companyDetails?.id
                  ? { ...companyDetails, ...updatedData }
                  : _company,
              ),
            ),
          );
          dispatch(setCompanyInfo({ ...companyDetails, ...updatedData }));
          UserService.setCompanyInfo({ ...companyDetails, ...updatedData });
          onToggleEditSurvey();
          topRef.current?.scrollIntoView({ block: 'nearest', inline: 'start' });
          if (accountType === 'founder') {
            setSearchParams('');
            setTimeout(() => {
              evaluateCompanyProfile(
                { ...companyDetails, ...updatedData },
                navigate,
              );
            }, 1000);
          }
        },
      },
    );
  };

  const submitExpertData = () => {
    const _data = {
      ...expertFilledData,
      category_id:
        selected?.id ||
        list.filter(({ title }) => title == 'Other')[0]?.id ||
        '',
    };

    if (selectedLocation?.id) _data['country_id'] = selectedLocation?.id;
    mutate(
      { key: `experts/${userDetails?.id}`, method: 'post', data: _data },
      {
        onSuccess(res) {
          addToast(res);
          const _expert = res?.data?.expert || {};
          const _user = res?.data?.user || {};
          dispatch(setUserInfo({ ...userDetails, ..._user }));
          dispatch(setExpertInfo({ ...expertDetails, ..._expert }));
          UserService.setUserInfo({ ...userDetails, ..._user });
          UserService.setExpertInfo({ ...expertDetails, ..._expert });
          setSearchValue(_expert?.expert_category?.title || '');
          setSearchLocationValue(_expert?.location?.title || '');
          onToggleEditExpert();
        },
        onError(res) {
          addToast(
            {
              message:
                res?.response?.data?.message ||
                res?.message ||
                'Failed to update',
            },
            'error',
          );
        },
      },
    );
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    mutate(
      {
        key: `users/${userDetails?.id}/update-password`,
        method: 'put',
        data: password,
      },
      {
        onSuccess(res) {
          addToast(res);
          setShowPasswordReset(false);
        },
      },
    );
  };

  const handlePasswordChange = (e) => {
    setPassword((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    let edit = location?.search?.slice(6) || null;
    if (edit && location?.search?.includes('?edit=')) {
      accountType === 'expert' ? onOpenEditExpert() : onOpenEditSurvey();
      setTimeout(() => {
        if (refs[`${edit}Ref`]?.current) {
          refs[`${edit}Ref`]?.current?.scrollIntoView({
            block: 'nearest',
            inline: 'start',
          });
          addToast(
            toastAlerts[`${edit}`],
            edit === 'pitchDeck' || edit === 'videoUrl' ? 'error' : 'info',
          );
        }
      }, 2000);
    }

    if (edit && location?.search?.includes('?tab=')) {
      edit = location?.search?.slice(5);
      if (edit == 'slots') setTabIndex(0);
    }
  }, [location.search, surveyData?.fundCategories]);

  const handleNotification = (e, type) => {
    e.preventDefault();

    const value = e.target.checked;
    if (type === 'email_notification') {
      setEmailNotf(value);
    } else if (type === 'push_notification') {
      setPushNotf(value);
    } else if (type === 'promotional_newsletter') {
      setPromoNotf(value);
    }

    const data = {
      type,
      value: value,
    };
    mutate(
      {
        key: `users/user-update-notf-settings`,
        method: 'post',
        data,
      },
      {
        onSuccess(res) {
          addToast(res);
          setShowPasswordReset(false);
        },
      },
    );
  };

  const handleSystemPreferences = (e, type) => {
    e.preventDefault();

    const value = e.target.checked;
    if (type === 'inboxNotification') {
      setInboxNotification(value);
    } else if (type === 'friendRequestNotification') {
      setFriendRequestNotification(value);
    }

    const data = {
      [type]: value,
    };
    mutate(
      {
        key: `users/system-preferences`,
        method: 'put',
        data,
      },
      {
        onSuccess(res) {
          addToast('Preferences updated');
        },
      },
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: `${userDetailsData?.first_name} ${userDetailsData?.last_name}`,
      email: userDetailsData?.email,
      imageUrl: userDetailsData?.profile_image,
    },
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (userDetailsData) {
      reset({
        name: `${userDetailsData.first_name} ${userDetailsData.last_name}`,
        email: userDetailsData.email,
        imageUrl: userDetailsData.profile_image,
      });
    }
  }, [userDetailsData, reset]);

  const handleChangeForFile = (e, type) => {
    e.stopPropagation();
    if (!e?.target?.files.length) return;
    const fileData = e.target.files[0];
    if (type == 'profile') {
      setProfilePicture(fileData);
    }
    const formData = new FormData();
    formData.append('attachment', fileData);
    uploadPhoto(formData, type);
  };

  const uploadPhoto = (data, type) => {
    mutateUpload(
      { key: `users/${userDetails?.id}/${type}-image`, method: 'put', data },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
          if (type === 'profile') {
            dispatch(
              setUserInfo({
                ...userDetails,
                profile_image: res.data.profile_image,
              }),
            );
            UserService.setUserInfo({
              ...userDetails,
              profile_image: res.data.profile_image,
            });
          }
        },
      },
    );
  };

  const formSubmit = (data) => {
    console.log('profile update data', data);
    mutate(
      {
        key: `users/${loggedInUser.id}/profile`,
        method: 'put',
        data,
      },
      {
        onSuccess(res) {
          addToast(res);
          queryClient.invalidateQueries(`users/${userDetails.id}`);
        },
        onError(res) {
          addToast(res?.response?.data?.message || 'Error occured', 'error');
        },
      },
    );
  };

  useEffect(() => {
    switch (typeof profilePicture) {
      case 'string':
        // setProfilePicture(profilePicture);
        break;
      case 'object':
        setProfilePicture(
          window.URL.createObjectURL(
            new Blob([profilePicture], { type: 'image' }),
          ),
        );
        break;
      default:
        setProfilePicture('');
    }
  }, [profilePicture]);

  const handleChange = (e) => {
    setFilledData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAddCompanyDataChange = (e) => {
    setAddCompanyData((prev) => {
      const _obj = {
        ...prev,
        [e.target.name]: e.target.value,
      };
      localStorage.setItem('companyData', JSON.stringify(_obj));
      return _obj;
    });
  };

  const handleExpertChange = (e) => {
    setExpertFilledData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileInput = (e) => {
    setFilledData((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
  };

  const handleAddCompanyFileInput = (e) => {
    setAddCompanyData((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
  };

  useEffect(() => {
    if (shouldGoToEditSurvey === true) {
      onOpenEditSurvey();
    }
  }, [shouldGoToEditSurvey, onOpenEditSurvey]);

  const openPDF = () => {
    if (companyDetails?.pitch_deck) {
      navigate('pdf-viewer');
    }
  };

  const openVideo = () => {
    if (companyDetails?.video_url) {
      navigate('video-viewer');
    }
  };

  useEffect(() => {
    let prevAddCompanyData = localStorage.getItem('companyData');
    if (prevAddCompanyData && prevAddCompanyData != 'undefined') {
      setAddCompanyData(JSON.parse(prevAddCompanyData));
    }
  }, []);

  const deletePhoto = () => {
    let data = {};
    mutate(
      { key: `users/user-delete-photo`, method: 'put', data },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
        },
      },
    );
  };

  const formSubmitResetPassword = () => {
    let data = {
      email: userDetails?.email,
    };

    setLoading(true);
    mutate(
      { key: 'forgot-password', method: 'post', data },
      {
        onSuccess(res) {
          setLoading(false);

          // showCheckEmail();
          // onToggleCheckEmail();
          addToast({ message: res.message, type: 'info' });
        },
      },
    );
  };

  const addExtraCompany = () => {
    try {
      if (!addCompanyData.title.trim()) {
        addToast({ message: 'Please fill in company title', type: 'info' });
        return;
      }

      const formData = new FormData();
      formData.append('title', addCompanyData?.title);
      formData.append('description', addCompanyData?.description);
      formData.append('video_url', addCompanyData?.video_url);
      formData.append('pitch_deck', addCompanyData?.pitch_deck);
      formData.append('fundCategoryId', addCompanyData?.fundCategoryId || '');
      formData.append('problem', addCompanyData?.problem);
      formData.append('solution', addCompanyData?.solution);
      formData.append('market_growth', addCompanyData?.market_growth);
      formData.append('revenue_model', addCompanyData?.revenue_model);
      formData.append('competition', addCompanyData?.competition);
      formData.append('fund_use', addCompanyData?.fund_use);
      formData.append('stakeholders', addCompanyData?.stakeholders);
      formData.append('customer_type', addCompanyData?.customer_type);
      if (addCompanyData?.incorporationLocationId?.length > 0) {
        addCompanyData?.incorporationLocationId.forEach((data) => {
          formData.append('incorporationLocationId[]', data);
        });
      } else {
        formData.append('incorporationLocationId', '');
      }
      if (accountType === 'founder') {
        if (
          typeof addCompanyData?.companyStageId === 'number' ||
          addCompanyData?.companyStageId?.length > 0
        ) {
          formData.append('companyStageId[]', addCompanyData?.companyStageId);
        } else {
          formData.append('companyStageId', '');
        }
        if (
          typeof addCompanyData?.businessModelId === 'number' ||
          addCompanyData?.businessModelId?.length > 0
        ) {
          formData.append('businessModelId[]', addCompanyData?.businessModelId);
        } else {
          formData.append('businessModelId', '');
        }
        if (
          typeof addCompanyData?.businessCategoryId === 'number' ||
          addCompanyData?.businessCategoryId?.length > 0
        ) {
          formData.append(
            'businessCategoryId[]',
            addCompanyData?.businessCategoryId,
          );
        } else {
          formData.append('businessCategoryId', '');
        }
      }
      if (accountType === 'investor') {
        formData.append('job_title', addCompanyData?.job_title);
        if (addCompanyData?.companyStageId?.length > 0) {
          addCompanyData?.companyStageId.forEach((data) => {
            formData.append('companyStageId[]', data);
          });
        } else {
          formData.append('companyStageId', '');
        }
        if (addCompanyData?.businessModelId?.length > 0) {
          addCompanyData?.businessModelId.forEach((data) => {
            formData.append('businessModelId[]', data);
          });
        } else {
          formData.append('businessModelId', '');
        }
        if (addCompanyData?.businessCategoryId?.length > 0) {
          addCompanyData?.businessCategoryId.forEach((data) => {
            formData.append('businessCategoryId[]', data);
          });
        } else {
          formData.append('businessCategoryId', '');
        }
        if (addCompanyData?.investmentlocationId?.length > 0) {
          addCompanyData?.investmentlocationId.forEach((data) => {
            formData.append('investmentlocationId[]', data);
          });
        } else {
          formData.append('investmentlocationId', '');
        }
      }
      mutateAddExtraCompany(
        { key: `companies/addExtraCompany`, method: 'post', data: formData },
        {
          onSuccess(res) {
            addToast({ message: res.message, type: 'info' });
            onCloseAddCompanyModal();
            setAddCompanyData({
              title: '',
              job_title: '',
              description: '',
              pitch_deck: '',
              video_url: '',
              businessCategoryId: null,
              businessModelId: null,
              fundCategoryId: null,
              incorporationLocationId: null,
              investmentlocationId: null,
              companyStageId: null,
              problem: null,
              solution: null,
              market_growth: null,
              revenue_model: null,
              competition: null,
              fund_use: null,
              stakeholders: null,
              customer_type: null,
            });
            localStorage.removeItem('companyData');

            const companyData = res?.data?.company;
            if (!companyData) return;
            UserService.setAllCompaniesInfo([
              ...allCompaniesInfo,
              { ...companyData },
            ]);
            dispatch(
              setAllCompaniesInfo([...allCompaniesInfo, { ...companyData }]),
            );
            dispatch(setCompanyInfo({ ...companyData }));
            UserService.setCompanyInfo({ ...companyData });
          },
        },
      );
    } catch (e) {
      //console.log(e)
    }
  };

  return (
    <Box>
      {isLoadingUserDetails && !isUserDetailsError && <Loader />}
      <DeleteAccountModal
        isOpen={deleteAccountModalIsOpen}
        onClose={closeDeleteAccountModal}
      />
      <CustomerSupportModal
        isOpen={submitComplaintModalIsOpen}
        onClose={onCloseSubmitComplaintModal}
      />
      <InviteUserModal
        isOpen={shareInviteModalIsOpen}
        onClose={onCloseShareInviteModal}
      />
      <Header title="Account Settings" />
      <Flex
        className={`flex-col ${
          isDesktop ? 'max-h-[95vh] w-full fixed pb-10 overflow-y-scroll' : ''
        }`}
      >
        <div ref={topRef}></div>
        <Stack
          className={`${isDesktop ? 'w-[80vw]' : ''}`}
          maxW={'1200px'}
          direction={!isDesktop ? 'column' : 'row'}
          spacing="24px"
          p={8}
        >
          <Box
            w={isDesktop ? '50%' : '100%'}
            boxShadow="0px 0px 8px 4px rgba(19, 74, 112, 0.1)"
            className="rounded-t-lg"
          >
            <VStack spacing={5} align="left">
              <div
                style={{
                  marginBottom: '60px',
                  position: 'relative',
                  width: '100%',
                }}
                className={`h-[10rem] ${data?.data?.user?.cover_image ? `bg-gradient-to-r from-[rgba(0, 0, 0, 0.5)] to-[rgba(0, 0, 0, 0.5)]` : 'bg-[#466fc2]'} bg-center bg-no-repeat bg-cover`}
              >
                {data?.data?.user?.cover_image ? (
                  <div
                    style={{ filter: 'brightness(0.5)' }}
                    className="border rounded-t-lg"
                  >
                    <Image
                      src={data?.data?.user?.cover_image}
                      className="max-h-[10rem] rounded-t-lg w-full"
                    />
                  </div>
                ) : null}
                <Text
                  onClick={(e) => {
                    e.stopPropagation();
                    changeBannerRef.current?.click();
                  }}
                  className="text-white px-3 py-5 cursor-pointer top-10 text-center w-full pr-5 font-medium absolute"
                >
                  Select to change banner image
                </Text>
                <Input
                  accept="image/*"
                  id="image-input-2"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={(e) => handleChangeForFile(e, 'cover')}
                  name="avatar"
                  key="avatar"
                  ref={changeBannerRef}
                />
                <Box
                  className="pl-6 flex w-full justify-between items-center flex-wrap"
                  position={'absolute'}
                  bottom={'-60px'}
                >
                  <HStack>
                    <div style={{ position: 'relative' }}>
                      <Avatar
                        className="border-2 border-white"
                        size="xl"
                        name={`${userDetails?.firstName} ${userDetails?.lastName}`}
                        src={data?.data?.user?.profile_image}
                        mr={2}
                      />

                      <div
                        style={{
                          position: 'absolute',
                          top: '2px',
                          right: '2px',
                          background: '#ffffff',
                          cursor: 'pointer',
                          borderRadius: '100px',
                        }}
                        className="p-1.5"
                        onClick={(e) => {
                          e.stopPropagation();
                          changeDPRef.current?.click();
                        }}
                      >
                        <Image
                          h="16px"
                          w="16px"
                          src={EditIcon}
                          alt="dashboard-icon"
                        />
                      </div>
                    </div>
                    <Input
                      ref={changeDPRef}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="image-input"
                      type="file"
                      onChange={(e) => handleChangeForFile(e, 'profile')}
                      name="avatar"
                      key="avatar"
                    />
                    {accountType === 'founder' ? (
                      <Flex className="flex-row gap-1 items-center">
                        <StarIcon
                          h={3.5}
                          w={3.5}
                          color={
                            userDetails?.rating > 0 ? 'yellow.400' : 'initial'
                          }
                        />
                        <StarIcon
                          h={3.5}
                          w={3.5}
                          color={
                            userDetails?.rating > 1 ? 'yellow.400' : 'initial'
                          }
                        />
                        <StarIcon
                          h={3.5}
                          w={3.5}
                          color={
                            userDetails?.rating > 2 ? 'yellow.400' : 'initial'
                          }
                        />
                      </Flex>
                    ) : null}
                  </HStack>
                  {accountType === 'founder' ? (
                    <Box className="mt-4 pt-4 px-3">
                      <Button
                        _hover={{ background: 'brand.primary' }}
                        className="text-white text-sm"
                        bgColor={'brand.primary'}
                        onClick={() => {
                          onOpenAddCompanyModal();
                        }}
                      >
                        <AddIcon />
                        &nbsp;&nbsp;Add Company
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              </div>

              <form
                className="px-7 py-4"
                noValidate
                onSubmit={handleSubmit(formSubmit)}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  className="mb-9"
                >
                  <Box w="100%" className="flex flex-col gap-6">
                    <Box className="flex flex-col gap-3 w-full">
                      <Text className="text-[#484848] font-medium text-base">
                        Your name
                      </Text>
                      <Input
                        {...register('name')}
                        placeholder="Hafis Raji"
                        w={'full'}
                        borderColor="#D5DBE2"
                        borderRadius={'16px'}
                        px={'1rem'}
                        py={'24px'}
                        className="p-2"
                        _placeholder={{ color: 'brand.primary' }}
                      />
                      <Text color="#9FA7AD" fontWeight={400} fontSize={12}>
                        You can't change your name now if you changed it within
                        the last 14 days.
                      </Text>
                    </Box>
                    <Box className="flex flex-col gap-3 w-full">
                      <Text className="text-[#484848] font-medium text-base">
                        Email Address
                      </Text>
                      <Input
                        {...register('email')}
                        disabled
                        placeholder="yourname@example.com"
                        w={'full'}
                        borderColor="#D5DBE2"
                        borderRadius={'16px'}
                        px={'1rem'}
                        py={'24px'}
                        className="p-2"
                        _placeholder={{ color: 'brand.primary' }}
                      />
                      <Text color="#9FA7AD" fontWeight={400} fontSize={12}>
                        Enter an email you check regularly. Any alerts you
                        activate will be sent there.
                      </Text>
                    </Box>
                    <Box className="flex flex-col gap-3 w-full">
                      <Text color="#000" fontWeight={400}>
                        Current password
                      </Text>
                      <Box w={'full'}>
                        <InputGroup>
                          <Input
                            {...register('password')}
                            placeholder="Your current password"
                            w="full"
                            borderColor="#D5DBE2"
                            borderRadius={'16px'}
                            px={'1rem'}
                            py={'24px'}
                            type={showPassword ? 'text' : 'password'}
                            _placeholder={{ color: '#9FA7AD' }}
                          />
                          <InputRightElement
                            py={'24px'}
                            // pointerEvents="none"
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPassword(!showPassword);
                            }}
                            children={
                              <Icon
                                as={AiOutlineEyeInvisible}
                                color="#9FA7AD"
                              />
                            }
                          />
                        </InputGroup>

                        {errors?.password?.message && (
                          <span className="text-sm text-red-400">
                            {errors?.password?.message}
                          </span>
                        )}
                      </Box>
                      <Text color="#9FA7AD" fontWeight={400} fontSize={12}>
                        To make any changes, you must enter your current
                        password.
                      </Text>
                    </Box>
                  </Box>
                </Flex>
                <Box className="mb-9">
                  <Text
                    color="brand.primary"
                    className="mb-4"
                    fontSize={'1.2rem'}
                    fontWeight={500}
                  >
                    Notifications
                  </Text>
                  <Flex className="flex-col gap-6 w-full">
                    <Flex className="flex-col gap-3 w-full">
                      <Text color="#979797" fontWeight={500} fontSize={'1rem'}>
                        How would you like to recieve your notifications on Seed
                        A Founder
                      </Text>
                      <Wrap className="flex flex-col">
                        <WrapItem>
                          <Checkbox
                            value={pushNotf}
                            isChecked={pushNotf}
                            fontSize={14}
                            color="brand.primary"
                            borderColor="brand.primary"
                            onChange={(e) =>
                              handleNotification(e, 'push_notification')
                            }
                          >
                            Push notifications
                          </Checkbox>
                        </WrapItem>
                        <WrapItem>
                          <Checkbox
                            value={emailNotf}
                            isChecked={emailNotf}
                            fontSize={14}
                            color="brand.primary"
                            borderColor="brand.primary"
                            onChange={(e) =>
                              handleNotification(e, 'email_notification')
                            }
                          >
                            Email notifications
                          </Checkbox>
                        </WrapItem>
                      </Wrap>
                    </Flex>

                    <Flex className="flex-col gap-3 w-full">
                      <Text color="#979797" fontWeight={500} fontSize={'1rem'}>
                        Never miss any activity on Seed A Founder
                      </Text>
                      <Wrap>
                        <WrapItem>
                          <Checkbox
                            value={inboxNotification}
                            isChecked={inboxNotification}
                            fontSize={14}
                            color="brand.primary"
                            borderColor="brand.primary"
                            onChange={(e) =>
                              handleSystemPreferences(e, 'inboxNotification')
                            }
                          >
                            Inbox Messages
                          </Checkbox>
                        </WrapItem>
                        <WrapItem>
                          <Checkbox
                            value={friendRequestNotification}
                            isChecked={friendRequestNotification}
                            fontSize={14}
                            color="brand.primary"
                            borderColor="brand.primary"
                            onChange={(e) =>
                              handleSystemPreferences(
                                e,
                                'friendRequestNotification',
                              )
                            }
                          >
                            Friend Requests
                          </Checkbox>
                        </WrapItem>
                        <WrapItem>
                          <Checkbox
                            value={promoNotf}
                            isChecked={promoNotf}
                            fontSize={14}
                            color="brand.primary"
                            borderColor="brand.primary"
                            onChange={(e) =>
                              handleNotification(e, 'promotional_newsletter')
                            }
                          >
                            Receive Promotional emails and newsletter
                          </Checkbox>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                  </Flex>
                </Box>
                <Flex className="mt-3 flex-col gap-3 w-full items-center">
                  <Button
                    bgColor="brand.primary"
                    color="#fff"
                    fontSize="14px"
                    borderRadius={4}
                    px={4}
                    type="submit"
                    w={'full'}
                    // onClick={handleSubmit(formSubmit)}
                    _hover={{ background: 'brand.primary' }}
                  >
                    {isLoading ? 'Updating...' : 'Save changes'}
                  </Button>
                  <Box
                    onClick={formSubmitResetPassword}
                    cursor="pointer"
                    w={'full'}
                    minH="30px"
                    borderColor="brand.primary"
                    borderWidth={1}
                    borderRadius="5px"
                  >
                    <Flex justifyContent="space-between" w={'full'} p={2}>
                      <Text
                        className="text-center text-sm w-full"
                        color="brand.primary"
                        fontWeight={500}
                      >
                        Reset my password
                      </Text>
                      {loading && <Spinner />}

                      {/* {!showPasswowordReset ? <AiOutlineRight /> : <AiOutlineUp />} */}
                    </Flex>
                  </Box>
                </Flex>
              </form>

              <Collapse in={showPasswowordReset}>
                <form onSubmit={handlePasswordReset}>
                  <InputGroup mb={3}>
                    <Flex
                      borderWidth="1px"
                      borderColor="brand.primary"
                      borderRadius="5px"
                      alignItems="center"
                    >
                      <InputLeftAddon
                        borderWidth="0px"
                        bg=""
                        children={<Image src={lock} size={30} />}
                      />
                      <Input
                        focusBorderColor="#"
                        borderWidth="0px"
                        variant="outline"
                        name="currentPassword"
                        onChange={(e) => handlePasswordChange(e)}
                        placeholder="Your current password"
                        type="password"
                        w="250px"
                        h="50px"
                      />
                    </Flex>
                  </InputGroup>
                  <InputGroup mb={3}>
                    <Flex
                      borderWidth="1px"
                      borderColor="brand.primary"
                      borderRadius="5px"
                      alignItems="center"
                    >
                      <InputLeftAddon
                        borderWidth="0px"
                        bg=""
                        children={<Image src={lock} size={30} />}
                      />
                      <Input
                        focusBorderColor="#"
                        borderWidth="0px"
                        variant="outline"
                        name="newPassword"
                        onChange={(e) => handlePasswordChange(e)}
                        placeholder="New password, at Least 6 characters"
                        type="password"
                        w="250px"
                        h="50px"
                      />
                    </Flex>
                  </InputGroup>
                  <InputGroup mb={3}>
                    <Flex
                      borderWidth="1px"
                      borderColor="brand.primary"
                      borderRadius="5px"
                      alignItems="center"
                    >
                      <InputLeftAddon
                        borderWidth="0px"
                        bg=""
                        children={<Image src={lock} size={30} />}
                      />
                      <Input
                        focusBorderColor="#"
                        borderWidth="0px"
                        variant="outline"
                        name="confirmNewPassword"
                        onChange={(e) => handlePasswordChange(e)}
                        placeholder="Confirm New password"
                        type="password"
                        w="250px"
                        h="50px"
                      />
                    </Flex>
                  </InputGroup>
                  <BrandButton
                    colorScheme="blue"
                    w="180px"
                    text="Reset Password"
                    isLoading={isLoading}
                    type="submit"
                  />
                </form>
              </Collapse>
            </VStack>
            {/* <Box  className="px-7 py-4">
              <Text color="brand.primary" fontSize={"1.2rem"} fontWeight={500}>
                Contact Support
              </Text>
              <Text color="#000" fontWeight={400} fontSize={14}>
                Click to submit your complaint, report a bug, or contact support.
              </Text>
              <Button
                mt={4}
                color="#fff"
                variant={"brand.primary"}
                _hover={{ background: "brand.primary" }} 
                className="text-white text-sm" 
                bgColor={"brand.primary"}
                borderRadius={4}
                fontSize={14}
                onClick={() => {
                  onOpenSubmitComplaintModal();
                }}
              >
                Contact Support
              </Button>
            </Box> */}
            <Box className="px-7 mt-9 mb-9 flex flex-col gap-3">
              <Text color="brand.primary" fontSize={'1.2rem'} fontWeight={500}>
                Invite A Friend
              </Text>
              <Text color="#000" fontWeight={400} fontSize={14}>
                Enjoying Seed A Founder? Invite a friend!
              </Text>
              <Button
                color="brand.primary"
                bgColor="#fff"
                borderRadius={4}
                fontSize={14}
                border="1px solid #134A70"
                _hover={{ background: 'inherit' }}
                onClick={() => {
                  onOpenShareInviteModal();
                }}
              >
                Invite a Friend
              </Button>
            </Box>
            <Box className="px-7 mt-9 mb-9 flex flex-col gap-3">
              <Text color="brand.primary" fontSize={'1.2rem'} fontWeight={500}>
                Delete Account
              </Text>
              <Text color="#000" fontWeight={400} fontSize={14}>
                This will completely remove your account from Seed A Founder
              </Text>
              <Button
                color="brand.primary"
                bgColor="#fff"
                borderRadius={4}
                fontSize={14}
                border="1px solid #134A70"
                _hover={{ background: 'inherit' }}
                onClick={() => {
                  onOpenDeleteAccountModal();
                }}
              >
                Delete Account
              </Button>
            </Box>
          </Box>

          {/* Right column */}
          {accountType === 'founder' || accountType === 'investor' ? (
            <>
              <Box
                w={!isDesktop ? '100%' : '50%'}
                boxShadow="0px 0px 8px 4px rgba(19, 74, 112, 0.1)"
                borderRadius="10px"
                className="p-2"
              >
                {accountType === 'founder' ? (
                  <Tabs
                    isFitted
                    className="mb-4"
                    index={tabIndex}
                    onChange={handleTabsChange}
                  >
                    <TabList>
                      <Tab>Slots</Tab>
                      <Tab>Profile</Tab>
                    </TabList>
                  </Tabs>
                ) : null}
                {tabIndex === 1 ? (
                  <>
                    {!isOpenEditSurvey && !addCompanyModalIsOpen ? (
                      <>
                        <Tooltip label="See how your profile appears to other users">
                          <Button
                            leftIcon={<Icon as={FaUserAlt} />}
                            borderRadius={4}
                            color="brand.primary"
                            bgColor="#fff"
                            fontSize="14px"
                            px={4}
                            border="1px solid"
                            borderColor="brand.primary"
                            type="submit"
                            w={'full'}
                            className="mt-4"
                            _hover={{ background: 'rgba(0, 0, 0, 0.05s)' }}
                            onClick={viewProfileAsOthers}
                          >
                            View Profile
                          </Button>
                        </Tooltip>
                        <Button
                          leftIcon={<Icon as={FiEdit2} />}
                          borderRadius={4}
                          color="#fff"
                          bgColor="brand.primary"
                          fontSize="14px"
                          px={4}
                          type="submit"
                          w={'full'}
                          className="my-4"
                          _hover={{ background: 'brand.primary' }}
                          onClick={editSurvey}
                        >
                          Edit Profile
                        </Button>
                        <Box className="border flex flex-col rounded-lg p-4">
                          <Flex
                            className={`${accountType === 'founder' ? 'justify-between' : 'justify-start'} gap-1 items-center `}
                          >
                            <Box className={`flex md:hidden flex-col gap-1`}>
                              <Select
                                className="w-[220px]"
                                value={currentCompanyIndex}
                                onChange={onChangeCurrentCompanyIndex}
                                placeholder={''}
                              >
                                {allCompaniesInfo.map((_company, index) => (
                                  <option key={index} value={index}>
                                    {_company.title}
                                  </option>
                                ))}
                              </Select>
                            </Box>
                            <Text className="text-lg text-[#2F4B6E] font-medium mb-4">
                              Business Overview
                            </Text>
                          </Flex>

                          <Box>
                            <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                              <Text
                                className="md:w-[50%] xl:w-[240px]"
                                fontWeight={500}
                                color="#C0BCBC"
                              >
                                {isDesktop ? `Company Name` : `Name`}:
                              </Text>
                              <Box className="md:w-[50%] xl:w-full">
                                <Text color="#000">
                                  {companyDetails?.title}
                                </Text>
                              </Box>
                            </Flex>
                            {accountType === 'investor' ? (
                              <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                                <Text
                                  className="md:w-[50%] xl:w-[240px]"
                                  fontWeight={500}
                                  color="#C0BCBC"
                                >
                                  Job Title:
                                </Text>
                                <Box className="md:w-[50%] xl:w-full">
                                  <Text color="#000">
                                    {companyDetails?.job_title}
                                  </Text>
                                </Box>
                              </Flex>
                            ) : null}
                            <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                              <Text
                                fontWeight={500}
                                color="#C0BCBC"
                                className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                              >
                                {isDesktop ? `Company Stage` : `Stage`}:
                              </Text>
                              <Box className="md:w-[50%] xl:w-full">
                                {companyDetails?.company_stage?.map(
                                  ({ title }, index) => (
                                    <Text key={index}>
                                      {title}
                                      <br />
                                    </Text>
                                  ),
                                )}
                              </Box>
                            </Flex>
                            <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                              <Text
                                fontWeight={500}
                                color="#C0BCBC"
                                className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                              >
                                Kind of fund:
                              </Text>
                              <Box className="md:w-[50%] xl:w-full">
                                <Text color="#000">
                                  {companyDetails?.fund_category?.title}
                                </Text>
                              </Box>
                            </Flex>
                            {accountType === 'investor' ? (
                              <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                                <Text
                                  fontWeight={500}
                                  color="#C0BCBC"
                                  className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                                >
                                  Investment locations:
                                </Text>
                                <Box className="md:w-[50%] xl:w-full">
                                  {companyDetails?.investment_location?.map(
                                    ({ name }) => (
                                      <Text>{name}</Text>
                                    ),
                                  )}
                                </Box>
                              </Flex>
                            ) : null}
                            <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                              <Text
                                fontWeight={500}
                                color="#C0BCBC"
                                className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                              >
                                {isDesktop
                                  ? `Location of company`
                                  : `Locations`}
                                :
                              </Text>
                              <Box className="md:w-[50%] xl:w-full">
                                {companyDetails?.incorporation_location?.map(
                                  ({ name }, index) => (
                                    <Text key={index}>{name}</Text>
                                  ),
                                )}
                              </Box>
                            </Flex>
                            <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                              <Text
                                className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                                fontWeight={500}
                                color="#C0BCBC"
                              >
                                Business model:
                              </Text>
                              <Box className="md:w-[50%] xl:w-full">
                                {companyDetails?.business_model?.map(
                                  ({ title }, index) => (
                                    <Text key={index}>{title}</Text>
                                  ),
                                )}
                              </Box>
                            </Flex>
                            <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                              <Text
                                fontWeight={500}
                                color="#C0BCBC"
                                className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                              >
                                Category:
                              </Text>
                              <Box className="md:w-[50%] xl:w-full">
                                {companyDetails?.business_category?.map(
                                  ({ title }, index) => (
                                    <Text key={index}>{title}</Text>
                                  ),
                                )}
                              </Box>
                            </Flex>
                            {accountType === 'founder' ? (
                              <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                                <Text
                                  fontWeight={500}
                                  color="#C0BCBC"
                                  className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                                >
                                  Valuation:
                                </Text>
                                <Box className="md:w-[50%] xl:w-full">
                                  <Text>{companyDetails?.valuation}</Text>
                                </Box>
                              </Flex>
                            ) : null}
                            {accountType === 'founder' ? (
                              <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                                <Text
                                  fontWeight={500}
                                  color="#C0BCBC"
                                  className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                                >
                                  Funding Target:
                                </Text>
                                <Box className="md:w-[50%] xl:w-full">
                                  <Text>{companyDetails?.funding_target}</Text>
                                </Box>
                              </Flex>
                            ) : null}
                            {accountType === 'founder' ? (
                              <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                                <Text
                                  fontWeight={500}
                                  color="#C0BCBC"
                                  className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                                >
                                  Number of Investors:
                                </Text>
                                <Box className="md:w-[50%] xl:w-full">
                                  <Text>
                                    {companyDetails?.number_of_investors}
                                  </Text>
                                </Box>
                              </Flex>
                            ) : null}
                            {accountType === 'founder' && (
                              <HStack
                                spacing={12}
                                h={{ base: '4vh', '2xl': '6.7vh' }}
                                className="mb-4"
                              >
                                <Text fontWeight={500} color="#9FA7AD">
                                  File(s)
                                </Text>
                                <HStack spacing={6}>
                                  {/* <a href={companyDetails?.pitch_deck} target="_blank" rel="noreferrer"> */}
                                  <Image
                                    src={ppt}
                                    boxSize={isDesktop ? '30px' : '25px'}
                                    cursor="pointer"
                                    onClick={() => openPDF()}
                                  />
                                  {/* </a> */}
                                  <Image
                                    src={mp4}
                                    boxSize={isDesktop ? '30px' : '25px'}
                                    cursor="pointer"
                                    onClick={() => openVideo()}
                                  />
                                </HStack>
                              </HStack>
                            )}
                          </Box>

                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                            <Text
                              color="#C0BCBC"
                              fontWeight={500}
                              className="no-wrap w-max md:w-[50%] xl:w-[240px]"
                            >
                              Description:
                            </Text>
                            <Box className="md:w-[50%] xl:w-full">
                              <Text color="#000">
                                {companyDetails?.description}
                              </Text>
                            </Box>
                          </Flex>

                          {accountType === 'founder' ? (
                            <Box className="mt-8">
                              <Text
                                color="brand.primary"
                                className="text-md font-bold"
                              >
                                Additional Images
                              </Text>
                              <Text className="my-2">
                                {`Image slots left: ${8 - (companyDetails?.additional_images?.length || 0) - (imageSource?.length || 0)}`}
                              </Text>
                              <>
                                {images?.length > 0 ? (
                                  <Box className="flex my-5 flex-col gap-5">
                                    <Box className="flex items-center flex-wrap gap-3">
                                      {imageSource.map((image, index) => (
                                        <Image
                                          key={index}
                                          src={image}
                                          className={
                                            'h-[165px] w-[165px] object-contain'
                                          }
                                        />
                                      ))}
                                      <Input
                                        accept="image/*"
                                        id="image-attachments"
                                        type="file"
                                        onChange={(e) =>
                                          handleChangeForImages(e)
                                        }
                                        name="attachments"
                                        key="image-attachments"
                                        hidden
                                        multiple
                                        ref={imagesInputRef}
                                      />
                                      {images ? null : (
                                        <Box
                                          onClick={openImageUpload}
                                          className="p-3 bg-gray-300 cursor-pointer"
                                        >
                                          <AddIcon fontSize={'xl'} />
                                        </Box>
                                      )}

                                      <Button
                                        bgColor="brand.primary"
                                        color="#fff"
                                        borderRadius={4}
                                        px={4}
                                        _hover={{ background: 'brand.primary' }}
                                        onClick={uploadExtraImages}
                                        className="w-full mx-auto"
                                      >
                                        {isLoadingImageUpload ? (
                                          <Flex>
                                            <Spinner />
                                          </Flex>
                                        ) : (
                                          `Upload ${imageSource?.length > 1 ? 'images' : 'image'}`
                                        )}
                                      </Button>

                                      <Button
                                        bgColor="red.500"
                                        color="#fff"
                                        borderRadius={4}
                                        px={4}
                                        _hover={{ background: 'red.500' }}
                                        onClick={() => {
                                          setImages(null);
                                          setImageSource([]);
                                        }}
                                        className="w-full mx-auto"
                                      >
                                        Clear selection
                                      </Button>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box className="flex flex-wrap items-center justify-center md:justify-start my-5 gap-3">
                                    {companyDetails?.additional_images?.length >
                                    0 ? (
                                      companyDetails?.additional_images?.map(
                                        (image, index) => {
                                          return (
                                            <Box
                                              key={index}
                                              className="relative"
                                            >
                                              <Text
                                                onClick={() =>
                                                  removeExtraImages(index)
                                                }
                                                className={`text-sm absolute rounded-full font-medium right-[-3px] top-[-5px] bg-red-100 text-gray-900 p-0.5 px-2 cursor-pointer`}
                                              >
                                                x
                                              </Text>
                                              {index == imageIndex ? (
                                                <Flex className="absolute w-full justify-center top-[40%]">
                                                  <Spinner />
                                                </Flex>
                                              ) : null}
                                              <Image
                                                src={image}
                                                className={
                                                  'h-[165px] object-contain w-[165px]'
                                                }
                                              />
                                            </Box>
                                          );
                                        },
                                      )
                                    ) : (
                                      <Text className="flex items-center p-3 text-gray-500 font-semibold">
                                        Click the button to add image
                                      </Text>
                                    )}
                                    <Input
                                      accept="image/*"
                                      id="image-attachments"
                                      type="file"
                                      onChange={(e) => handleChangeForImages(e)}
                                      name="attachments"
                                      key="image-attachments"
                                      hidden
                                      multiple
                                      ref={imagesInputRef}
                                    />
                                    <Box
                                      onClick={openImageUpload}
                                      className="p-3 h-[80px] w-[80px] flex flex-col items-center justify-center rounded bg-gray-200 cursor-pointer"
                                    >
                                      <AddIcon fontSize={'xl'} />
                                    </Box>
                                  </Box>
                                )}
                              </>
                            </Box>
                          ) : null}
                        </Box>
                      </>
                    ) : addCompanyModalIsOpen ? (
                      <Flex className="flex-col">
                        <Box className="p-4">
                          <Heading fontSize="24px" color="brand.primary" mb={4}>
                            Add Company
                          </Heading>
                          <Box className={`flex flex-col gap-2 mb-3`}>
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              className="mb-2"
                            >
                              Enter your company’s name?
                              {!addCompanyData?.title && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Input
                              key="title"
                              type="text"
                              placeholder="Type here...."
                              name="title"
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              h="50px"
                              defaultValue={addCompanyData.title}
                              className="mb-3.5"
                              onChange={(e) => handleAddCompanyDataChange(e)}
                            />
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              className="mb-2"
                            >
                              Describe your company in few words{' '}
                              {!addCompanyData?.description && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              key="description"
                              placeholder="Type here...."
                              type="text"
                              name="description"
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              minH="150px"
                              className="mb-3.5"
                              defaultValue={addCompanyData.description}
                              onChange={(e) => handleAddCompanyDataChange(e)}
                            />
                          </Box>
                          <Box>
                            <Page
                              question="What is your start-up stage?"
                              options={surveyData?.companyStages || []}
                              data={{ companyStageId: undefined }}
                              setFilledData={setAddCompanyData}
                              filledData={addCompanyData}
                              responseId={addCompanyData?.companyStageId}
                              pageContainerClasses={
                                'w-full items-start justify-start place-self-start justify-self-start '
                              }
                              isOnboardingView={false}
                            />
                          </Box>
                          <Box>
                            <Page
                              question="What type of funding does your start-up require?"
                              options={surveyData?.fundCategories || []}
                              data={{ fundCategoryId: undefined }}
                              setFilledData={setAddCompanyData}
                              filledData={addCompanyData}
                              responseId={addCompanyData?.fundCategoryId}
                              pageContainerClasses={'w-full items-start'}
                              isOnboardingView={false}
                            />
                          </Box>
                          <Box>
                            <Page
                              question="Where is your start-up incorporated?"
                              isCheckBox={true}
                              options={surveyData?.locations || []}
                              data={{ incorporationLocationId: undefined }}
                              setFilledData={setAddCompanyData}
                              filledData={addCompanyData}
                              responseId={
                                addCompanyData?.incorporationLocationId
                              }
                              pageContainerClasses={'w-full items-start'}
                              isOnboardingView={false}
                            />
                          </Box>
                          <Box>
                            <Page
                              question="Which business model best describes your start-up?"
                              options={surveyData?.businessModels || []}
                              data={{ businessModelId: undefined }}
                              setFilledData={setAddCompanyData}
                              filledData={addCompanyData}
                              responseId={addCompanyData?.businessModelId}
                              pageContainerClasses={'w-full items-start'}
                              isOnboardingView={false}
                            />
                          </Box>
                          <Box>
                            <Page
                              question="Which category best describes your start-up?"
                              options={surveyData?.categories || []}
                              data={{ businessCategoryId: undefined }}
                              setFilledData={setAddCompanyData}
                              filledData={addCompanyData}
                              responseId={addCompanyData?.businessCategoryId}
                              pageContainerClasses={'w-full items-start'}
                              isOnboardingView={false}
                            />
                          </Box>
                          <Box className="flex flex-col w-full gap-2.5">
                            <Text fontWeight={500} fontSize="16px" color="#000">
                              Current Valuation{' '}
                              {!addCompanyData?.valuation && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Input
                              key="valuation"
                              type="number"
                              placeholder="Type here...."
                              defaultValue={Number(
                                addCompanyData?.valuation || 0,
                              )}
                              name="valuation"
                              borderRadius="10px"
                              borderWidth={2}
                              borderColor={
                                !addCompanyData?.valuation
                                  ? 'red.500'
                                  : 'brand.primary'
                              }
                              h="50px"
                              onChange={(e) => handleAddCompanyDataChange(e)}
                              mb={2}
                            />
                          </Box>
                          <Box className="flex flex-col w-full gap-2.5">
                            <Text fontWeight={500} fontSize="16px" color="#000">
                              Funding Target{' '}
                              {!addCompanyData?.funding_target && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Input
                              key="funding_target"
                              type="number"
                              placeholder="Type here...."
                              name="funding_target"
                              borderRadius="10px"
                              borderWidth={2}
                              defaultValue={Number(
                                addCompanyData?.fundCategoryId || 0,
                              )}
                              borderColor={
                                !addCompanyData?.funding_target
                                  ? 'red.500'
                                  : 'brand.primary'
                              }
                              h="50px"
                              onChange={(e) => handleAddCompanyDataChange(e)}
                              mb={2}
                            />
                          </Box>
                          <Box className="flex flex-col w-full gap-2.5">
                            <Text fontWeight={500} fontSize="16px" color="#000">
                              Current Number of Investors on Cap Table{' '}
                              {!addCompanyData?.number_of_investors && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Input
                              key="number_of_investors"
                              type="number"
                              placeholder="Type here...."
                              defaultValue={Number(
                                addCompanyData?.number_of_investors || 0,
                              )}
                              name="number_of_investors"
                              borderRadius="10px"
                              borderWidth={2}
                              borderColor={
                                !addCompanyData?.number_of_investors
                                  ? 'red.500'
                                  : 'brand.primary'
                              }
                              h="50px"
                              onChange={(e) => handleAddCompanyDataChange(e)}
                              mb={2}
                            />
                          </Box>
                          <Box>
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              ref={pitchDeckRef}
                            >
                              Upload pitch-deck{' '}
                              {!addCompanyData?.pitch_deck && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Input
                              hidden
                              id="file-input-btn"
                              key="pitch_deck"
                              accept="application/pdf"
                              onChange={handleAddCompanyFileInput}
                              type="file"
                              placeholder="Type here...."
                              name="pitch_deck"
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              h="50px"
                            />
                            <Flex
                              alignItems="center"
                              justifyContent="flex-start"
                              w="100%"
                            >
                              <label h="100%" htmlFor="file-input-btn">
                                <Flex
                                  w="100px"
                                  cursor="pointer"
                                  borderRadius="5px"
                                  _active={{ backgroundColor: 'lightgray' }}
                                  justifyContent="center"
                                  alignItems="center"
                                  h="50px"
                                  bg="brand.primary"
                                  fontWeight="600"
                                  fontSize="16px"
                                  className="mb-4 mt-3"
                                >
                                  <Text color="white" fontSize="14px">
                                    Browse File
                                  </Text>
                                </Flex>
                              </label>
                              <Box w="70%">
                                <Text ml={2}>
                                  {addCompanyData?.pitch_deck?.name || ''}
                                </Text>
                              </Box>
                            </Flex>
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              ref={pitchDeckRef}
                              className="mt-4 mb-3"
                            >
                              Add Video url{' '}
                              {!addCompanyData?.video_url && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Input
                              key="video-url"
                              type="text"
                              placeholder="Type here...."
                              className="mb-4"
                              defaultValue={addCompanyData?.video_url}
                              name="video_url"
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              h="50px"
                              onChange={(e) => handleAddCompanyDataChange(e)}
                            />
                          </Box>
                          <Flex className="gap-2 flex-col my-3">
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              className="mb-1"
                            >
                              Problem{' '}
                              {!addCompanyData?.problem && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              name="problem"
                              value={addCompanyData?.problem}
                              onChange={handleAddCompanyDataChange}
                              maxLength={300}
                              rows={4}
                              borderRadius="10px"
                              className="mb-4"
                              borderWidth={1}
                              borderColor="brand.primary"
                              placeholder="Describe the specific problem your startup aims to solve or the market gap it addresses"
                            />
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              ref={problemRef}
                              className="mb-1"
                            >
                              Solution{' '}
                              {!addCompanyData?.solution && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              name="solution"
                              value={addCompanyData?.solution}
                              onChange={handleAddCompanyDataChange}
                              maxLength={300}
                              rows={4}
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              placeholder="Outline how your idea solves the problem"
                            />
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              ref={solutionRef}
                              className="mb-1"
                            >
                              Market Growth{' '}
                              {!addCompanyData?.market_growth && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              name="market_growth"
                              value={addCompanyData?.market_growth}
                              onChange={handleAddCompanyDataChange}
                              maxLength={300}
                              rows={4}
                              borderRadius="10px"
                              className="mb-4"
                              borderWidth={1}
                              borderColor="brand.primary"
                              placeholder={`Insights into the target market's growth potential`}
                            />
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              ref={marketGrowthRef}
                              className="mb-1"
                            >
                              Business Model{' '}
                              {!addCompanyData?.revenue_model && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              name="revenue_model"
                              value={addCompanyData?.revenue_model}
                              onChange={handleAddCompanyDataChange}
                              maxLength={300}
                              rows={4}
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              className="mb-4"
                              placeholder={`outline your startup's revenue generation strategy or business model`}
                            />
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              ref={revenueModelRef}
                              className="mb-1"
                            >
                              Competition{' '}
                              {!addCompanyData?.competition && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              name="competition"
                              value={addCompanyData?.competition}
                              onChange={handleAddCompanyDataChange}
                              maxLength={300}
                              rows={4}
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              className="mb-4"
                              placeholder={`List your competitors and how the startup differentiates from them`}
                            />
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              ref={competitionRef}
                              className="mb-1"
                            >
                              Use of funds{' '}
                              {!addCompanyData?.fund_use && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              name="fund_use"
                              value={addCompanyData?.fund_use}
                              onChange={handleAddCompanyDataChange}
                              maxLength={300}
                              rows={4}
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              className="mb-4"
                              placeholder={`Specify how you plan to allocate funds to various aspects of your startup`}
                            />
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              ccolor="#000"
                              ref={fundsUseRef}
                              className="mb-1"
                            >
                              Current Investors{' '}
                              {!addCompanyData?.stakeholders && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              name="stakeholders"
                              value={addCompanyData?.stakeholders}
                              onChange={handleAddCompanyDataChange}
                              maxLength={300}
                              rows={4}
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              className="mb-4"
                              placeholder={`If applicable, provide information about current investors or stakeholders`}
                            />
                            <Text
                              fontWeight={500}
                              fontSize="16px"
                              color="#000"
                              ref={stakeholdersRef}
                              className="mb-1"
                            >
                              Customers{' '}
                              {!addCompanyData?.customer_type && (
                                <Text className="text-red-600 text-lg inline font-bold">
                                  *
                                </Text>
                              )}
                            </Text>
                            <Textarea
                              name="customer_type"
                              value={addCompanyData?.customer_type}
                              onChange={handleAddCompanyDataChange}
                              maxLength={300}
                              rows={4}
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor="brand.primary"
                              className="mb-4"
                              placeholder={`Details about the target customer base are essential`}
                            />
                          </Flex>
                          <Box
                            className="mt-7 flex flex-col md:flex-row gap-3 items-center justify-between"
                            w="full"
                          >
                            <BrandButton
                              text="Submit"
                              width="full"
                              colorScheme="blue"
                              isLoading={isLoadingAddExtraCompany}
                              onClick={addExtraCompany}
                            />
                            <BrandButton
                              text="Cancel"
                              width="full"
                              onClick={onCloseAddCompanyModal}
                            />
                          </Box>
                        </Box>
                      </Flex>
                    ) : (
                      <Box>
                        {/* <Heading fontSize="24px" color="brand.primary" mb={4}>
                      Edit {companyDetails?.title} Data
                    </Heading> */}
                        <Text className="text-lg text-[#2F4B6E] px-4 mt-4 font-medium mb-4">
                          Business Overview
                        </Text>
                        <VStack
                          flexDir="column"
                          w={isDesktop ? '100%' : '100%'}
                          justifyContent="flex-start"
                          alignItems={isDesktop ? 'flex-start' : 'flex-start'}
                          className="gap-2 px-4 py-6"
                        >
                          <Text fontWeight={500} fontSize="16px" color="#000">
                            Enter your company’s name?
                            {!filledData?.title && (
                              <Text className="text-red-600 text-lg inline font-bold">
                                *
                              </Text>
                            )}
                          </Text>
                          <Input
                            key="title"
                            type="text"
                            placeholder="Type here...."
                            defaultValue={companyDetails?.title}
                            name="title"
                            borderRadius="10px"
                            borderWidth={1}
                            borderColor={
                              !filledData?.title ? 'red.500' : 'brand.primary'
                            }
                            h="50px"
                            onChange={(e) => handleChange(e)}
                          />
                          <Box className="w-full" />

                          {accountType === 'investor' ? (
                            <>
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                              >
                                Enter your job title
                                {!filledData?.job_title && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Input
                                key="title"
                                type="text"
                                placeholder="Type here...."
                                defaultValue={companyDetails?.job_title}
                                name="job_title"
                                borderRadius="10px"
                                borderWidth={2}
                                borderColor={
                                  !filledData?.job_title
                                    ? 'red.500'
                                    : 'brand.primary'
                                }
                                h="50px"
                                onChange={(e) => handleChange(e)}
                                mb={2}
                              />
                              <Box className="w-full" />
                            </>
                          ) : null}

                          <Text fontWeight={500} fontSize="16px" color="#000">
                            Describe your company in few words{' '}
                            {!filledData?.description && (
                              <Text className="text-red-600 text-lg inline font-bold">
                                *
                              </Text>
                            )}
                          </Text>
                          <Textarea
                            key="description"
                            placeholder="Type here...."
                            defaultValue={companyDetails?.description}
                            type="text"
                            name="description"
                            borderRadius="10px"
                            borderWidth={1}
                            borderColor={
                              !filledData?.description
                                ? 'red.500'
                                : 'brand.primary'
                            }
                            minH="150px"
                            onChange={(e) => handleChange(e)}
                            mb={2}
                          />
                          <Box className="w-full" />

                          {accountType === 'founder' ? (
                            <>
                              <Box className="flex flex-col">
                                <Flex
                                  className="flex-col"
                                  ref={companyStageRef}
                                >
                                  <Page
                                    question="What is your start-up stage?"
                                    options={surveyData?.companyStages || []}
                                    data={{ companyStageId: undefined }}
                                    setFilledData={setFilledData}
                                    filledData={filledData}
                                    responseId={
                                      companyDetails?.company_stage_ids
                                        ?.length > 0
                                        ? companyDetails?.company_stage_ids[0]
                                        : ''
                                    }
                                    pageContainerClasses={
                                      'w-full items-start justify-start place-self-start justify-self-start '
                                    }
                                    isOnboardingView={false}
                                  />
                                </Flex>
                                <Flex
                                  className="flex-col"
                                  ref={fundCategoryRef}
                                >
                                  <Page
                                    question="What type of funding does your start-up require?"
                                    options={surveyData?.fundCategories || []}
                                    data={{ fundCategoryId: undefined }}
                                    setFilledData={setFilledData}
                                    filledData={filledData}
                                    responseId={
                                      companyDetails?.fundCategoryId || ''
                                    }
                                    pageContainerClasses={'w-full items-start'}
                                    isOnboardingView={false}
                                  />
                                </Flex>
                                <Flex
                                  className="flex-col"
                                  ref={incorporationLocationRef}
                                >
                                  <Page
                                    question="Where is your start-up incorporated?"
                                    isCheckBox={true}
                                    options={surveyData?.locations || []}
                                    data={{
                                      incorporationLocationId: undefined,
                                    }}
                                    setFilledData={setFilledData}
                                    filledData={filledData}
                                    responseId={companyDetails?.incorporation_location_ids.map(
                                      (item) => `${item}`,
                                    )}
                                    pageContainerClasses={'w-full items-start'}
                                    isOnboardingView={false}
                                  />
                                </Flex>
                                <Flex
                                  className="flex-col"
                                  ref={businessModelRef}
                                >
                                  <Page
                                    question="Which business model best describes your start-up?"
                                    options={surveyData?.businessModels || []}
                                    data={{ businessModelId: undefined }}
                                    setFilledData={setFilledData}
                                    filledData={filledData}
                                    responseId={
                                      companyDetails?.business_model_ids
                                        ?.length > 0
                                        ? companyDetails?.business_model_ids[0]
                                        : ''
                                    }
                                    pageContainerClasses={'w-full items-start'}
                                    isOnboardingView={false}
                                  />
                                </Flex>
                                <Flex
                                  className="flex-col"
                                  ref={businessCategoryRef}
                                >
                                  <Page
                                    question="Which category best describes your start-up?"
                                    options={surveyData?.categories || []}
                                    data={{ businessCategoryId: undefined }}
                                    setFilledData={setFilledData}
                                    filledData={filledData}
                                    responseId={
                                      companyDetails?.business_category_ids
                                        ?.length > 0
                                        ? companyDetails
                                            ?.business_category_ids[0]
                                        : ''
                                    }
                                    pageContainerClasses={'w-full items-start'}
                                    isOnboardingView={false}
                                  />
                                </Flex>
                              </Box>
                              <Box className="flex flex-col w-full gap-2.5">
                                <Text
                                  fontWeight={500}
                                  fontSize="16px"
                                  color="#000"
                                  // ref={pitchDeckRef}
                                >
                                  Current Valuation{' '}
                                  {!filledData?.valuation && (
                                    <Text className="text-red-600 text-lg inline font-bold">
                                      *
                                    </Text>
                                  )}
                                </Text>
                                <Input
                                  key="valuation"
                                  type="number"
                                  placeholder="Type here...."
                                  defaultValue={companyDetails?.valuation}
                                  name="valuation"
                                  borderRadius="10px"
                                  borderWidth={2}
                                  borderColor={
                                    !filledData?.valuation
                                      ? 'red.500'
                                      : 'brand.primary'
                                  }
                                  h="50px"
                                  onChange={(e) => handleChange(e)}
                                  mb={2}
                                />
                              </Box>
                              <Box className="flex flex-col w-full gap-2.5">
                                <Text
                                  fontWeight={500}
                                  fontSize="16px"
                                  color="#000"
                                  // ref={pitchDeckRef}
                                >
                                  Funding Target{' '}
                                  {!filledData?.funding_target && (
                                    <Text className="text-red-600 text-lg inline font-bold">
                                      *
                                    </Text>
                                  )}
                                </Text>
                                <Input
                                  key="funding_target"
                                  type="number"
                                  placeholder="Type here...."
                                  defaultValue={companyDetails?.funding_target}
                                  name="funding_target"
                                  borderRadius="10px"
                                  borderWidth={2}
                                  borderColor={
                                    !filledData?.funding_target
                                      ? 'red.500'
                                      : 'brand.primary'
                                  }
                                  h="50px"
                                  onChange={(e) => handleChange(e)}
                                  mb={2}
                                />
                              </Box>
                              <Box className="flex flex-col w-full gap-2.5">
                                <Text
                                  fontWeight={500}
                                  fontSize="16px"
                                  color="#000"
                                  // ref={pitchDeckRef}
                                >
                                  Current Number of Investors on Cap Table{' '}
                                  {!filledData?.number_of_investors && (
                                    <Text className="text-red-600 text-lg inline font-bold">
                                      *
                                    </Text>
                                  )}
                                </Text>
                                <Input
                                  key="number_of_investors"
                                  type="number"
                                  placeholder="Type here...."
                                  defaultValue={
                                    companyDetails?.number_of_investors
                                  }
                                  name="number_of_investors"
                                  borderRadius="10px"
                                  borderWidth={2}
                                  borderColor={
                                    !filledData?.number_of_investors
                                      ? 'red.500'
                                      : 'brand.primary'
                                  }
                                  h="50px"
                                  onChange={(e) => handleChange(e)}
                                  mb={2}
                                />
                              </Box>
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={pitchDeckRef}
                              >
                                Upload pitch-deck{' '}
                                {!filledData?.pitch_deck && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Input
                                hidden
                                id="file-input-btn"
                                key="pitch_deck"
                                accept="application/pdf"
                                onChange={handleFileInput}
                                type="file"
                                placeholder="Type here...."
                                name="pitch_deck"
                                borderRadius="10px"
                                borderWidth={1}
                                borderColor="brand.primary"
                                h="50px"
                              />
                              <Flex
                                alignItems="center"
                                justifyContent="flex-start"
                                w="100%"
                              >
                                <label h="100%" htmlFor="file-input-btn">
                                  <Flex
                                    w="100px"
                                    cursor="pointer"
                                    borderRadius="5px"
                                    _active={{ backgroundColor: 'lightgray' }}
                                    justifyContent="center"
                                    alignItems="center"
                                    h="50px"
                                    bg="brand.primary"
                                    fontWeight="600"
                                    fontSize="16px"
                                  >
                                    <Text color="white" fontSize="14px">
                                      Browse File
                                    </Text>
                                  </Flex>
                                </label>
                                <Box w="70%">
                                  <Text ml={2}>
                                    {
                                      (
                                        filledData?.pitch_deck?.name ||
                                        companyDetails?.pitch_deck ||
                                        ''
                                      )
                                        .split('/')
                                        .reverse()[0]
                                    }
                                  </Text>
                                </Box>
                              </Flex>
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={pitchDeckRef}
                              >
                                Add Video url{' '}
                                {!filledData?.video_url && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Input
                                key="video-url"
                                type="text"
                                placeholder="Type here...."
                                defaultValue={companyDetails?.video_url}
                                name="video_url"
                                borderRadius="10px"
                                borderWidth={1}
                                borderColor="brand.primary"
                                h="50px"
                                onChange={(e) => handleChange(e)}
                              />
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={videoUrlRef}
                              >
                                Problem{' '}
                                {!filledData?.problem && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Textarea
                                name="problem"
                                defaultValue={companyDetails?.problem}
                                value={filledData?.problem}
                                onChange={handleChange}
                                maxLength={300}
                                rows={4}
                                borderRadius="10px"
                                borderWidth={1}
                                isInvalid
                                className={`border ${!filledData?.problem ? 'border-red-500' : ''} `}
                                borderColor={'brand.primary'}
                                placeholder="Describe the specific problem your startup aims to solve or the market gap it addresses"
                              />
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={problemRef}
                              >
                                Solution{' '}
                                {!filledData?.solution && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Textarea
                                name="solution"
                                defaultValue={companyDetails?.solution}
                                value={filledData?.solution}
                                onChange={handleChange}
                                maxLength={300}
                                rows={4}
                                borderRadius="10px"
                                borderWidth={1}
                                className={`border ${!filledData?.solution ? 'border-red-500' : ''} `}
                                borderColor="brand.primary"
                                placeholder="Outline how your idea solves the problem"
                              />
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={solutionRef}
                              >
                                Market Growth{' '}
                                {!filledData?.market_growth && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Textarea
                                name="market_growth"
                                defaultValue={companyDetails?.market_growth}
                                value={filledData?.market_growth}
                                onChange={handleChange}
                                maxLength={300}
                                rows={4}
                                borderRadius="10px"
                                borderWidth={1}
                                className={`border ${!filledData?.market_growth ? 'border-red-500' : ''} `}
                                borderColor="brand.primary"
                                placeholder={`Insights into the target market's growth potential`}
                              />
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="brand.primary"
                                ref={marketGrowthRef}
                              >
                                Business Model{' '}
                                {!filledData?.revenue_model && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Textarea
                                name="revenue_model"
                                defaultValue={companyDetails?.revenue_model}
                                value={filledData?.revenue_model}
                                onChange={handleChange}
                                maxLength={300}
                                rows={4}
                                borderRadius="10px"
                                borderWidth={1}
                                className={`border ${!filledData?.revenue_model ? 'border-red-500' : ''} `}
                                borderColor="brand.primary"
                                placeholder={`outline your startup's revenue generation strategy or business model`}
                              />
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={revenueModelRef}
                              >
                                Competition{' '}
                                {!filledData?.competition && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Textarea
                                name="competition"
                                defaultValue={companyDetails?.competition}
                                value={filledData?.competition}
                                onChange={handleChange}
                                maxLength={300}
                                rows={4}
                                borderRadius="10px"
                                borderWidth={1}
                                className={`border ${!filledData?.competition ? 'border-red-500' : ''} `}
                                borderColor="brand.primary"
                                placeholder={`List your competitors and how the startup differentiates from them`}
                              />
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={competitionRef}
                              >
                                Use of funds{' '}
                                {!filledData?.fund_use && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Textarea
                                name="fund_use"
                                defaultValue={companyDetails?.fund_use}
                                value={filledData?.fund_use}
                                onChange={handleChange}
                                maxLength={300}
                                rows={4}
                                borderRadius="10px"
                                borderWidth={1}
                                className={`border ${!filledData?.fund_use ? 'border-red-500' : ''} `}
                                borderColor="brand.primary"
                                placeholder={`Specify how you plan to allocate funds to various aspects of your startup`}
                              />
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={fundsUseRef}
                              >
                                Current Investors{' '}
                                {!filledData?.stakeholders && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Textarea
                                name="stakeholders"
                                defaultValue={companyDetails?.stakeholders}
                                value={filledData?.stakeholders}
                                onChange={handleChange}
                                maxLength={300}
                                rows={4}
                                borderRadius="10px"
                                borderWidth={1}
                                className={`border ${!filledData?.stakeholders ? 'border-red-500' : ''} `}
                                borderColor="brand.primary"
                                placeholder={`If applicable, provide information about current investors or stakeholders`}
                              />
                              <Text
                                fontWeight={500}
                                fontSize="16px"
                                color="#000"
                                ref={stakeholdersRef}
                              >
                                Customers{' '}
                                {!filledData?.customer_type && (
                                  <Text className="text-red-600 text-lg inline font-bold">
                                    *
                                  </Text>
                                )}
                              </Text>
                              <Textarea
                                name="customer_type"
                                defaultValue={companyDetails?.customer_type}
                                value={filledData?.customer_type}
                                onChange={handleChange}
                                maxLength={300}
                                rows={4}
                                borderRadius="10px"
                                borderWidth={1}
                                className={`border ${!filledData?.customer_type ? 'border-red-500' : ''} `}
                                borderColor="brand.primary"
                                placeholder={`Details about the target customer base are essential`}
                              />
                              <div ref={customerTypeRef}></div>
                            </>
                          ) : null}
                          {accountType === 'investor' ? (
                            <>
                              <Box>
                                <Page
                                  question="What company stage are you interested in?"
                                  options={surveyData?.companyStages}
                                  data={{ companyStageId: undefined }}
                                  setFilledData={setFilledData}
                                  filledData={filledData}
                                  isCheckBox={true}
                                  pageContainerClasses={'w-full items-start'}
                                  isOnboardingView={false}
                                  responseId={companyDetails?.company_stage_ids.map(
                                    (item) => `${item}`,
                                  )}
                                />
                                <Page
                                  question="How much money are you interested in investing?"
                                  options={surveyData?.fundCategories}
                                  data={{ fundCategoryId: undefined }}
                                  setFilledData={setFilledData}
                                  filledData={filledData}
                                  responseId={companyDetails?.fundCategoryId}
                                  pageContainerClasses={'w-full items-start'}
                                  isOnboardingView={false}
                                />

                                <Page
                                  question="Where would you like to invest in?"
                                  isCheckBox={true}
                                  options={surveyData?.locations}
                                  data={{ investmentlocationId: undefined }}
                                  setFilledData={setFilledData}
                                  filledData={filledData}
                                  responseId={companyDetails?.investment_location_ids?.map(
                                    (item) => `${item}`,
                                  )}
                                  pageContainerClasses={'w-full items-start'}
                                  isOnboardingView={false}
                                />
                                <Page
                                  question="Where is your company incorporated?"
                                  isCheckBox={true}
                                  options={surveyData?.locations}
                                  data={{ incorporationLocationId: undefined }}
                                  setFilledData={setFilledData}
                                  filledData={filledData}
                                  responseId={companyDetails?.incorporation_location_ids.map(
                                    (item) => `${item}`,
                                  )}
                                  pageContainerClasses={'w-full items-start'}
                                  isOnboardingView={false}
                                />
                                <Page
                                  question="Which business models match your investment interest?"
                                  isCheckBox={true}
                                  options={surveyData?.businessModels}
                                  data={{ businessModelId: undefined }}
                                  setFilledData={setFilledData}
                                  filledData={filledData}
                                  pageContainerClasses={'w-full items-start'}
                                  isOnboardingView={false}
                                  responseId={companyDetails?.business_model_ids?.map(
                                    (item) => `${item}`,
                                  )}
                                />
                                <Page
                                  question="Which business category would you like to invest in?"
                                  options={surveyData?.categories}
                                  isCheckBox={true}
                                  data={{ businessCategoryId: undefined }}
                                  setFilledData={setFilledData}
                                  filledData={filledData}
                                  pageContainerClasses={'w-full items-start'}
                                  isOnboardingView={false}
                                  responseId={companyDetails?.business_category_ids?.map(
                                    (item) => `${item}`,
                                  )}
                                />
                              </Box>
                            </>
                          ) : null}
                          <Box className="flex flex-col lg:flex-row gap-3 w-full space-between items-center">
                            <BrandButton
                              text="Submit"
                              width="full"
                              colorScheme="blue"
                              isLoading={isLoading}
                              onClick={submitCompanydata}
                            />
                            <BrandButton
                              text="Cancel"
                              width="full"
                              onClick={closeEditSurvey}
                            />
                          </Box>
                        </VStack>
                      </Box>
                    )}
                  </>
                ) : (
                  <MySlots />
                )}
              </Box>
            </>
          ) : (
            <Box
              w={!isDesktop ? '100%' : '50%'}
              boxShadow="0px 0px 8px 4px rgba(19, 74, 112, 0.1)"
              borderRadius="10px"
              p={4}
            >
              <Tabs
                isFitted
                className="mb-4"
                index={tabIndex}
                onChange={handleTabsChange}
              >
                <TabList>
                  <Tab>Slots</Tab>
                  <Tab>Edit Profile</Tab>
                </TabList>
              </Tabs>
              {tabIndex === 0 ? <MySlots /> : null}
              {tabIndex === 1 ? (
                <Flex className="flex-col">
                  {!isOpenEditExpert ? (
                    <Box>
                      <Text color="brand.primary" fontWeight={500}>
                        Expert details
                      </Text>
                      <Box fontSize={{ base: 12, '2xl': 16 }} mt="5%">
                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                          <Text fontWeight={500} color="#9FA7AD">
                            {isDesktop ? `Company Name` : `Name`}:
                          </Text>
                          <Text color="#000" align="right">
                            {expertDetails?.company_name}
                          </Text>
                        </Flex>
                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                          <Text fontWeight={500} color="#9FA7AD">
                            Bio:
                          </Text>
                          <Text color="#000" align="right">
                            {userDetails?.bio}
                          </Text>
                        </Flex>
                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                          <Text fontWeight={500} color="#9FA7AD">
                            Phone Number:
                          </Text>
                          <Text color="#000" align="right">
                            {userDetails?.phone_number}
                          </Text>
                        </Flex>
                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                          <Text fontWeight={500} color="#9FA7AD">
                            Position:
                          </Text>
                          <Text color="#000" align="right">
                            {userDetails?.headline}
                          </Text>
                        </Flex>
                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                          <Text fontWeight={500} color="#9FA7AD">
                            Category:
                          </Text>
                          <Text color="#000" align="right">
                            {expertDetails?.expert_category?.title}
                          </Text>
                        </Flex>
                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                          <Text fontWeight={500} color="#9FA7AD">
                            Location:
                          </Text>
                          <Text color="#000" align="right">
                            {expertDetails?.location?.title}
                          </Text>
                        </Flex>
                        <Tooltip label="See how your profile appears to other users">
                          <Button
                            leftIcon={<Icon as={FaUserAlt} />}
                            borderRadius={4}
                            color="brand.primary"
                            bgColor="#fff"
                            fontSize="14px"
                            px={4}
                            border="1px solid"
                            borderColor="brand.primary"
                            type="submit"
                            w={'full'}
                            className="mt-4"
                            _hover={{ background: 'rgba(0, 0, 0, 0.05s)' }}
                            onClick={viewProfileAsOthers}
                          >
                            View Profile
                          </Button>
                        </Tooltip>
                        <Button
                          leftIcon={<Icon as={FiEdit2} />}
                          borderRadius={4}
                          color="#fff"
                          bgColor="brand.primary"
                          fontSize="14px"
                          px={4}
                          mt={6}
                          type="submit"
                          w={'full'}
                          className="my-4"
                          _hover={{ background: 'brand.primary' }}
                          onClick={editExpertDetails}
                        >
                          Edit Profile
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Heading fontSize="18px" color="brand.primary" mb={4}>
                        Edit Expert data
                      </Heading>
                      <Flex className="flex-col gap-2 my-3">
                        <Text
                          fontWeight={500}
                          fontSize="16px"
                          color="brand.primary"
                          ref={expertCompanyRef}
                        >
                          Enter your company’s name?
                          {!expertFilledData?.company_name && (
                            <Text className="text-red-600 text-lg inline font-bold">
                              *
                            </Text>
                          )}
                        </Text>
                        <Input
                          key="company_name"
                          type="text"
                          placeholder="Type here...."
                          defaultValue={expertDetails?.company_name}
                          name="company_name"
                          borderRadius="10px"
                          borderWidth={1}
                          borderColor={
                            !expertFilledData?.company_name
                              ? 'red.500'
                              : 'brand.primary'
                          }
                          h="50px"
                          onChange={(e) => handleExpertChange(e)}
                        />
                      </Flex>
                      <Flex ref={expertBioRef} className="flex-col gap-2 my-3">
                        <Text
                          fontWeight={500}
                          fontSize="16px"
                          color="brand.primary"
                        >
                          Describe yourself in a few words
                          {!expertFilledData?.bio && (
                            <Text className="text-red-600 text-lg inline font-bold">
                              *
                            </Text>
                          )}
                        </Text>
                        <Textarea
                          key="bio"
                          placeholder="Type here...."
                          defaultValue={userDetails?.bio}
                          type="text"
                          name="bio"
                          borderRadius="10px"
                          borderWidth={1}
                          borderColor={
                            !expertFilledData?.bio ? 'red.500' : 'brand.primary'
                          }
                          minH="150px"
                          onChange={(e) => handleExpertChange(e)}
                        />
                      </Flex>
                      <Flex
                        ref={expertPhoneRef}
                        className="flex-col gap-2 my-3"
                      >
                        <Text
                          fontWeight={500}
                          fontSize="16px"
                          color="brand.primary"
                        >
                          Enter your phone number?
                          {!expertFilledData?.phone_number && (
                            <Text className="text-red-600 text-lg inline font-bold">
                              *
                            </Text>
                          )}
                        </Text>
                        <Input
                          key="phone_number"
                          type="number"
                          placeholder="Type here...."
                          defaultValue={userDetails?.phone_number}
                          name="phone_number"
                          borderRadius="10px"
                          borderWidth={1}
                          borderColor={
                            !expertFilledData?.phone_number
                              ? 'red.500'
                              : 'brand.primary'
                          }
                          h="50px"
                          onChange={(e) => handleExpertChange(e)}
                        />
                      </Flex>
                      <Flex
                        ref={expertHeadlineRef}
                        className="flex-col gap-2 my-3"
                      >
                        <Text
                          fontWeight={500}
                          fontSize="16px"
                          color="brand.primary"
                          ref={expertCategoryRef}
                        >
                          Enter your position?
                          {!expertFilledData?.headline && (
                            <Text className="text-red-600 text-lg inline font-bold">
                              *
                            </Text>
                          )}
                        </Text>
                        <Input
                          key="headline"
                          type="text"
                          placeholder="Type here...."
                          defaultValue={userDetails?.headline}
                          name="headline"
                          borderRadius="10px"
                          borderWidth={1}
                          borderColor={
                            !expertFilledData?.headline
                              ? 'red.500'
                              : 'brand.primary'
                          }
                          h="50px"
                          onChange={(e) => handleExpertChange(e)}
                        />
                      </Flex>
                      <div className={`relative w-full`} ref={ref}>
                        <Text
                          mb={2}
                          fontWeight={500}
                          fontSize="16px"
                          color="brand.primary"
                        >
                          Categories
                          {!selected?.id && (
                            <Text className="text-red-600 text-lg inline font-bold">
                              *
                            </Text>
                          )}
                        </Text>
                        <div
                          ref={ref}
                          className={`bg-white cursor-pointer flex justify-between items-center mb-2 max-w-2xl `}
                        >
                          <Input
                            key="category"
                            id="category"
                            value={searchValue}
                            onChange={onChange}
                            placeholder={'Category'}
                            className="border border-[#134A70] w-full py-3 pr-2 pl-4 rounded-lg outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                            borderRadius="10px"
                            borderWidth={1}
                            borderColor={
                              !selected?.id ? 'red.500' : 'brand.primary'
                            }
                            h="50px"
                          />
                        </div>
                        {isOpen && list.length ? (
                          <div className="absolute top-[80px] bg-white w-full max-w-2xl rounded-2xl py-2 flex flex-col gap-4 left-0 ad-card-shadow z-10">
                            <div
                              className={`flex flex-col gap-4 max-h-[30vh] overflow-y-auto pb-5`}
                            >
                              {list
                                .filter(searchCategoriesFilter)
                                .map((item, index) => {
                                  return (
                                    <div
                                      className="cursor-pointer px-5 py-2 hover:bg-gray-100"
                                      onClick={() => {
                                        selectItem(item);
                                      }}
                                      key={item?.id}
                                    >
                                      {item?.title}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className={`relative w-full`} ref={ref}>
                        <Text
                          mb={2}
                          fontWeight={500}
                          fontSize="16px"
                          color="brand.primary"
                          ref={expertCountryRef}
                        >
                          Location
                          {!selectedLocation?.id && (
                            <Text className="text-red-600 text-lg inline font-bold">
                              *
                            </Text>
                          )}
                        </Text>
                        <div
                          ref={locationRef}
                          className={`bg-white cursor-pointer flex justify-between items-center mb-2 max-w-2xl `}
                        >
                          <Flex className="flex-col gap-1 w-full">
                            <Input
                              key="location"
                              id="location"
                              value={searchLocationValue}
                              onChange={onChangeLocation}
                              placeholder={'Location'}
                              className="border border-[#134A70] w-full py-3 pr-2 pl-4 rounded-lg outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                              borderRadius="10px"
                              borderWidth={1}
                              borderColor={
                                !selectedLocation?.id
                                  ? 'red.500'
                                  : 'brand.primary'
                              }
                              h="50px"
                            />
                          </Flex>
                        </div>
                        {isLocationOpen && countriesList.length ? (
                          <div className="absolute top-[80px] bg-white w-full max-w-2xl rounded-2xl py-2 flex flex-col gap-4 left-0 ad-card-shadow z-10">
                            <div
                              className={`flex flex-col gap-4 max-h-[30vh] overflow-y-auto pb-5`}
                            >
                              {countriesList
                                .filter(searchLocationFilter)
                                .map((item, index) => {
                                  return (
                                    <div
                                      className="cursor-pointer px-5 py-2 hover:bg-gray-100"
                                      onClick={() => {
                                        selectLocation(item);
                                      }}
                                      key={item?.id}
                                    >
                                      {item?.title}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <Flex
                        className="w-full flex-wrap gap-5 my-5"
                        justifyContent="space-between"
                      >
                        <BrandButton
                          text="Submit"
                          width="100px"
                          colorScheme="blue"
                          isLoading={isLoading}
                          onClick={submitExpertData}
                        />
                        <BrandButton
                          text="Cancel"
                          width="100px"
                          onClick={closeEditExpert}
                        />
                      </Flex>
                    </Box>
                  )}
                </Flex>
              ) : null}
            </Box>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

export default AccountSettings;
