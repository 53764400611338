import { Box } from "@chakra-ui/react";

const MediaDisplay = ({ media = [] }) => {
  // Determine if a URL is a video URL (currently supporting YouTube and Vimeo)
  const isVideoUrl = (url) => /(?:youtube\.com|youtu\.be|vimeo\.com)/.test(url);

  // Extract the YouTube video ID from a URL
  const extractYoutubeVideoId = (url) => {
    const regex = /(?:youtube\.com\/.*(?:\?|&)v=|youtu\.be\/)([^&]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  // Extract the Vimeo video ID from a URL
  const extractVimeoVideoId = (url) => {
    const regex = /vimeo\.com\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  // Render a single media item: if it’s a video URL, render an iframe; otherwise, render an image.
  const renderMediaItem = (item, key) => {
    if (isVideoUrl(item)) {
      if (item.includes("youtube") || item.includes("youtu.be")) {
        const videoId = extractYoutubeVideoId(item);
        if (videoId) {
          return (
            <iframe
              key={key || item}
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          );
        }
      } else if (item.includes("vimeo")) {
        const videoId = extractVimeoVideoId(item);
        if (videoId) {
          return (
            <iframe
              key={key || item}
              className="w-full h-full"
              src={`https://player.vimeo.com/video/${videoId}`}
              title="Vimeo video player"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          );
        }
      }
    }

    // Fallback to rendering an image if not a video or if video extraction fails
    return (
      <img
        key={key || item}
        className="w-full h-full object-cover"
        src={item}
        alt="media"
      />
    );
  };

  return (
    <>
      {media.length === 1 ? (
        <Box className="mb-2">
          {media.map((item, index) => renderMediaItem(item, index))}
        </Box>
      ) : media.length === 2 ? (
        <Box className="grid grid-cols-2 gap-2 mb-2">
          {media.map((item, index) => renderMediaItem(item, index))}
        </Box>
      ) : media.length === 3 ? (
        <Box className="grid grid-cols-2 gap-2 mb-2">
          {renderMediaItem(media[0], 0)}
          <Box className="flex flex-col gap-2">
            {[media[1], media[2]].map((item, index) =>
              renderMediaItem(item, index + 1)
            )}
          </Box>
        </Box>
      ) : (
        // For 4 or more media items, only the first 4 are shown in this layout.
        <Box className="grid grid-cols-2 gap-2 mb-2">
          <Box className="flex flex-col gap-2">
            {[media[0], media[1]].map((item, index) => renderMediaItem(item, index))}
          </Box>
          <Box className="flex flex-col gap-2">
            {[media[2], media[3]].map((item, index) => renderMediaItem(item, index + 2))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default MediaDisplay;
