import React from 'react';
import { UserContext } from '../../context/UserContext';
import { useContext, useEffect } from 'react';
import TermSheetTableView from '../../components/dealFlow/TermSheetTableView';

const DealFlowTermsheetsPage = () => {
  const { setActivePage } = useContext(UserContext);

  useEffect(() => {
    setActivePage('/deal-flow');
  }, [setActivePage]);

  return (
    <>
      <TermSheetTableView />
    </>
  );
};

export default DealFlowTermsheetsPage;
