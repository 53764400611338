import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../header/Header';
import HeaderSection from './HeaderSection';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CloseDealFlow = () => {
  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle,
  );

  const currentDealFlow = useSelector(
    (state) => state?.dealFlow?.currentDealFlow,
  );
  const navigate = useNavigate();

  return (
    <Box className="h-screen flex flex-col">
      <Header title={'Close Deal Flow'} />
      <Box className="flex-1 bg-gray-100 overflow-hidden">
        <Box className="h-full px-5 pt-4">
          <Box className="h-full bg-white rounded-lg p-4 flex flex-col">
            <HeaderSection
              title={currentDealFlow?.name}
              subTitle="Deal Closed"
              checkboxState="closed"
            />

            <Box className="mt-10 w-full h-full flex items-center justify-center rounded-xl shadow-[0_0_20px_rgba(0,0,0,0.08)]">
              <Box className="w-[90%] md:w-[50%] flex flex-col gap-2 md:gap-4 text-center bg-white rounded-lg">
                <div className="flex flex-col gap-3 text-[#2F4B6E] font-bold text-lg md:text-2xl">
                  <p>Congratulations! The deal has been successfully closed.</p>

                  {accountType === 'founder' ? (
                    <p className="text-base md:text-lg font-medium text-[#4A5568]">
                      Welcome to a new chapter of growth and innovation!
                    </p>
                  ) : (
                    <p className="text-base md:text-lg font-medium text-[#4A5568]">
                      Thank you for supporting the next generation of
                      breakthrough ideas.
                    </p>
                  )}
                </div>

                <button
                  onClick={() => navigate('/deal-flow')}
                  className="mt-5 w-full bg-[#134A70] text-white py-3 rounded-2xl"
                >
                  Go to deal Flow
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CloseDealFlow;
