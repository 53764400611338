import ComposeInternalLayout from '../components/HOC/ComposeInternalLayout';
import Survey from '../pages/survey/Survey';
import ViewUser from '../pages/viewUser/ViewUser';
import PostAgreementStage from '../pages/postAgreementStage';
import ViewTermSheet from '../pages/termSheet';
import CloseDealFlow from '../pages/closeDealFlow';
import { privateUrls } from '../url/urls';
import DealFlowTermsheetsPage from '../pages/termSheet/DealFlowTermsheets';

export const privateRoutes = [
  {
    title: 'Survey',
    path: privateUrls.survey(),
    element: Survey,
  },
  {
    title: 'ViewUser',
    path: privateUrls.viewUser(),
    element: ComposeInternalLayout(ViewUser),
  },
  {
    title: 'ViewTermSheet',
    path: privateUrls.viewTermSheet(),
    element: ComposeInternalLayout(ViewTermSheet),
  },
  {
    title: 'ViewPostAgreementStage',
    path: privateUrls.viewPostAgreementStage(),
    element: ComposeInternalLayout(PostAgreementStage),
  },
  {
    title: 'ViewCloseDealFlow',
    path: privateUrls.viewCloseDealFlow(),
    element: ComposeInternalLayout(CloseDealFlow),
  },
  {
    title: 'ViewDealFlowTermsheets',
    path: privateUrls.viewDealFlowTermsheets(),
    element: ComposeInternalLayout(DealFlowTermsheetsPage),
  },
];
