import Header from '../header/Header';
import { Box, useDisclosure } from '@chakra-ui/react';
import Trending from './Trending';
import { FaPen } from 'react-icons/fa';
import CreatePostModal from '../modal/CreatePostModal';
import { useData } from '../../data';
import { useEffect, useState } from 'react';
import Post from './Post';
import CreatePost from './CreatePost';
import FriendRequests from './FriendRequests';

const Feed = () => {
  const {
    isOpen: createPostModalIsOpen,
    onClose: onCloseCreatePostModal,
    onOpen: onOpenCreatePostModal,
  } = useDisclosure();

  const [page, setPage] = useState(1);

  const [feed, setFeed] = useState([]);

  const {
    data: feedData,
    isLoading: feedDataIsLoading,
    refetch: refetchFeed,
  } = useData('feed', 10000);

  const appendToPostList = (post = null) => {
    if (post) setFeed((prev) => [post, ...prev]);
  };

  useEffect(() => {
    setFeed(feedData?.data?.posts || []);
  }, [feedDataIsLoading]);

  return (
    <Box>
      <Header title={'feed'} />
      <Box className="flex w-full bg-gray-100">
        <Box
          className={`hide-scroll max-h-[90vh] overflow-x-auto min-h-[89vh] pt-4 px-5 pb-20  w-full max-w-3xl`}
        >
          <Box className="p-3 bg-white mb-5 rounded-lg max-w-2xl mx-auto">
            <CreatePost
              showCloseButton={false}
              refetchPost={refetchFeed}
              appendToPostList={appendToPostList}
            />
          </Box>
          <Box className="flex flex-col gap-5 max-w-2xl mx-auto">
            {feed?.map((post) => {
              return (
                <Box className="bg-white rounded-lg" key={post.id}>
                  <Post post={post} refetchPost={refetchFeed} />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          className={`hide-scroll max-h-[90vh] overflow-x-auto w-full px-5 max-w-sm hidden lg:flex`}
        >
          <Box className="flex flex-col gap-8 py-4 w-full">
            <FriendRequests />
            <Trending />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Feed;
