import React, { useState, useRef, useEffect, useContext } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Image,
  Text,
  Flex,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  Avatar,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Button,
  Link,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import brandLogo from '../../public/icons/logo_alt.svg';
import logout from '../../public/icons_v2/logout.svg';
import Navs from './navs/nav';
import IsDesktop from '../../utils/IsDesktop';
import UserService from '../../utils/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated } from '../../redux-toolkit/reducers/authSlice';
import { setUserInfo } from '../../redux-toolkit/reducers/userSlice';
import { CustomToast } from '../toast/CustomToast';
import { mutateFunction, useMutation } from '../../libs/apis';
import { setCompanyInfo } from '../../redux-toolkit/reducers/companySlice';
import FounderSurveyForm from '../../pages/survey/FounderSurveyForm';
import { setCompleteSurvey } from '../../redux-toolkit/reducers/SurveySlice';
import StatusIndicator from '../statusIndicator/StatusIndicator';
import Logout from '../../utils/Logout';
import { UserContext } from '../../context/UserContext';
import CustomerSupportModal from '../modal/CustomerSupportModal';
import LogoutModal from '../modal/LogoutModal';

const Sidebar = ({ sidebarWidth, toggleSidebar, isHidden }) => {
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const navs = Navs();
  const { activePage, setActivePage } = useContext(UserContext);
  const isDesktop = IsDesktop();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const btnRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = CustomToast();
  const { mutate } = useMutation(mutateFunction);
  const { isOpen: isSurveyOpen, onToggle: onToggleSurvey } = useDisclosure();
  const { isOpen: isLogoutModalOpen, onToggle: onToggleLogoutModal } =
    useDisclosure();
  const location = useLocation();
  const {
    isOpen: submitComplaintModalIsOpen,
    onOpen: onOpenSubmitComplaintModal,
    onClose: onCloseSubmitComplaintModal,
  } = useDisclosure();

  const completeSurvey = () => {
    UserService.setCompleteSurveyAction(true);
    dispatch(setCompleteSurvey(true));
    navigate('/survey');
  };

  const updateActivePage = (route) => {
    if (userInfo?.status == 'disabled') return;
    setActivePage(route);
  };

  useEffect(() => {
    navs.some((nav) => {
      if (location.pathname.match(new RegExp(`^${nav.to}`))) {
        updateActivePage(nav.to);
        return true;
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    if (userInfo?.status == 'disabled') navigate('/feed');
  }, [location.pathname]);

  // const [sidebarWidth, setSidebarWidth] = useState(300);

  // const toggleSidebar = () => {
  //   setSidebarWidth(sidebarWidth === 300 ? 50 : 300);
  // };

  return (
    <>
      <CustomerSupportModal
        isOpen={submitComplaintModalIsOpen}
        onClose={onCloseSubmitComplaintModal}
      />
      {/* Mobile view */}
      {!isDesktop && (
        <>
          <Box
            className={`z-10 top-3.5`}
            onClick={onToggle}
            color="#fff"
            pos="fixed"
            ml={4}
            pt={3}
            cursor="pointer"
            zIndex={'banner'}
          >
            <HamburgerIcon color={'#000'} w={8} h={8} />
          </Box>
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay
              bg={'transparent'}
              className="flex flex-col justify-between"
            >
              <Box className="pt-[80px]"></Box>
              <Box className="h-full bg-[rgba(0,0,0,0.7)]"></Box>
            </DrawerOverlay>
            <DrawerContent bg={'transparent'}>
              <DrawerBody
                bg={'transparent'}
                className="flex flex-col justify-between"
                p={0}
              >
                <Box className="h-[88px] flex pl-4 items-center relative bg-[#F6F6F6]"></Box>
                <Box className="flex flex-col justify-between bg-white h-full">
                  <Box>
                    <Box
                      mb={8}
                      mt={2}
                      className={`gap-2 pt-8 pb-6 relative justify-center items-center`}
                      display="flex"
                      cursor="pointer"
                    >
                      <Box className={`flex gap-2 flex-row items-center`}>
                        <Image
                          onClick={() => navigate('/feed')}
                          className={`w-[30px]`}
                          src={brandLogo}
                          alt="brandLogo"
                          borderWidth="2px"
                        />
                        <Text
                          className={`text-[#8A8A8A] font-semibold text-base`}
                        >
                          Seedafounders
                        </Text>
                      </Box>
                    </Box>
                    <Box className="bg-white">
                      <Box display="flex" flexDir="column">
                        {navs?.map((nav) => (
                          <Box
                            className="my-4"
                            onClick={() => {
                              if (userInfo?.status == 'disabled') {
                                return;
                              }
                              setActivePage(nav.to);
                              onClose();
                            }}
                            key={nav.name}
                          >
                            <NavLink
                              to={userInfo?.status == 'disabled' ? '#' : nav.to}
                              style={{ textDecoration: 'none' }}
                            >
                              <Box
                                className={`flex items-center ml-6 pl-3 rounded-l-md ${activePage === nav.to ? 'bg-[#134A70]' : ''}`}
                              >
                                <Box
                                  className={`flex p-2 items-center justify-center`}
                                >
                                  {activePage === nav.to
                                    ? nav.activeIcon
                                    : nav.icon}
                                </Box>
                                <Text
                                  className="text-[19px]"
                                  textAlign="left"
                                  color={
                                    activePage === nav.to ? '#fff' : '#000'
                                  }
                                >
                                  {nav.name}
                                </Text>
                              </Box>
                            </NavLink>
                          </Box>
                        ))}

                        <Box
                          onClick={onToggleLogoutModal}
                          className={`flex items-center my-4 ml-6 pl-3 rounded-l-md`}
                        >
                          <Box
                            className={`flex p-2 items-center justify-center`}
                          >
                            <Image
                              h="24px"
                              w="24px"
                              color={'#9FA7AD'}
                              src={logout}
                            />
                          </Box>
                          <Text
                            whiteSpace="nowrap"
                            color={'#000'}
                            className={`text-[19px]`}
                            textAlign="left"
                          >
                            Logout
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className={`${isHidden ? 'hidden' : 'pt-5 flex flex-col pb-8 gap-6 bg-white'}`}
                  >
                    <Box className="flex gap-2 justify-center items-center">
                      <Avatar
                        w={'40px'}
                        h={'40px'}
                        name={`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
                        src={userInfo?.profile_image}
                      />
                      <Text
                        className="text-[19px] capitalize font-semibold"
                        color={'brand.primary'}
                      >
                        {`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
                      </Text>
                    </Box>

                    <Box
                      onClick={() => {
                        onOpenSubmitComplaintModal();
                      }}
                      className="cursor-pointer text-center text-[19px] font-medium text-[#8A8A8A]"
                    >
                      Contact support
                    </Box>
                  </Box>
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}

      {/* Web view */}
      {isDesktop && (
        <Box
          bgColor="#fff"
          className={`${isHidden ? 'w-[140px]' : 'min-w-[240px]'} pt-5`}
          boxShadow="10px 0px 20px rgba(0, 0, 0, 0.05)"
        >
          <Box
            mb={8}
            mt={2}
            className={`gap-2 py-2 relative justify-center ${isHidden ? 'items-start' : 'items-center'}`}
            display="flex"
            cursor="pointer"
          >
            <Box
              className={`flex gap-2  ${isHidden ? 'flex-col items-center w-full' : 'flex-row items-center'}`}
            >
              <Image
                onClick={() => navigate('/dashboard')}
                className={`${isHidden ? 'w-[30px]' : 'w-[30px]'}`}
                src={brandLogo}
                alt="brandLogo"
                borderWidth="2px"
              />
              <Text
                className={`text-[#8A8A8A] font-semibold ${isHidden ? 'hidden' : 'text-base'}`}
              >
                Seedafounder
              </Text>
            </Box>
            <div
              className={`border border-[#DBE2ED] absolute right-[0] border-r-transparent bg-[#FAFAFA] rounded-l-lg`}
              onClick={toggleSidebar}
            >
              {isHidden ? (
                <ChevronRightIcon w={8} h={8} />
              ) : (
                <ChevronLeftIcon w={8} h={8} />
              )}
            </div>
          </Box>
          <Box className="flex flex-col min-h-[85vh] justify-between">
            <Box
              display="flex"
              flexDir="column"
              className={`${isHidden ? 'items-center' : ''}`}
            >
              {navs?.map((nav) => (
                <Box
                  className="my-4"
                  onClick={() => {
                    if (userInfo?.status == 'disabled') return;
                    setActivePage(nav.to);
                  }}
                  key={nav.name}
                >
                  <NavLink
                    to={userInfo?.status == 'disabled' ? '#' : nav.to}
                    style={{ textDecoration: 'none' }}
                  >
                    <Box
                      className={`flex items-center ${isHidden ? 'justify-center rounded-md' : 'ml-6 pl-3 rounded-l-md'} ${activePage === nav.to ? 'bg-[#134A70]' : ''}`}
                    >
                      <Box className={`flex p-2 items-center justify-center`}>
                        {activePage === nav.to ? nav.activeIcon : nav.icon}
                      </Box>
                      <Text
                        className="text-[19px]"
                        textAlign="left"
                        color={activePage === nav.to ? '#fff' : '#000'}
                        display={isHidden ? 'none' : 'block'}
                      >
                        {nav.name}
                      </Text>
                    </Box>
                  </NavLink>
                </Box>
              ))}

              <Box
                onClick={onToggleLogoutModal}
                className={`flex cursor-pointer items-center my-4 ${isHidden ? 'justify-center rounded-sm' : 'ml-6 pl-3 rounded-l-md'}`}
              >
                <Box className={`flex p-2 items-center justify-center`}>
                  <Image h="24px" w="24px" color={'#9FA7AD'} src={logout} />
                </Box>
                <Text
                  whiteSpace="nowrap"
                  color={'#000'}
                  className={`text-[19px]`}
                  textAlign="left"
                  display={isHidden ? 'none' : 'block'}
                >
                  Logout
                </Text>
              </Box>
            </Box>
            <Box
              className={`${isHidden ? 'hidden' : 'flex flex-col pb-8 gap-6'}`}
            >
              <Box className="flex gap-2 justify-center items-center">
                <Avatar
                  w={'40px'}
                  h={'40px'}
                  name={`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
                  src={userInfo?.profile_image}
                />
                <Text
                  className="text-[19px] capitalize font-semibold"
                  color={'brand.primary'}
                >
                  {`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
                </Text>
              </Box>

              <Box
                onClick={() => {
                  onOpenSubmitComplaintModal();
                }}
                className="cursor-pointer text-center text-[19px] font-medium text-[#8A8A8A]"
              >
                Contact support
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <LogoutModal isOpen={isLogoutModalOpen} onClose={onToggleLogoutModal} />
    </>
  );
};

export default Sidebar;
