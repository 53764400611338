import { useBreakpointValue, Checkbox, Stack } from '@chakra-ui/react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HeaderSection = ({
  title,
  subTitle,
  checkboxState,
  showStatus = true,
}) => {
  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle,
  );

  const navigate = useNavigate();
  const stackDirection = useBreakpointValue({
    base: 'column',
    sm: 'row', // 480px and above
  });

  return (
    <section className="flex flex-col gap-4 md:gap-0 md:flex-row md:items-center md:justify-between">
      <div className="flex items-center gap-1 sm:gap-2 md:gap-4 text-lg sm:text-xl md:text-2xl font-bold text-[#2F4B6E]">
        <IoIosArrowBack
          onClick={() =>
            accountType === 'founder' ? navigate(-1) : navigate('../deal-flow')
          }
          className="text-3xl cursor-pointer"
        />
        <p>
          {title} - {subTitle}
        </p>
      </div>
      {showStatus ? (
        <div className="flex flex-col items-end min-w-fit">
          <p className="text-base md:text-lg font-semibold text-[#2F4B6E]">
            Status Update
          </p>
          <Stack
            direction={stackDirection}
            spacing={[1, 2, 3]}
            flexShrink={0}
            whiteSpace="nowrap"
            fontSize={['2xs', 'xs', 'sm']}
            alignItems="flex-start"
          >
            {checkboxState === 'in_progress' && (
              <Checkbox
                isChecked={checkboxState === 'in_progress'}
                colorScheme="green"
                sx={{
                  '.chakra-checkbox__control': {
                    borderColor: '#20B962',
                    _checked: {
                      bg: '#20B962',
                      borderColor: '#20B962',
                    },
                    w: ['3', '4', '5'],
                    h: ['3', '4', '5'],
                  },
                }}
              >
                In progress
              </Checkbox>
            )}

            {checkboxState === 'signed' && (
              <Checkbox
                isChecked={checkboxState === 'signed'}
                colorScheme="orange"
                sx={{
                  '.chakra-checkbox__control': {
                    borderColor: '#EC981C',
                    _checked: {
                      bg: '#EC981C',
                      borderColor: '#EC981C',
                    },
                    w: ['3', '4', '5'],
                    h: ['3', '4', '5'],
                  },
                }}
              >
                Term Sheet Signed
              </Checkbox>
            )}

            {checkboxState === 'rejected' && (
              <Checkbox
                isChecked={checkboxState === 'rejected'}
                colorScheme="red"
                sx={{
                  '.chakra-checkbox__control': {
                    borderColor: '#CF1137',
                    _checked: {
                      bg: '#CF1137',
                      borderColor: '#CF1137',
                    },
                    w: ['3', '4', '5'],
                    h: ['3', '4', '5'],
                  },
                }}
              >
                Term Sheet Rejected
              </Checkbox>
            )}
          </Stack>
        </div>
      ) : null}
    </section>
  );
};

export default HeaderSection;
