import React, { useState } from "react";
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { AiFillCustomerService, AiFillVideoCamera, AiFillWarning } from "react-icons/ai";
import { mutateFunction, useMutation } from "../../libs/apis";
import { CustomToast } from "../toast/CustomToast";
import CreateComment from "../feed/CreateComment";
import EmbedVideoLink from "../feed/EmbedVideoLink";

export default function EmbedVideoLinkModal({ 
  isOpen, 
  onClose, 
  videoLink,
  onAddLink
}) {

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Flex flexDirection="column">
              <Stack spacing={6}>
                <Flex className="items-center gap-2" justify={"center"}>
                  <Text
                    fontSize={20}
                    fontWeight={700}
                    color="brand.primary"
                    align="center"
                  >
                    Add A Video
                  </Text>
                  <AiFillVideoCamera style={{ color: "rgba(0,0,0,0.7)", fontSize: "28px" }} />
                </Flex>

                <Text fontSize={14} color="#000000">
                  Embed a Youtube or Vimeo link on your post 
                </Text>
                </Stack>
              <EmbedVideoLink 
                videoLink={videoLink}
                onAddLink={onAddLink}
                onClose={onClose}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
