import { createSlice } from '@reduxjs/toolkit';
import UserService from '../../utils/UserService';

const initialState = {
  currentDealFlow: UserService.getCurrentDealFlow(),
  currentTermSheetId: UserService.getCurrentTermSheetId(),
  currentPage: UserService.getCurrentPage(),
};

const dealFlowSlice = createSlice({
  name: 'dealFlow',
  initialState,
  reducers: {
    setCurrentDealFlow(state, action) {
      state.currentDealFlow = action.payload;
    },
    setCurrentTermSheetId(state, action) {
      state.currentTermSheetId = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export const { setCurrentDealFlow, setCurrentTermSheetId, setCurrentPage } =
  dealFlowSlice.actions;
export default dealFlowSlice.reducer;
