import { Avatar, Box, Spinner, Text, useDisclosure } from "@chakra-ui/react"
import moment from "moment";
import { useEffect, useState } from "react";
import { FaArrowLeft, FaBookmark, FaCaretLeft, FaComment, FaHeart, FaRegBookmark, FaRegComment, FaRegHeart, FaShareAlt } from "react-icons/fa"
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../helpers/constant";
import { mutateFunction } from "../../libs/apis";
import client from "../../libs/client";
import Header from "../header/Header";
import CreateCommentModal from "../modal/CreateCommentModal";
import ShareContentModal from "../modal/ShareContentModal";
import { CustomToast } from "../toast/CustomToast";
import Comment from "./Comment";
import CreateComment from "./CreateComment";
import CreateReply from "./CreateReply";
import FriendRequests from "./FriendRequests";
import MediaDisplay from "./MediaDisplay";
import Trending from "./Trending";

const PublicPostDetails = ({
    
}) => {
    const { mutate, isLoading } = useMutation(mutateFunction);
    const { addToast } = CustomToast();
    const navigate = useNavigate()
    const location  = useLocation()
    const {
        isOpen : createCommentModalIsOpen,
        onClose : onCloseCreateCommentModal,
        onOpen : onOpenCreateCommentModal
    } = useDisclosure()

    const [isLoadingPost, setIsLoadingPost] = useState(false)
    const [isNestedComment, setIsNestedComment] = useState(false)
    const [isLoadingComments, setIsLoadingComments] = useState(false)
    const [ post, setPost] = useState(null)
    const [comments, setComments] = useState([])
    const [parentComment, setParentComment] = useState(null)
    const [isLiked, setIsLiked] = useState(false)
    const [likesCount, setLikesCount] = useState(0)
    const [commentsCount, setCommentsCount] = useState(0)
    const {
        isOpen : createShareContentIsOpen,
        onClose : onCloseShareContentVideo,
        onOpen : onOpenShareContentVideo
      } = useDisclosure()
    const [message, setMessage] = useState('')
    const [link, setLink] = useState('')

    const getPost = ({postId}) => {
        setIsLoadingPost(true)
        return client().get(`${BASE_URL}public/post/${postId}`)
            .then((res) => {
                setIsLoadingPost(false)
                const data = res.data.data.post;
                setPost(data)
                setIsLiked(data.isLiked)
                setLikesCount(data.likes_count)
                setCommentsCount(data.comment_count)
                const _message = `Hi! ${data.user.first_name} ${data.user.last_name} shared their thoughts on SeedAFounder`;
                const _link = `${process.env.REACT_APP_FRONTEND_URL}public/post/${postId}`;
                setMessage(_message)
                setLink(_link)
        })
    }

    const getComments = ({postId, commentId}) => {
        setIsLoadingComments(true)
        return client().get(commentId ? `${BASE_URL}public/comment/reply/${commentId}` : `${BASE_URL}public/comment/post/${postId}`)
            .then((res) => {
                setIsLoadingComments(false)
                const data = res.data.data.comments;
                setComments(data)
        })
    }

    const getParentComment = ({commentId}) => {
        setIsLoadingComments(true)
        return client().get(`${BASE_URL}public/comment/${commentId}`)
            .then((res) => {
                setIsLoadingComments(false)
                const data = res.data.data.comment;
                setParentComment(data)
        })
    }

    const likePost = () => {
        try {
            
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const params = {}
        location.search.replace('?', '').split("&").forEach((x) => {
            let arr = x.split("=")
            params[arr[0]] = arr[1]
        })
        if(params['id']){
            getPost({
                postId: params['id']
            })
            getComments({
                postId: params['id'],
                commentId: params['commentId'] || null
            })
        }
        if(params['commentId']){
            setIsNestedComment(true)
            getParentComment({
                commentId: params['commentId'] || null 
            })
        }else{
            setIsNestedComment(false)
            setParentComment(null)
        }
    }, [location])

    const onCreateComment = (comment) => {
        if(parentComment){
            setParentComment(prev => ({
                ...prev,
                replies_count : prev.replies_count + 1
            }))
        }else {
            setCommentsCount(prev => prev + 1)
        }
        setComments(prev => ([comment, ...prev]))
    }

    const onCreateReply = (comment) => {
        setComments(prev => ([comment, ...prev]))
    }

    const goBack = (e) => {
        e.stopPropagation()
        navigate(-1)
    }
    return (
        <Box className="">
            <CreateCommentModal
                isOpen={createCommentModalIsOpen}
                onClose={onCloseCreateCommentModal}
                post={post}
                onSuccess={onCreateComment}
            />
            <ShareContentModal  
                isOpen={createShareContentIsOpen}
                onClose={onCloseShareContentVideo}
                message={message}
                link={link}
                subject={"Hello! Check out this post on SeedAFounder."}
            />
            <Box className="flex flex-col h-full bg-gray-100 gap-2 w-full">
                <Box className="px-3 py-2">
                    <Box onClick={goBack} className="flex items-center cursor-pointer gap-1">
                        <FaArrowLeft />
                        <Text>Back</Text>
                    </Box>
                </Box>
            {
                isLoadingPost ? (
                        <Box>
                            <Spinner />
                        </Box>
                ) : (
                    !post ? (
                        <Box>
                            <Text className="text-center text-gray-700 font-bold text-sm">Could not load post</Text>
                        </Box>
                    ) : (
                        <Box className="flex w-full h-full bg-gray-100">
                            <Box className={`hide-scroll max-h-[95vh] overflow-x-auto min-h-[94vh] pt-4 md:px-5 pb-30 pr-10 w-full pt-4 max-w-3xl`} >
                                <Box className="p-2 flex flex-col relative bg-white mb-5 rounded-lg w-full max-w-2xl mx-auto">
                                    {
                                        parentComment ? (
                                            <Box className="h-[60%] w-[2px] bg-gray-300 absolute left-10 top-7"/> 
                                        ) : null
                                    }
                                    <Box className="p-2 flex mb-5">
                                        <Box>
                                            <Avatar 
                                                src={post.user.profile_image ?? null}
                                                name={`${post.user.first_name} ${post.user.last_name}`}
                                                size={"md"}
                                            />
                                        </Box>
                                        <Box className="w-full cursor-pointer flex px-2 pl-4 flex-col">
                                            <Box className="flex items-center text-sm mb-3 pt-2 gap-2">
                                                <Text className="font-bold capitalize">{`${post.user.first_name} ${post.user.last_name}`}</Text>
                                                <Text className="text-xs text-gray-600">{moment(post.createdAt).fromNow()}</Text>
                                            </Box>
                                            <Box className="w-full pb-5 min-h-10">
                                            <Text className="w-full" color="#000">
                                                {post?.text.split('\n\n').map(text => <span>{text}<br/></span>)}
                                            </Text>
                                            </Box>
                                            {
                                                post.media.length > 0 ? <MediaDisplay media={post.media} /> : null
                                            }
                                            <Box className="flex items-center justify-between">
                                                <Box className="flex gap-1 cursor-pointer items-center">
                                                    <FaRegComment className="text-gray-600" />
                                                    <Text>{commentsCount}</Text>
                                                </Box>
                                                <Box className="flex gap-1 cursor-pointer items-center">
                                                {
                                                    isLiked ? (
                                                        <FaHeart onClick={(e) => {
                                                            e.stopPropagation()
                                                            likePost()
                                                        }} className="text-red-600" />
                                                    ) : (
                                                        <FaRegHeart onClick={(e) => {
                                                            e.stopPropagation()
                                                            likePost()
                                                        }} className="text-gray-600" /> 
                                                    )
                                                } 
                                                    <Text>{likesCount}</Text>
                                                </Box>
                                                <Box  onClick={(e) => {
                                                                e.stopPropagation()
                                                                onOpenShareContentVideo()
                                                            }} className="flex gap-1 cursor-pointer items-center">
                                                    <FaShareAlt />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {
                                        parentComment ? (
                                            <Comment onCreateReply={onCreateReply} parentId={post.id} post={parentComment} />    
                                        ) : null
                                    }
                                </Box>
                                
                                <Box className="p-2 bg-white rounded-lg my-5 max-w-2xl mx-auto">
                                    {
                                        parentComment ? (
                                            <CreateReply
                                                comment={parentComment}
                                                postId={post.id}
                                                showCloseButton={false}
                                                onSuccess={onCreateComment}   
                                                showPost={false}   
                                            />
                                        ) : (
                                            <CreateComment 
                                                post={post}
                                                showPost={false}
                                                showCloseButton={false}
                                                onSuccess={onCreateComment}      
                                            />
                                        )
                                    }
                                </Box>
                                {
                                        isLoadingPost || isLoadingComments ? (
                                            <Box>
                                                <Spinner />
                                            </Box>
                                        ) : (
                                            <Box className="flex flex-col max-w-2xl mx-auto my-10 gap-5">
                                                {
                                                    comments.length > 0 ? comments.map((comment) => {
                                                    return (
                                                        <Box className="p-2 bg-white rounded-lg" key={comment.id}>
                                                            <Comment parentId={post.id} post={comment} />
                                                        </Box>
                                                    ) 
                                                    }) : (
                                                        <Box>
                                                            <Text className="font-medium text-gray-500 text-center">No comments yet</Text>
                                                        </Box>
                                                    )
                                                }
                                            </Box>
                                        )
                                }
                            </Box>
                        </Box>
                    )
                )
            }
            </Box>
        </Box>
        
    )
}


export default PublicPostDetails